import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export default function TableResume({ currentUrl, monthly }: { currentUrl: string, monthly: boolean }) {
    const { t } = useTranslation();

    const calculPrice = () => {
        if (currentUrl === "pro") {
            return monthly ? "22.-" : "198.-";
        } else {
            return monthly ? "8.-" : "72.-";
        }
    }

    return (
        <table className="w-full min-w-max table-auto text-left">
            <tr>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                    >
                        {t("licence")}
                    </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                    >
                        {t("typestyle")}
                    </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                    >
                        {t("period")}
                    </Typography>
                </th>
                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 text-center"
                    >
                        {t("pricettc")}
                    </Typography>
                </th>
            </tr>
            <tr>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {t("licence")}
                    </Typography>
                </td>
                <td className="p-4">
                    
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {currentUrl === "pro" ? t("PRO") : t("PREMIUM")}
                    </Typography>
                </td>
                <td className="p-4">
                    
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                    >
                        {t(monthly ? "monthly" : "yearly")}
                    </Typography>
                </td>
                <td className="p-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-center"
                    >
                        {calculPrice()} {t("chf")}
                    </Typography>
                </td>
            </tr>
        </table>
    )
}