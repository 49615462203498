import { Checkbox, Option, Radio, Select } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import GradientButton from "../../../components/Form/Button/GradientButtonBig";
import {
    faCreativeCommons,
    faCreativeCommonsBy,
    faCreativeCommonsNc,
    faCreativeCommonsNd,
    faCreativeCommonsSa,
    faCreativeCommonsZero,
} from "@fortawesome/free-brands-svg-icons";
import leftZone from "../../../assets/images/elements/watermarkinfo/bl.jpg";
import rightZone from "../../../assets/images/elements/watermarkinfo/br.jpg";
import fullZone from "../../../assets/images/elements/watermarkinfo/full.jpg";

import ModalPreview from "../../../components/Modals/ModalPreview";
import { useTranslation } from "react-i18next";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Storage } from "../../../services/storage";
import { Language } from "../../../enums/Language";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateMe } from "../../../services/redux/actions/generalActions";
import { handleError } from "../../../services/Errors/handleErrors";

export default function Notifications() {
    const { t } = useTranslation();
    const [preferences, setPreferences] = React.useState({} as any);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [me, setMe] = React.useState<any>();
    const myId = Storage.getId()
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(Storage.getLanguage() ?? Language.FR);
    const dispatch = useDispatch();

    const fetchMyId = async () => {
        if (!myId) return;
        const resp = await UserAPI.getPreferences(myId);
        if (resp.status === 200 || resp.status === 201) {
            setPreferences(resp.body);
        } else {
            handleError(resp);
        }
    }
    
    const fetchData = async () => {
        const response = await UserAPI.me();
        if (response.status === 200)
            setMe(response.body)
        else {
            handleError(response);
        }
    }

    useEffect(() => {
        fetchMyId();
        fetchData()
    }, []);

    const handleSubmit = async () => {
        if (!myId) return;
        changeLang();
        let success = false

        const datas = {
            defaultLicense: me.defaultLicense,
            enableNSFWFilter: me.enableNSFWFilter,
            enableWatermark: me.enableWatermark,
        }

        const response = await UserAPI.putUser(datas, myId)
        if (response.status === 201 || response.status === 200) {
            success = true
            const responseMe = await UserAPI.me()
            if (responseMe.status === 200)
                dispatch(updateMe(responseMe.body))
            else
                handleError(responseMe);
        } else {
            handleError(response);
            success = false
        }
        const datasNotifs = {
            newLikedPicture: preferences.notificationPreferences.newLikedPicture,
            newLikedGallery: preferences.notificationPreferences.newLikedGallery,
            newSubscriber: preferences.notificationPreferences.newSubscriber,
            newTestimonial: preferences.notificationPreferences.newTestimonial,
            newOffer:preferences.notificationPreferences.newOffer,
            newMessage:preferences.notificationPreferences.newMessage,
            pictureStatus: preferences.notificationPreferences.pictureStatus,
            newSale:preferences.notificationPreferences.newSale,
            subscription: preferences.notificationPreferences.subscription,
            newsletter:preferences.notificationPreferences.newsletter,
            favoritesPhotographers:preferences.notificationPreferences.favoritesPhotographers
        }
        const constRespNotifs = await UserAPI.putNotifications(preferences.notificationPreferences.id, datasNotifs)
        if (constRespNotifs.status === 201 || constRespNotifs.status === 200) {
            success = true
            const responseMe = await UserAPI.me()
            if (responseMe.status === 200)
                dispatch(updateMe(responseMe.body))
            else
                handleError(responseMe);
        } else {
            handleError(constRespNotifs);
            success = false
        }

        if (success) {
            toast.success(t("preferences_updated"))
        } else {
            toast.error(t("preferences_update_error"))
        }
    }

    const changeLang = () => {
        switch (lang) {
            case "en":
                i18n.changeLanguage(Language.EN);
                Storage.setLanguage("en");
                break;
            case "it":
                i18n.changeLanguage(Language.IT);
                Storage.setLanguage("it");
                break;
            case "de":
                i18n.changeLanguage(Language.DE);
                Storage.setLanguage("de");
                break;
            default:
                i18n.changeLanguage(Language.FR);
                Storage.setLanguage("fr");
                break;
        }
    }

    return (
        <div className="flex flex-col bg-gray-50 w-full md:px-4 py-4 rounded-lg">
            <ModalPreview open={openPreview} setOpen={setOpenPreview} type={me?.enableWatermark} />
            <p className="mb-2 text-xl font-sans font-extrabold">{t("notifsandpref")}</p>
            <p className="mt-2 mb-2 text-base font-sans font-bold">{t("myalerts")}</p>

            <div className="flex flex-col gap-2 mt-4 w-full">
                <div className="flex flex-row w-full gap-4 items-center">
                    <p color="black" className="flex flex-row text-sm w-1/2"></p>
                    <div className="flex w-1/4 justify-center">
                        <p color="black" className="flex flex-row justify-center gap-2 text-sm w-1/2">
                            <FontAwesomeIcon icon="bell" className="w-4 h-4" />
                            {t("notification")}
                        </p>
                    </div>
                    <div className="flex w-1/4 justify-center">
                        <p color="black" className="flex flex-row justify-center gap-2 text-sm w-1/2">
                            <FontAwesomeIcon icon="envelope" className="w-4 h-4" />
                            {t("mail")}
                        </p>
                    </div>
                </div>
                {["newLikedPicture", "newLikedGallery", "newSubscriber", "newTestimonial", "newOffer", "newMessage", "pictureStatus", "newSale", "subscription", "newsletter", "favoritesPhotographers"].map((notif, index) => (
                    <div className="flex flex-row w-full gap-4 items-center" key={notif}>
                        <p color="black" className="flex flex-row text-sm w-1/2">
                            {t(notif)}
                        </p>
                        <div className="flex w-1/4 justify-center">
                            <Checkbox 
                                className="bg-white" 
                                color="orange" 
                                checked={
                                    (preferences && preferences.notificationPreferences && (preferences?.notificationPreferences[notif] === "inapp" || preferences?.notificationPreferences[notif] === "both"))
                                }
                                disabled={notif === "subscription" || notif === "newsletter" || notif === "favoritesPhotographers"}
                                onChange={(event) => {
                                    if (!preferences || !preferences.notificationPreferences || !preferences.notificationPreferences[notif]) return;
                                    if (preferences.notificationPreferences[notif] === "inapp")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "none"}})
                                    else if (preferences.notificationPreferences[notif] === "both")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "email"}})
                                    else if (preferences.notificationPreferences[notif] === "none")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "inapp"}})
                                    else if (preferences.notificationPreferences[notif] === "email")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "both"}})
                                    else return;
                                }}                            
                            />
                        </div>
                        <div className="flex w-1/4 justify-center">
                            <Checkbox 
                                className="bg-white" 
                                color="orange" 
                                checked={
                                    (preferences && preferences.notificationPreferences && (preferences?.notificationPreferences[notif] === "email" || preferences?.notificationPreferences[notif] === "both"))
                                }
                                onChange={(event) => {
                                    if (!preferences || !preferences.notificationPreferences || !preferences.notificationPreferences[notif]) return;
                                    if (preferences.notificationPreferences[notif] === "email")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "none"}})
                                    else if (preferences.notificationPreferences[notif] === "both")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "inapp"}})
                                    else if (preferences.notificationPreferences[notif] === "none")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "email"}})
                                    else if (preferences.notificationPreferences[notif] === "inapp")
                                        setPreferences({...preferences, notificationPreferences: {...preferences.notificationPreferences, [notif]: "both"}})
                                    else return;
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <hr className="border-gray-300 mt-6" />

            <p className="mt-4 mb-2 text-base font-sans font-bold">{t("myimports")}</p>
            <p className="mb-4 text-sm font-sans">{t("myimports_d")}</p>

            <div className="flex flex-row gap-2 items-center justify-start">
                <div className="w-full md:w-72">
                    <Select className="bg-white" color="orange" label={t("defaultlicense")} value={me?.defaultLicense} onChange={(arg) => setMe({...me, defaultLicense: arg })}>
                        <Option value="all-rights-reserved">
                            <div className="flex justify-between flex-row">
                                <span className="mr-auto whitespace-nowrap">Tout droits réservés</span>
                            </div>
                        </Option>
                        <Option value="own-license">
                            <div className="flex justify-between flex-row">
                                <span className="mr-auto whitespace-nowrap">Licence personnalisée</span>
                            </div>
                        </Option>
                        <Option value="cc-by">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap mr-auto">CC BY 4.0</span>
                                <span className="ml-36 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc-by-sa">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC BY-SA 4.0</span>
                                <span className="ml-24 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                    <FontAwesomeIcon icon={faCreativeCommonsSa} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc-by-nc">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC BY-NC 4.0</span>
                                <span className="ml-24 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                    <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc-by-nc-sa">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC BY-NC-SA 4.0</span>
                                <span className="ml-12 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                    <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                    <FontAwesomeIcon icon={faCreativeCommonsSa} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc-by-nd">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC BY-ND 4.0</span>
                                <span className="ml-24 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                    <FontAwesomeIcon icon={faCreativeCommonsNd} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc-by-nc-nd">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC BY-NC-ND 4.0</span>
                                <span className="ml-12 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                    <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                    <FontAwesomeIcon icon={faCreativeCommonsNd} />
                                </span>
                            </div>
                        </Option>
                        <Option value="cc0">
                            <div className="flex justify-between flex-row">
                                <span className="whitespace-nowrap">CC0 1.0</span>
                                <span className="ml-40 flex flex-row gap-1 text-lg">
                                    <FontAwesomeIcon icon={faCreativeCommons} />
                                    <FontAwesomeIcon icon={faCreativeCommonsZero} />
                                </span>
                            </div>
                        </Option>
                    </Select>
                </div>
            </div>

            <div className="flex flex-row gap-2 items-start justify-start mt-2">
                <Checkbox className="bg-white" color={me?.enableWatermark !== "none" ? "orange" : "gray"} checked={me?.enableWatermark !== "none"} onChange={() => setMe({...me, enableWatermark: me?.enableWatermark === "none" ? "bl" : "none"})} />
                <p className={`text-sm font-sans h-11 align-middle flex items-center transition-all ${me?.enableWatermark !== "none" ? "text-black" : "text-gray-500"}`}>{t("addlogo")}</p>
            </div>

            {me?.enableWatermark !== "none" && (
                <div className="flex flex-col items-start justify-center w-full">
                    <div className="flex flex-col md:flex-row mt-6 justify-center w-full">
                        <Radio
                            color="orange"
                            name="side"
                            id="bl"
                            value="bl"
                            checked={me?.enableWatermark === "bl"}
                            onChange={() => setMe({...me, enableWatermark: "bl"})}
                            label={<img alt="leftZone" src={leftZone} className="h-32" />}
                        />
                        <Radio
                            color="orange"
                            name="side"
                            id="bl"
                            value="bl"
                            checked={me?.enableWatermark === "br"}
                            onChange={() => setMe({...me, enableWatermark: "br"})}
                            label={<img alt="rightZone" src={rightZone} className="h-32" />}
                        />
                        <Radio
                            color="orange"
                            name="side"
                            id="bl"
                            value="bl"
                            checked={me?.enableWatermark === "full"}
                            onChange={() => setMe({...me, enableWatermark: "full"})}
                            label={<img alt="fullZone" src={fullZone} className="h-32" />}
                        />
                    </div>
                    <button
                        className="mx-auto mt-6 border px-4 py-2 rounded-full hover:bg-gray-200 transition-all"
                        onClick={() => setOpenPreview(!openPreview)}
                    >
                        {t("preview")}
                    </button>
                </div>
            )}

            <hr className="border-gray-300 mt-2 " />

            <p className="mt-4 mb-2 text-base font-sans font-bold">{t("nsfw")}</p>

            <div className="flex flex-row gap-2 items-center justify-start">
                <Checkbox className="bg-white" color={me?.enableNSFWFilter ? "orange" : "gray"} checked={me?.enableNSFWFilter} onChange={(event) => setMe({...me, enableNSFWFilter: event.target.checked })} />

                <p className={`text-sm font-sans align-middle flex items-center ${me?.enableNSFWFilter ? "text-black" : "text-gray-500"} transition-all`}>{t("nsfwfilter_d")}</p>
            </div>

            <hr className="border-gray-300 mt-6" />

            <p className="mt-4 mb-3 text-base font-sans font-bold">{t("mylang")}</p>

            <div className="flex flex-col gap-2 items-start justify-start">
                <div className="w-full md:w-56">
                    <Select className="bg-white" color="orange" label={t("lang")} value={lang} onChange={(arg) => arg && setLang(arg)}>
                        <Option value='fr'>{t("french_d")}</Option>
                        <Option value='en'>{t("english_d")}</Option>
                        <Option value='de'>{t("deutsch_d")}</Option>
                        <Option value='it'>{t("italiano_d")}</Option>
                    </Select>
                </div>
                <button className="w-fit mx-auto mt-8" onClick={() => handleSubmit()}>
                    <GradientButton text={t("savechanges")} />
                </button>
            </div>
        </div>
    );
}
