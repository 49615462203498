import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Checkbox } from "@material-tailwind/react";
import moment from "moment";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

interface Item {
    key: string
    value: string
}

interface DropDownProps {
    title: string
    list: Item[]
    selected: string | undefined
    setSelected: (item: string) => void
    removeSelected: () => void
}

export default function DropDownMobile({ title, list,  selected, setSelected, removeSelected }: DropDownProps) {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    const returnLabel = (value: string) => {
        switch (title) {
            case "filters.date":
                return moment(value).format("DD.MM.YYYY");
            case "filters.license":
                return t(value)
            default:
                return value;
        }
    }

    const { t } = useTranslation();
    return (
        <>
            <button className="flex items-center justify-between w-full px-5 py-2 transition-all" onClick={toggleOpen}>
                {t(title)}
                <FontAwesomeIcon icon={open ? "chevron-up" : "chevron-down"} className="text-orange-400" />
            </button>
            <div className={`${open ? "h-fit" : "h-0"} overflow-hidden transition-all flex flex-col`}>
                {list.map((item) => (
                    <Checkbox 
                        key={item.key}
                        color="orange" 
                        label={returnLabel(item.value)}
                        checked={selected === item.key}
                        onChange={(e) => {
                        if (e.target.checked) {
                            setSelected(item.key);
                        } else {
                            removeSelected();
                        }
                    }} />
                ))}
            </div>
        </>
    );
}
