import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomeIn from "./Home/LogIn/Home";
import Home from "./Home/LogOut/Home";
import ForgotPassword from "./Signin/ForgotPassword";
import Signin from "./Signin/Signin";
import EmailConfirmation from "./Signup/EmailConfirmation";
import PhoneConfirmation from "./Signup/PhoneConfirmation";
import Signup from "./Signup/Signup";

import Favorites from "./Favorites/Favorites";

import EditGallery from "./Gallery/Edit";
import Gallery from "./Gallery/Gallery";

import EditPortfolio from "./Portfolio/Edit";
import Portfolio from "./Portfolio/Portfolio";

import Photographers from "./Photographers/Photographers";

import Account from "./Account/Account";
import Stats from "./Stats/Stats";
import Subscription from "./Subscription/Subscription";

import Upload from "./Upload/Upload";

import Contact from "./Contact/Contact";

import Page404 from "./Bundle/404/Page404";
import Contracts from "./Contracts/Contracts";
import Download from "./Download/Download";
import About from "./Other/About";
import Avis from "./Other/Avis";
import Cgu from "./Other/CGU";
import Cgv from "./Other/CGV";
import Contacts from "./Other/Contact";
import Privacy from "./Other/Privacy";
import Galeries from "./Research/Galeries/Galeries";
import Popular from "./Research/Popular/Popular";
import PopularPhotographers from "./Research/Popular/PopularPhotographer";
import Recent from "./Research/Recent/Recent";
import Search from "./Research/Search/Search";

import Purchases from "./Purchases/Purchases";
import Sales from "./Sales/Sales";

import Aide from "./Other/Aide";
import CGUNewsletter from "./Other/CGUNewsletter";
import TmpPortfolio from "./Portfolio/TmpPortfolio";
import RecentPhotographers from "./Research/Recent/RecentPhotographers";
import Teaser from "./Teaser/Teaser";
import UnSubscribe from "./Teaser/UnSubscribe";

import ContractsAccount from "./Account/components/Contracts";
import Data from "./Account/components/Data";
import Notifications from "./Account/components/Notifications";
import PortfolioAccount from "./Account/components/Portfolio";
import SubscriptionAccount from "./Account/components/Purchases";
import Security from "./Account/components/Security";
import CartTransaction from "./Cart/CartTransaction";
import AboutEdit from "./Portfolio/componentsEdit/AboutEdit";
import PerformanceAdd from "./Portfolio/componentsEdit/Performances/PerformanceAdd";
import PerformanceDetailEdit from "./Portfolio/componentsEdit/Performances/PerformanceDetailEdit";
import PerformancesEdit from "./Portfolio/componentsEdit/PerformancesEdit";
import PhotosEdit from "./Portfolio/componentsEdit/PhotosEdit";
import AboutPortfolio from "./Portfolio/componentsPortfolio/About";
import ContactPortfolio from "./Portfolio/componentsPortfolio/Contact";
import Appointment from "./Portfolio/componentsPortfolio/Contact/Appointment";
import Inquiry from "./Portfolio/componentsPortfolio/Contact/Inquiry";
import Offers from "./Portfolio/componentsPortfolio/Contact/Offers";
import Opinion from "./Portfolio/componentsPortfolio/Opinion";
import Performances from "./Portfolio/componentsPortfolio/Performances";
import PerformanceDetail from "./Portfolio/componentsPortfolio/Performances/PerformanceDetail";
import Photos from "./Portfolio/componentsPortfolio/Photos";
import EndTransaction from "./Subscription/EndTransaction";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { PictureModalProvider } from "../providers/PictureModalProvider";
import { updateGeneral } from "../services/redux/actions/generalActions";
import { RootState } from "../services/redux/store";
import { Storage } from "../services/storage";
import PortfolioOr404 from "./Bundle/404/PortfolioOr404";
import ContractsEdit from "./Contracts/ContractEdit";
import { dataLoggedIn, dataUnlogged } from "./fetchData";
import IamPhotographer from "./Home/IamPhotographer/IamPhotographer";
import Tarifs from "./Other/Tarifs";
import DoubleAuth from "./Signin/DoubleAuth";
import RecoverPassword from "./Signin/RecoverPassword";
import SignupConfirm from "./Signup/SignupConfirm";
import { PictureEditProvider } from "../providers/PictureEditProvider";
import { CartProvider } from "../providers/CartProvider";
import ScrollToTop from "../components/Scroll/ScrollToTop";

const token = Storage.getToken();

export default function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dataFetched, setDataFetched] = React.useState<boolean>(true);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();

    const fetchData = async (isLoggedin: boolean) => {
        try {
            if (!general.dataFetched) {
                const state = await (isLoggedin ? dataLoggedIn() : dataUnlogged());
                await dispatch(updateGeneral(state));
                setDataFetched(false);
                setLoading(false);
            } else {
                setDataFetched(false);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchData(!!token);
    }, []);

    if (loading) {
        return <Loader loading={loading} />;
    }

    if (dataFetched) {
        return <Loader loading={dataFetched} />;
    }

    if (!general.dataFetched) {
        return <Loader loading={!general.dataFetched} />;
    }

    const clientId = process.env.REACT_APP_GOOGLE_CODE_OAUTH ?? "";
    return (
        <BrowserRouter>
            <CartProvider>
                <PictureEditProvider>
                    <PictureModalProvider>
                        <GoogleOAuthProvider clientId={clientId}>
                        <ScrollToTop />
                            <Routes>
                                <Route path="/" element={token ? <HomeIn /> : <Home />} />
                                <Route path="/iam-photographer" element={<IamPhotographer />} />
                                <Route path="/teaser" element={<Teaser />} />

                                <Route path="/signin" element={<Signin />} />
                                <Route path="/signup" element={<Signup />} />
                                <Route path="/confirm-signup" element={<SignupConfirm />} />
                                <Route path="/verify-email" element={<EmailConfirmation />} />
                                <Route path="/forgotpassword" element={<ForgotPassword />} />
                                <Route path="/forgot-password" element={<RecoverPassword />} />
                                <Route path="/phone_confirmation" element={<PhoneConfirmation />} />
                                <Route path="/2fa" element={<DoubleAuth />} />

                                <Route path="/favorites" element={<Favorites />} />
                                <Route path="/favorites/:type" element={<Favorites />} />

                                <Route path="/gallery/:hashid/edit" element={token ? <EditGallery /> : <Navigate to="/" />} />
                                <Route path="/gallery/:hashid" element={<Gallery />} />

                                <Route path="/portfolio">
                                    <Route path="edit" element={token ? <EditPortfolio /> : <Navigate to="/" />}>
                                        <Route path="photos" element={<PhotosEdit />} />
                                        <Route path="about" element={<AboutEdit />} />
                                        <Route path="performances" element={<PerformancesEdit />} />
                                        <Route path="performance/:perf" element={<PerformanceDetailEdit />} />
                                        <Route path="addPerf" element={<PerformanceAdd />} />
                                    </Route>
                                    <Route path=":id" element={<Portfolio isPathPersonnal={false} />}>
                                        <Route path="photos" element={<Photos />} />
                                        <Route path="about" element={<AboutPortfolio />} />
                                        <Route path="performances" element={<Performances />} />
                                        <Route path="performance/:perf" element={<PerformanceDetail />} />
                                        <Route path="opinion" element={<Opinion />} />
                                        <Route path="contact" element={<ContactPortfolio />} />
                                        <Route path="offers" element={<Offers />} />
                                        <Route path="appointment" element={<Appointment />} />
                                        <Route path="inquiry" element={<Inquiry />} />
                                    </Route>
                                </Route>

                                <Route path="/my-portfolio" element={<TmpPortfolio />} />

                                <Route path="/recent" element={<Recent />} />
                                <Route path="/popular" element={<Popular />} />
                                <Route path="/galeries" element={<Galeries />} />
                                <Route path="/search">
                                    <Route path="" element={<Search />} />
                                    <Route path=":type/:value" element={<Search />} />
                                </Route>

                                <Route path="/photographers">
                                    <Route path="" element={<Photographers />} />
                                    <Route path=":type/:value" element={<Photographers />} />
                                </Route>

                                <Route path="/popular-photographers" element={<PopularPhotographers />} />
                                <Route path="/recent-photographers" element={<RecentPhotographers />} />

                                <Route path="/account" element={token ? <Account /> : <Navigate to="/" />}>
                                    <Route path="data" element={<Data />} />
                                    <Route path="portfolio" element={<PortfolioAccount />} />
                                    <Route path="preferences" element={<Notifications />} />
                                    <Route path="security" element={<Security />} />
                                    <Route path="subscription" element={<SubscriptionAccount />} />
                                    <Route path="contracts" element={<ContractsAccount />} />
                                    <Route path="*" element={<Navigate to="/account/data" />} />
                                </Route>

                                <Route path="/sales" element={token ? <Sales /> : <Navigate to="/" />} />
                                <Route path="/sales/:type" element={token ? <Sales /> : <Navigate to="/" />} />
                                <Route path="/purchases" element={token ? <Purchases /> : <Navigate to="/" />} />
                                <Route path="/statistics" element={token ? <Stats /> : <Navigate to="/" />} />
                                <Route path="/statistics/:type" element={token ? <Stats /> : <Navigate to="/" />} />
                                <Route path="/subscription/:sub_type" element={token ? <Subscription /> : <Navigate to="/" />} />
                                <Route path="/checkout" element={token ? <CartTransaction /> : <Navigate to="/" />} />

                                <Route path="/upload" element={token ? <Upload /> : <Navigate to="/" />} />

                                <Route path="/contact/:type/:id" element={token ? <Contact /> : <Navigate to="/" />} />

                                <Route path="/contracts" element={token ? <Contracts /> : <Navigate to="/" />} />
                                <Route path="/contracts/:id" element={token ? <ContractsEdit /> : <Navigate to="/" />} />
                                <Route path="/download/:id" element={token ? <Download /> : <Navigate to="/" />} />
                                <Route path="/checkout/:arg" element={token ? <EndTransaction /> : <Navigate to="/" />} />
                                <Route path="/contact" element={<Contacts />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/CGU" element={<Cgu />} />
                                <Route path="/CGU-newsletter" element={<CGUNewsletter />} />
                                <Route path="/CGV" element={<Cgv />} />
                                <Route path="/avis" element={<Avis />} />
                                <Route path="/tarifs" element={<Tarifs />} />
                                <Route path="/help" element={<Aide />} />

                                <Route path="/unsubscribe-newsletter/:id" element={<UnSubscribe />} />

                                <Route path="/:portfolio" element={<PortfolioOr404 />}>
                                    <Route path="photos" element={<Photos />} />
                                    <Route path="about" element={<AboutPortfolio />} />
                                    <Route path="performances" element={<Performances />} />
                                    <Route path="performance/:perf" element={<PerformanceDetail />} />
                                    <Route path="opinion" element={<Opinion />} />
                                    <Route path="contact" element={<ContactPortfolio />} />
                                    <Route path="offers" element={<Offers />} />
                                    <Route path="appointment" element={<Appointment />} />
                                    <Route path="inquiry" element={<Inquiry />} />
                                </Route>
                                <Route path="/*" element={<Page404 />} />
                            </Routes>
                            <ToastContainer
                                position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </GoogleOAuthProvider>
                    </PictureModalProvider>
                </PictureEditProvider>
            </CartProvider>
        </BrowserRouter >
    );
}
