import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import moment from "moment";
import { useState } from "react";

export default function DatePickerFilter({
    title,
    values,
    selected,
    configuration,
    setSelected,
    removeSelected
}: Readonly<{
    title: string,
    values: { takeDate: string }[],
    selected: string | undefined,
    configuration: any,
    setSelected: any,
    removeSelected: any
}>) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    function isDateDisabled(date: Date) {
        if (values.length === 0) return true;
        const dateString = moment(date).format("YYYY-MM-DD");
        return !values.some(entry => entry.takeDate === dateString);
    }

    return (
        <Popover placement="bottom-end" open={open}>
            <PopoverHandler
                className={`${selected ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""} border flex px-4 py-1 rounded-full flex-row items-center gap-2 inline-flex bg-white hover:bg-gray-50 transition-all`}
                onClick={() => setOpen(!open)}
            >
                <button color="white" className="inline-flex w-full justify-center rounded-md items-center font-light font-comfortaa">
                    {selected ? moment(selected, "YYYY-MM-DD").format("DD.MM.YYYY") : t(title)}
                    {selected ?
                        <FontAwesomeIcon icon="times" className="ml-2" onClick={(e) => {
                            e.stopPropagation();
                            removeSelected();
                        }} /> :
                        <FontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                    }
                </button>
            </PopoverHandler>
            <PopoverContent className="z-30 w-96 flex">
                <Calendar
                    onChange={(value: any) => {
                        setSelected(moment(value).format("YYYY-MM-DD"));
                        setOpen(false);
                    }}
                    value={moment(selected).format("YYYY-MM-DD") ?? moment()}
                    tileDisabled={({ date }) => isDateDisabled(date)}
                />
            </PopoverContent>
        </Popover>
    )
}