import { ContractType } from "../../../components/Interfaces/ContractType";

export const updateName = (name: string): any => ({
    type: "UPDATE_NAME",
    payload: name,
});

export const updateAvailable = (available: "UNLIMITED" | "LIMITED" | "SINGLE"): any => ({
    type: "UPDATE_AVAILABLE",
    payload: available,
});

export const updateOriginalCount = (originalCount: number): any => ({
    type: "UPDATE_ORIGINALCOUNT",
    payload: originalCount,
});

export const updateUntil = (until: string | null): any => ({
    type: "UPDATE_UNTIL",
    payload: until,
});

export const updateExclusive = (exclusive: boolean): any => ({
    type: "UPDATE_EXCLUSIVE",
    payload: exclusive,
});

export const updateLimited = (limited: boolean): any => ({
    type: "UPDATE_LIMITED",
    payload: limited,
});

export const updateLimitedCount = (limitedCount: number): any => ({
    type: "UPDATE_LIMITEDCOUNT",
    payload: limitedCount,
});

export const updateCommercialUse = (commercialUse: boolean): any => ({
    type: "UPDATE_COMMERCIALUSE",
    payload: commercialUse,
});

export const updateAutonomous = (autonomous: boolean): any => ({
    type: "UPDATE_AUTONOMOUS",
    payload: autonomous,
});

export const updateBranding = (branding: boolean): any => ({
    type: "UPDATE_BRANDING",
    payload: branding,
});

export const updateForSale = (forSale: string): any => ({
    type: "UPDATE_FORSALE",
    payload: forSale,
});

export const updateSalePaper = (salePaper: number): any => ({
    type: "UPDATE_SALEPAPER",
    payload: salePaper,
});

export const updateSaleDesk = (saleDesk: number): any => ({
    type: "UPDATE_SALEDESK",
    payload: saleDesk,
});

export const updateSaleMerch = (saleMerch: number): any => ({
    type: "UPDATE_SALEMERCH",
    payload: saleMerch,
});

export const updateDigitalModels = (digitalModels: boolean): any => ({
    type: "UPDATE_DIGITALMODELS",
    payload: digitalModels,
});

export const updatePrint = (print: boolean): any => ({
    type: "UPDATE_PRINT",
    payload: print,
});

export const updatePrintCount = (printCount: number): any => ({
    type: "UPDATE_PRINTCOUNT",
    payload: printCount,
});

export const updateUseByEmployerClient = (useByEmployerClient: boolean): any => ({
    type: "UPDATE_USEBYEMPLOYERCLIENT",
    payload: useByEmployerClient,
});

export const updateUseBySubcontractor = (useBySubcontractor: boolean): any => ({
    type: "UPDATE_USEBYSUBCONTRACTOR",
    payload: useBySubcontractor,
});

export const updateUserCount = (userCount: string): any => ({
    type: "UPDATE_USERCOUNT",
    payload: userCount,
});

export const updateBasePrice = (basePrice: number): any => ({
    type: "UPDATE_BASEPRICE",
    payload: basePrice,
});

export const updateContract = (contract: ContractType): any => ({
    type: "UPDATE_CONTRACT",
    payload: contract,
});