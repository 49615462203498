import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip, IconButton, Typography } from "@material-tailwind/react";
import AutosizeInput from "react-input-autosize";
import { useTranslation } from "react-i18next";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ModalChangeBackgroundGallry from "../ModalChangeBackgroundGallery";
import { useSelector } from "react-redux";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import { Menu, Transition } from "@headlessui/react";
import ModalOrganize from "../../../../components/Modals/Organize/ModalOrganize";
import { RootState } from "../../../../services/redux/store";
import { Link } from "react-router-dom";
import moment from "moment";
import InputPlace from "../../../../components/Form/Input/InputPlace";
import { v4 as uuidv4 } from "uuid";
import PublishGalleryButton from "../../../Portfolio/componentsEdit/PublishButtonEdit";

export default function MobileGalleryHeader({
    searchBar,
    hashid,
    data,
    picturesLength,
    tmpNewBanner,
    setTmpNewBanner,
    handleSave,
    handleSavePublish,
    handleChange,
    changeBannerOffset,
    handleChangeDate,
    onSelected
}: Readonly<{
    searchBar: boolean,
    hashid: string | undefined;
    data: GalleryInterface;
    picturesLength: number,
    tmpNewBanner: { id: string, minPath: string },
    setTmpNewBanner: Function,
    handleSave: Function,
    handleSavePublish: Function,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeBannerOffset: (number: number) => void;
    handleChangeDate: (date: string) => void;
    onSelected: (value: string, label: string | undefined) => void;
}>) {
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalChangePlan, setOpenModalChangePlan] = React.useState(false);
    const general = useSelector((state: RootState) => state.general);
    const { t } = useTranslation();
    const [hidden, setHidden] = React.useState(false);
    const [sortBy, setSortBy] = React.useState("");
    const [openModalOrganize, setOpenModalOrganize] = React.useState(false);
    const [uuid, setUuid] = React.useState<string>("");
    const parentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputWidth, setInputWidth] = useState('100px');

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const handleOpenModal = () => setOpenModalOrganize(true);
    const handleCloseModal = () => setOpenModalOrganize(false);

    const handleChangeBackground = (id: string, minPath: string) => {
        setTmpNewBanner({ id, minPath });
        setOpenModal(false);
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setInputWidth(`calc(${parentHeight}px - 60px)`);
        }
    }, [window.innerWidth, window.outerWidth]);

    return (
        <>
            <PictureDrawerForFilters />
            <ModalOrganize open={openModalOrganize} onClose={handleCloseModal} />
            <ModalChangePlan open={openModalChangePlan} setOpen={setOpenModalChangePlan} />
            <ModalChangeBackgroundGallry handleSelectBackground={handleChangeBackground} open={openModal} setOpen={() => setOpenModal(!openModal)} />
            <div
                className="flex md:hidden relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${
                        (tmpNewBanner && tmpNewBanner.minPath !== "" && tmpNewBanner.minPath) || (data && data.banner && data.banner.publicPath) || DefaultBannerEzoom
                    })`,
                }}
            >
                <div
                    ref={parentRef}
                    style={{ height: "calc(100% - 60px)", width: "40px" }}
                    className={`absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center ${((tmpNewBanner && tmpNewBanner.minPath !== "" && tmpNewBanner.minPath) || (data && data.banner && data.banner.publicPath)) ? "opacity-100" : "opacity-0"}`}
                >
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={data.bannerOffset}
                        onChange={(e) => changeBannerOffset(Number(e.target.value))}
                        ref={inputRef}
                        style={{ width: inputWidth }}
                        className="-rotate-90 transparent h-[4px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                        id="customRange1"
                    />
                </div>
                <div style={{ height: "40px" }} className="absolute bottom-2 right-2 flex flex-col items-center justify-center">
                    <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer" onClick={() => setOpenModal(true)}>
                        <FontAwesomeIcon icon="pen" />
                    </IconButton>
                </div>
            </div>
            <div className="block md:hidden">
                <div className="flex flex-row flex-wrap md:flew-nowrap justify-end md:justify-end gap-2 mt-2 px-4">
                    {!data.published && (
                        // <button
                        //     className="border bg-orange-500 hover:bg-orange-200 border-transparent inline w-auto text-white hover:text-black py-1.5 px-4 rounded-full disabled:bg-gray-200 disabled:hover:text-white"
                        //     style={{
                        //         transition: "all .25s ease-in-out",
                        //     }}
                        //     onClick={() => handleSavePublish()}
                        // >
                        //     <span className="flex">{t(data.private ? "saveandpublishprivate" : "saveandpublish")}</span>
                        // </button>
                        <PublishGalleryButton onPublish={() => handleSavePublish()} data={data} banner={data.banner} tmpBanner={tmpNewBanner} pictures={picturesLength} />
                    )}
                    <button type="button" onClick={() => handleSave()} >
                        <GradientButton text={t("save")} />
                    </button>
                    <Link to={`/upload`}>
                        <button className="border inline w-fit hover:text-black py-1.5 px-4 rounded-full">
                            <span className="flex">+</span>
                        </button>
                    </Link>
                    <a href={`/gallery/${hashid}`}>
                        <button className="border inline w-fit hover:text-black py-1.5 px-4 rounded-full">
                            <span className="flex">{t("cancel")}</span>
                        </button>
                    </a>
                </div>
                <Typography className="text-4xl font-bold text-center font-sans px-4 mt-4">
                    <AutosizeInput
                        type="text"
                        value={data.title ?? ""}
                        name="title"
                        placeholder="Titre"
                        onChange={handleChange}
                        className="border-b pb-1 border-b-gray-500"
                        style={{ fontFamily: "Great Vibes" }}
                    />
                </Typography>
                <div className="w-full flex flex-row justify-center items-end gap-2 mt-4 px-4">
                    <FontAwesomeIcon icon="map-marker-alt" className="text-gray-500 mb-2.5" />
                    <div className="w-fit">
                        <InputPlace uuid={uuid} onSelect={onSelected} value={data.place} whichStyle="other" />
                    </div>
                    <FontAwesomeIcon icon="calendar" className="text-gray-500 mb-2.5" />
                    <input type="date" placeholder="Date" value={data.date} onChange={(e) => handleChangeDate(e.target.value)} className="border-b pb-1 border-b-gray-500" />
                    {general?.me && (general?.me?.plan === "PRO") ? (
                        <button className="flex flex-row gap-2 items-center" onClick={() => data.private ? handleChange({ target: { name: "private", value: "nok" } } as React.ChangeEvent<HTMLInputElement>) :  handleChange({ target: { name: "private", value: "ok" } } as React.ChangeEvent<HTMLInputElement>)}>
                            <FontAwesomeIcon icon={data.private ? "lock" : "lock-open"} className="text-gray-500 mb-2.5" />
                            <Typography className="border-b pb-1 border-b-gray-500">{data.private ? t("private") : t("public")}</Typography>
                        </button>
                    ) : (
                        <button className="flex flex-row gap-2 items-center" onClick={() => setOpenModalChangePlan(!openModalChangePlan)}>
                            <FontAwesomeIcon icon="lock-open" className="text-gray-500 mb-2.5" />
                            <Typography className="border-b pb-1 border-b-gray-500">{t("public")}</Typography>
                        </button>
                    )}
                </div>
                <div className="flex flex-col md:flex-row justify-between px-4 mt-4 max-w-7xl mx-auto w-full mb-4">
                    <div className="flex flex-row justify-center md:justify-start gap-4 mt-4 px-4 mx-auto w-full md:w-1/3">
                        <Avatar src={data.portfolio.picture ?? DefaultPictureEzoom} />
                        <div className="flex flex-col">
                            <Typography className="text-lg font-bold">{data.portfolio.name}</Typography>
                            <Typography className="text-sm">Photographe</Typography>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center gap-4 mt-4 px-4 mx-auto w-full md:w-1/3">
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${picturesLength ?? 0} ${t("photos")}`}
                            icon={<FontAwesomeIcon icon="images" className="h-4 mt-0.5" />}
                        />
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${data.viewCount ?? 0} ${t("views")}`}
                            icon={<FontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                        />
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${data.favoriteCount ?? 0} ${t("favs")}`}
                            icon={<FontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                        />
                    </div>
                    <div className="flex flex-row justify-center md:justify-end gap-4 mt-4 px-4 mx-auto w-full md:w-1/3 text-gray-500">
                       
                    </div>
                </div>
            </div>
            <motion.div
                variants={{
                    visible: { top: "48px", paddingTop: "12px" },
                    hidden: { top: "0px", paddingTop: "4px" },
                    barsInactive: { top: "48px", paddingTop: "12px" },
                    barsActive: { top: "102px", paddingTop: "8px" },
                }}
                animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                transition={{
                    duration: 0.35,
                    ease: "easeInOut",
                }}
                className="flex md:hidden justify-end gap-2 pr-2 mt-4 -mb-4 sticky top-0 md:pt-0 pb-1 md:pb-0 bg-white z-30 border-b border-gray-200"
            >
                <div className="flex pr-1.5 border-r border-r-gray-200">
                    <button onClick={handleOpenModal} className="border inline w-fit hover:text-black py-2 mt-0.5 px-3 rounded-full text-xs h-[37.2px]">
                        <span className="flex">{t("organize")}</span>
                    </button>
                </div>

                <MobileFilterButton />

                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button
                            className={`${
                                sortBy !== "" ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""
                            } border flex px-4 py-2.5 rounded-full flex-row items-center gap-2 hover:bg-white text-xs`}
                        >
                            {t("sortby")}
                            <FontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className={`absolute right-0 z-50  flex-col px-2 py-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                        >
                            <div className="py-1">
                                {["default", "note"].map((item: string) => (
                                    <Menu.Item key={item}>
                                        <button
                                            onClick={() => setSortBy(item)}
                                            className={`${
                                                sortBy === item && "bg-orange-500 text-white"
                                            } my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg`}
                                        >
                                            {t(item)}
                                            {sortBy === item && <FontAwesomeIcon icon="check" className="ml-2" />}
                                        </button>
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </motion.div>
        </>
    );
}
