import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import moment from "moment";
import MapPicker from "../../NavBar/composants/MapPicker";
import React, { useEffect } from "react";

export default function MapPickerFilter({
    title,
    values,
    selected,
    configuration,
    setSelected,
    removeSelected
}: Readonly<{
    title: string,
    values: { takeDate: string }[],
    selected: string | undefined,
    configuration: any,
    setSelected: any,
    removeSelected: any
}>) {
    const { t } = useTranslation();
    const [regions, setRegions] = React.useState<string[]>([])

    useEffect(() => {
        const tmpRegions = values.map((v: any) => v.code)
        setRegions(tmpRegions)
    }, [values])

    return (
        <Popover placement="bottom-end">
            <PopoverHandler
                className={`${selected ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""} border flex px-4 py-1 rounded-full flex-row items-center gap-2 inline-flex bg-white hover:bg-gray-50 transition-all`}
            >
                <button color="white" className="inline-flex w-full justify-center rounded-md items-center font-light font-comfortaa">
                    {selected ? t(selected) : t(title)}
                    {selected ?
                        <FontAwesomeIcon icon="times" className="ml-2" onClick={(e) => {
                            e.stopPropagation();
                            removeSelected();
                        }} /> :
                        <FontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                    }
                </button>
            </PopoverHandler>
            <PopoverContent className="z-30 w-96 flex">
                <MapPicker
                    disabledArea={regions}
                    selected={selected}
                    setSelected={setSelected}
                />
            </PopoverContent>
        </Popover>
    )
}