import { useTranslation } from "react-i18next";
import GradientButtonPurple from "../../../../components/Form/Button/GradientButtonPurple";
import { Link } from "react-router-dom";
import SwiperPhotograph from "../../../../components/Box/Photograph/SwiperPhotograph";
import { SearchPortfolioType } from "../../../../components/Interfaces/PortfolioType";
import { useEffect, useState } from "react";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Mandate() {
    const { t } = useTranslation();
    const [photographer, setPhotographer] = useState<SearchPortfolioType>({ 
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0
        },
        data: [] 
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await PortfolioAPI.searchPortfolioPaLogOut()
            if (response.status === 200)
                setPhotographer(response.body);
            else handleError(response);
        }
        fetchData();
    }, []);
    
    return (
        <div className="m-0 pb-5 relative bg-white overflow-hidden bg-no-repeat bg-image-popular bg-cover font-sans">
            <div className="relative z-10 pb-4 max-w-7xl mx-auto flex flex-col-reverse md:flex-row px-8">
                <div className="flex justify-center w-full md:w-1/2 md:mt-12 md:pr-4">
                    <SwiperPhotograph galeries={photographer.data} />
                </div>
                <div className="flex flex-col w-full md:w-1/2 md:pl-4 mt-6">
                    <p className="mt-12 text-left text-4xl">{t("mandate")}</p>
                    <p className="text-left mt-4 mx-auto">{t("mandate_long")}</p>
                    <p className="text-left mt-4 mb-8">
                        <Link to="/photographers">
                            <GradientButtonPurple text={t("explorePhotographs")} />    
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
