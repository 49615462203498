export const NavigationCustomStyles = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
        border: "0px",
    }),
    menubar: (base: any) => ({
        ...base,
        border: "0px",
    }),
    menuList: (base: any) => ({
        ...base,
        padding: "0 0.5rem",
        border: "0px",
    }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px white" : "",
        borderRadius: "0px",
        border: "0px",
        ring: "0px",
        outlineWidth: "0px",
        borderBottom: state.isFocused ? "1px solid rgb(239, 147, 53)" : "1px solid rgb(176, 190, 197)", 
        backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "white",
        color: "rgb(239, 147, 53)",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(176, 190, 197)",
        },
        fontWeight: "bold",
        fontSize: "14px"
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "lightgray",
            color: state.isSelected ? "white" : "black",
        },
        borderRadius: "0.375rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        fontWeight: "bold"
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
};
