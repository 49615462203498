import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import LogoBetaWhite from "../../assets/images/logos/ezoombetaneg.png";

export default function MinimalFooter() {
    const { t } = useTranslation();
    return (
        <footer className="absolute bottom-0 left-0 right-0 bg-black text-white flex flex-row flex-wrap md:flex-nowrap justify-between items-center px-4 py-4 h-18">
            <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start items-center font-comfortaa text-xs gap-4 w-full">
                {/* <div className="hidden md:block w-1/3"><LogoE height="22" /></div> */}
                <div className="hidden md:block w-1/3"><img src={LogoBetaWhite} alt="logo" className="w-20" /></div>
                <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-center items-center font-comfortaa text-xs gap-4 w-full md:w-1/3">
                <Link className="hidden md:flex cursor-pointer whitespace-nowrap hover:decoration-1 hover:underline" to="/CGU-newsletter">
                    {t("CG")}
                </Link>
                <Link className="flex md:hidden cursor-pointer whitespace-nowrap hover:decoration-1 hover:underline" to="/CGU-newsletter">
                    {t("C")}
                </Link>
                <Link className="hidden md:flex cursor-pointer whitespace-nowrap hover:decoration-1 hover:underline" to="/privacy">
                    {t("privacyPolitic")}
                </Link>
                <Link className="flex md:hidden cursor-pointer whitespace-nowrap hover:decoration-1 hover:underline" to="/privacy">
                    {t("privacy")}
                </Link>
                <Link className="cursor-pointer whitespace-nowrap hover:decoration-1 hover:underline" to="mailto:contact@ezoom.ch">
                    {t("contact_us")}
                </Link>
                </div>
                <div className="hidden md:block w-1/3">
                </div>
            </div>
            {/* <div className="md:hidden mt-4 mx-auto"><LogoE height="20" /></div> */}
            <div className="md:hidden mt-4 mx-auto"><img src={LogoBetaWhite} alt="logo" className="w-20" /></div>
        </footer>
    );
}
