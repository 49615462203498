import { CompatibleFilterList, FetchFiltersFunction, FilterList, FilterRenderConfiguration, FilterRenderer, FilterType, SortOption } from "../types/global";
import DropDown from "./DropDownFilter";
import { FilterConfiguration } from "./FilterConfiguration";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import DatePickerFilter from "./DatePickerFilter";
import MapPickerFilter from "./MapPickerFilter";
import { Option, Select } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import BooleanFilter from "./BooleanFilter";
import DropDownTitle from "./DropDownTitle";

interface SortByProps {
    sortOptions: SortOption[];
    defaultSort?: string;
    setSort: (sort: string | undefined) => void;
    sort: string | undefined;
}
function SortBy(props: Readonly<SortByProps>) {
    const { t } = useTranslation();

    return (
        <div className="mt-2 hidden md:flex">
            <DropDownTitle
                setSort={props.setSort}
                sort={props.sort}
                sortOptions={props.sortOptions}
                defaultSort={props.defaultSort}
            />
            {/* <select
                placeholder={t("sortby")}
                className="placeholder:text-black text-black  border flex px-4 py-1 rounded-full flex-row items-center gap-2 bg-white hover:bg-gray-50 focus:rounded-full focus-visible:rounded-full focus:border  hover:border-orange-500"
                // labelProps={{ className: "absolute rounded-full focus:rounded-full before:hidden after:hidden border focus:border-orange-500 top-0 text-black pl-4 pt-2 text-base" }}
                color="orange"
                // onChange={(p) => props.setSort(p)}
                value={props.sort}
            >
                {[
                    {
                        key: props.defaultSort ?? "default",
                        value: "default",
                    },
                    ...props.sortOptions,
                ].map((option) => (
                    // <Option key={option.key} value={option.key}>
                    <option key={option.key} value={option.key}>
                        {t(option.value)}
                    </option>
                    // </Option>
                ))}
            </select> */}
        </div>
    );
}

interface FiltersProps<FiltersType extends FilterList, CompatibleFiltersType extends CompatibleFilterList> {
    filters: FilterConfiguration<FiltersType>;
    renderConfiguration: FilterRenderConfiguration<CompatibleFiltersType>;
    fetchFilters: FetchFiltersFunction<FiltersType, CompatibleFiltersType>;
    sortOptions: SortOption[];
    defaultSort?: string;
}

export default function Filters<FiltersType extends FilterList, CompatibleFiltersType extends CompatibleFilterList>(
    props: Readonly<FiltersProps<FiltersType, CompatibleFiltersType>>
) {
    const currentFilters = props.filters.getAdditionalFilters();

    const dispatch = useDispatch();
    const availableFilters = useSelector((state: any) => state.filters.compatibleFilters);

    const fetchFilters = async () => {
        const result = await props.fetchFilters(props.filters);
        if (!result) {
            return;
        }
        if (props.filters.getAllFilters().galleries === true) {
            (result as any).galleries = true;
        }
        dispatch({ type: "SET_COMPATIBLE_FILTERS", payload: { filters: result } });
    };

    useEffect(() => {
        fetchFilters();
    }, [JSON.stringify(currentFilters)]);

    const Renderers: { [key in FilterType]: FilterRenderer<CompatibleFiltersType> } = {
        [FilterType.BOOLEAN]: (name, values, selected, configuration) => {
            return (
                <BooleanFilter
                    title={`filters.${name as string}`}
                    value={selected as true | undefined}
                    onChange={(value) => {
                        if (value === true) {
                            props.filters.addFilter(name as keyof FiltersType, true);
                        } else {
                            props.filters.removeFilter(name as keyof FiltersType);
                        }
                    }}
                />
            );
        },
        [FilterType.DROPDOWN]: (name, values, selected, configuration) => {
            return (
                <DropDown
                    title={`filters.${name as string}`}
                    list={values.map((v) => ({
                        key: configuration.getKey(v),
                        value: configuration.getValue(v),
                    }))}
                    selected={selected as string}
                    side="left"
                    setSelected={(key: string) => props.filters.addFilter(name as keyof FiltersType, key)}
                    removeSelected={() => props.filters.removeFilter(name as keyof FiltersType)}
                />
            );
        },
        [FilterType.DATEPICKER]: (name, values, selected, configuration) => {
            return (
                <DatePickerFilter
                    title={`filters.${name as string}`}
                    values={values}
                    selected={selected as string}
                    configuration={configuration}
                    setSelected={(key: string) => props.filters.addFilter(name as keyof FiltersType, key)}
                    removeSelected={() => props.filters.removeFilter(name as keyof FiltersType)}
                />
            );
        },
        [FilterType.MAPPICKER]: (name, values, selected, configuration) => {
            return (
                <MapPickerFilter
                    title={`filters.${name as string}`}
                    values={values}
                    selected={selected as string}
                    configuration={configuration}
                    setSelected={(key: string) => {
                        props.filters.addFilter(name as keyof FiltersType, key);
                        props.filters.addFilter("country", "CHE");
                    }}
                    removeSelected={() => {
                        props.filters.removeFilter(name as keyof FiltersType);
                        props.filters.removeFilter("country");
                    }}
                />
            );
        },
    };
    return (
        <div className="flex w-full justify-end md:justify-between mb-4">
            <div className="hidden md:flex flex-1 flex-row flex-wrap gap-2 items-center py-2">
                {availableFilters &&
                    Object.keys(availableFilters)
                        .filter((key) => props.renderConfiguration[key as keyof CompatibleFiltersType])
                        .sort((a, b) => Object.keys(props.renderConfiguration).indexOf(a) - Object.keys(props.renderConfiguration).indexOf(b))
                        .map((key) => {
                            if (!availableFilters) return;
                            const value = availableFilters[key as keyof CompatibleFiltersType];
                            if (!value) return;
                            const config = props.renderConfiguration[key];
                            if (!config) return null;
                            return Renderers[config.type](key, value, currentFilters[key], props.renderConfiguration[key]);
                        })}
            </div>
            <SortBy sortOptions={props.sortOptions} defaultSort={props.defaultSort} setSort={(v) => props.filters.sortBy(v)} sort={props.filters.getSortBy()} />
        </div>
    );
}
