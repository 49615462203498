import { useSelector, useDispatch } from "react-redux";
import { FilterStoreInterface } from "../../../services/redux/reducers/filtersReducer";
import { Badge, IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownTitle from "../filters/DropDownTitle";
import { SortOption } from "../types/global";

const MobileFilterButtonFilter = () => {
  const filters: FilterStoreInterface = useSelector(
    (state: any) => state.filters
  );
  const dispatch = useDispatch();

  const enabledFilters = filters.currentFilters
    ? Object.keys(filters.currentFilters).filter(
        (key) => filters.currentFilters && filters.currentFilters[key]
      )
    : [];

  const openDrawer = () => {
    dispatch({ type: "OPEN_MOBILE_DRAWER" });
  };

  return enabledFilters.length === 0 ? (
    <IconButton
      onClick={openDrawer}
      color="white"
      className="ring-1 ring-gray-400/50 h-[37.2px] w-[37.2px] rounded-full"
    >
      <FontAwesomeIcon icon="sliders" />
    </IconButton>
  ) : (
    <Badge
      withBorder
      placement="top-start"
      color="orange"
      content={enabledFilters.length}
      className="text-[8px] h-2 w-2 p-0 left-1 top-1 min-h-[16px] min-w-[16px]"
    >
      <IconButton
        onClick={openDrawer}
        className="ring-1 bg-orange-500/30 text-black ring-gray-400/50 h-[37.2px] w-[37.2px] rounded-full"
      >
        <FontAwesomeIcon icon="sliders" />
      </IconButton>
    </Badge>
  );
};


interface SortByProps {
    sortOptions: SortOption[];
    defaultSort?: string;
    setSort: (sort: string | undefined) => void;
    sort: string | undefined;
}


const MobileFilterButton = () => {
    return (
        <div className="flex gap-1 items-center">
        <MobileFilterButtonFilter />
        </div>
    );
    }



export default MobileFilterButton;
