import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { PictureType } from "../../components/Interfaces/PictureType";

import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import Loader from "../../components/Loader";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";

import { Link } from "react-router-dom";
import AvatarDefaultEzoom from "../../assets/images/elements/default-profile.png";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { handleError } from "../../services/Errors/handleErrors";

export default function Download() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("download")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [picture, setPicture] = React.useState<PictureType>({
        id: "",
        exifData: [],
        keywords: [],
        category: {
            enumValue: "",
            frLabel: "",
            enLabel: "",
        },
        nsfw: false,
        license: "",
        contract: {
            id: "",
        },
        uploadedAt: "",
        favoriteCount: 0,
        viewCount: 0,
        publicPath: "",
        watermarkUptoDate: false,
        canBeBought: false,
        minPath: "",
        isFavorited: false,
        galleries: [],
        identifiablePeople: false,
        identifiableProperties: false,
        portfolio: {
            id: "",
            name: "",
            picture: "",
            publicLink: "",
            verified: false,
        },
        portfolios: [],
        watermark: "",
        writtenContract: false,
    });
    const [linkToDownload, setLinkToDownload] = React.useState("");

    const fetchData = async () => {
        const currentUrl = window.location.href.split("/")[4];
        const response = await PicturesAPI.getPicture(currentUrl);
        if (response.status === 200) {
            setPicture(response.body);
        } else {
            handleError(response);
        }
        const responseDownload = await PicturesAPI.downloadPicture(currentUrl);
        if (responseDownload.status === 200) {
            const imageUrl = responseDownload.body.originalPath;
            setLinkToDownload(imageUrl);
        } else {
            handleError(responseDownload);
        }
    };

    useEffect(() => {
        setLoading(false);
        fetchData();
    }, []);

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <Loader loading={loading} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <TitlePage>
                        <FontAwesomeIcon icon="download" className="mr-1 text-orange-500" />
                        {t("download")}
                    </TitlePage>
                    <WrapperGrayZone pt="6" px="8">
                        <div className="flex flex-col md:flex-row max-w-7xl mx-auto">
                            <div className="flex flex-col w-full md:w-1/2">
                                <Card shadow={false} className="relative grid h-[34rem] w-full items-end justify-center overflow-hidden text-center">
                                    <CardHeader
                                        floated={false}
                                        shadow={false}
                                        color="transparent"
                                        className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
                                        style={{ backgroundImage: `url("${picture.minPath}")` }}
                                    >
                                        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/90 via-black/20" />
                                    </CardHeader>
                                    <CardBody className="relative py-14 px-6 md:px-12">
                                        <Typography variant="h5" className="mb-2 text-gray-300">
                                            {picture.portfolio.name}
                                        </Typography>
                                        <Avatar size="xl" variant="circular" alt="tania andrew" src={picture.portfolio.picture ?? AvatarDefaultEzoom} />
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="flex flex-col w-full md:w-1/2">
                                <table className="h-full w-full table-auto text-left">
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="border-r border-blue-gray-100/50 font-normal leading-none opacity-70 rounded-tl"
                                            >
                                                {t("title")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {picture?.title ?? "/"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("caption")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {picture?.description ?? "/"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("licence")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {t(picture?.license ?? "/")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("width")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {t(picture?.height?.toString() ?? "/")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("height")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {t(picture?.height?.toString() ?? "/")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("nsfw")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {picture?.nsfw ? "Actif" : "Inactif"}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70 rounded-tl">
                                                {t("contract")}
                                            </Typography>
                                        </th>
                                        <td className="p-4 border-b border-blue-gray-100/80">
                                            <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                {t(picture?.contract?.available ?? "/")} <br />
                                                {t("exclusivity") + " : " + (picture?.contract?.exclusive ? "Oui" : "Non")} <br />
                                                {t("commercialUse") + " : " + (picture?.contract?.commercialUse ? "Oui" : "Non")}
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="mt-4 max-w-7xl mx-auto w-fit">
                            <Link to={linkToDownload} download={true} target="_blank">
                                <GradientButton text="download" />
                            </Link>
                        </div>

                        <Typography
                            variant="h6"
                            className="mt-8 text-center text-blue-gray-700 border-b border-blue-gray-100/80 pb-4 max-w-7xl mx-auto"
                        >
                            {t("exif")}
                        </Typography>
                        <table className="max-w-7xl mx-auto w-full table-auto text-left">
                            {picture.exifData?.length > 0 &&
                                picture.exifData.map((exif, index) => {
                                    if (exif.value && exif.key !== "computed.html")
                                        return (
                                            <tr key={exif.key}>
                                                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl w-1/2">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70 rounded-tl"
                                                    >
                                                        {t(exif.key)}
                                                    </Typography>
                                                </th>
                                                <td className="p-4 border-b border-blue-gray-100/80">
                                                    <Typography variant="small" color="blue-gray" className="font-normal opacity-60">
                                                        {exif.value}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                })}
                        </table>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
