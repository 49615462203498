import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Radio, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateUserCount } from "../../../services/redux/actions/contractActions";

export default function UsersLicence() {
    const { t } = useTranslation();
    const [openGeneralites, setOpenGeneralites] = React.useState(false);
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateUserCount(e.target.value));
    };

    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenGeneralites(!openGeneralites)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">6. {t("howmuchuser")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openGeneralites ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openGeneralites} className="flex flex-col gap-4 pt-2">
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("howmuchuser_d")}
                </Typography>
                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="userLimit"
                    value="SINGLE"
                    onChange={handleChange}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("oneuser")}</Typography>}
                    checked={contract.userCount === "SINGLE"}
                />
                <Typography variant="small" className={`w-auto text-justify -mt-4 px-4 ${contract.userCount === "SINGLE" ? "" : "text-gray-500"}`}>
                    {t("oneuser_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="userLimit"
                    value="ORGANIZATION"
                    onChange={handleChange}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("unlimited_orga_users")}</Typography>}
                    checked={contract.userCount === "ORGANIZATION"}
                />
                <Typography variant="small" className={`w-auto text-justify -mt-4 px-4 ${contract.userCount === "ORGANIZATION" ? "" : "text-gray-500"}`}>
                    {t("unlimited_orga_users_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="userLimit"
                    value="UNLIMITED"
                    onChange={handleChange}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("unlimited_users")}</Typography>}
                    checked={contract.userCount === "UNLIMITED"}
                />
                <Typography variant="small" className={`w-auto text-justify -mt-4 px-4 ${contract.userCount === "UNLIMITED" ? "" : "text-gray-500"}`}>
                    {t("unlimited_users_d")}
                </Typography>
            </Collapse>
        </div>
    );
}
