import { useTranslation } from "react-i18next";
import { Services } from "../../Interfaces/PortfolioType";
import DefaultEzoomBanner from "../../../assets/images/elements/default-banner.png";
import { Chip } from "@material-tailwind/react";

export default function Performance ({ data } : Readonly<{ data: Services }>) {
    const { t } = useTranslation();
    return (
        <div className="relative h-72 w-full hover:opacity-75 transition-all">
            <img
                className="h-full w-full rounded-xl object-cover object-center"
                src={data?.banner?.minPath ?? DefaultEzoomBanner}
                alt={data.title}
            />
            <div className="absolute top-1/2 -translate-y-1/2 left-0 justify-center w-full text-center">
                <p className="text-white line-clamp-3 text-4xl font-sans" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    {data.title}
                </p>
                <p className="text-white mt-2 font-normal text-lg" style={{ textShadow: "#000000BB 1px 0 10px" }}>
                    {t(data.priceType)} {data.priceValue && `${t("chf")} ${data.priceValue}.-`}{data.priceCeil && ` ${t("to")} ${t("chf")} ${data.priceCeil}.-`} 
                </p>
                <button className="text-sm px-4 py-2 mt-2 bg-gray-50 hover:bg-gray-50/75 rounded-full hover:text-black">
                    {t("learnmore")}
                </button>
                {!data.published && <Chip color="orange" value={t("unpublished")} className="w-fit mx-auto mt-2" />}
            </div>
        </div>
    );
}
