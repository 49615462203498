import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function HeaderImg({
    setOpeners,
}: Readonly<{
    setOpeners: Function
}>) {
    const { t } = useTranslation();
    const contract = useSelector((state: any) => state.contract);

    const [pieceHover, setPieceHover] = React.useState<{
        limit: boolean;
        exclusivity: boolean;
        commercial: boolean;
        price: boolean;
    }>({
        limit: false,
        exclusivity: false,
        commercial: false,
        price: false,
    });

    const scrollToSection = (arg: string) => {
        const sectionB = document.getElementById(`section${arg}`);
        if (sectionB) {
            if (arg === "1") {
                setOpeners((prevOpeners: { limit: any; }) => ({ ...prevOpeners, limit: true }));
                setTimeout(() => {
                    sectionB.scrollIntoView({ behavior: "smooth" });
                }, 200);
            } else if (arg === "2") {
                setOpeners((prevOpeners: { exclusivity: any; }) => ({ ...prevOpeners, exclusivity: true }));
                setTimeout(() => {
                    sectionB.scrollIntoView({ behavior: "smooth" });
                }, 200);
            } else if (arg === "3") {
                setOpeners((prevOpeners: { commercial: any; }) => ({ ...prevOpeners, commercial: true }));
                setTimeout(() => {
                    sectionB.scrollIntoView({ behavior: "smooth" });
                }, 200);
            } else
                setTimeout(() => {
                    sectionB.scrollIntoView({ behavior: "smooth" });
                }, 200);
        }
    };

    return (
        <div className="flex flex-row flex-wrap justify-between items-center px-4 py-2">
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-8">
                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                    <FontAwesomeIcon icon="image" className="text-gray-600 h-12 mb-2" />
                    <div className="flex flex-col">
                        <Typography className="text-center text-base text-gray-500">{t("imagejpeg")} XXXX x XXXX px</Typography>
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-8">
                <button
                    className={`relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center ${pieceHover.limit && "bg-gray-100"}`}
                    onMouseLeave={() => setPieceHover({ ...pieceHover, limit: false })}
                    onMouseEnter={() => setPieceHover({ ...pieceHover, limit: true })}
                    onClick={() => scrollToSection("1")}
                >
                    {contract.available === "UNLIMITED" && (
                        <>
                            <FontAwesomeIcon icon="infinity" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("unlimited_content")}</Typography>
                        </>
                    )}
                    {contract.available === "SINGLE" ? (
                        <>
                            <FontAwesomeIcon icon="medal" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("unique")}</Typography>
                        </>
                    ) : (
                        contract.available === "LIMITED" && (
                            <>
                                <FontAwesomeIcon icon="boxes-stacked" className="text-gray-600 h-12 mb-2" />
                                <Typography className="text-center text-base text-gray-500">{t("limited_content")}</Typography>
                            </>
                        )
                    )}
                    <button
                        className={`cursor-pointer absolute -top-2 -right-2 bg-white h-12 w-12 rounded-full shadow-lg flex items-center justify-center ring ring-gray-500/20 transition ${
                            pieceHover.limit ? "opacity-100 transition duration-300" : "opacity-0 transition duration-300"
                        }`}
                        onClick={() => scrollToSection("1")}
                    >
                        <FontAwesomeIcon icon="pen" className="text-gray-500" onClick={() => scrollToSection("1")} />
                    </button>
                </button>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-8">
                <button
                    className={`relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center ${pieceHover.exclusivity && "bg-gray-100"}`}
                    onMouseLeave={() => setPieceHover({ ...pieceHover, exclusivity: false })}
                    onMouseEnter={() => setPieceHover({ ...pieceHover, exclusivity: true })}
                    onClick={() => scrollToSection("2")}
                >
                    {contract.exclusive ? (
                        <>
                            <FontAwesomeIcon icon="file-shield" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("exclusive_contract")}</Typography>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon="file" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("inherit")}</Typography>
                        </>
                    )}
                    <button
                        className={`cursor-pointer absolute -top-2 -right-2 bg-white h-12 w-12 rounded-full shadow-lg flex items-center justify-center ring ring-gray-500/20 transition ${
                            pieceHover.exclusivity ? "opacity-100 transition duration-300" : "opacity-0 transition duration-300"
                        }`}
                        onClick={() => scrollToSection("2")}
                    >
                        <FontAwesomeIcon icon="pen" className="text-gray-500" onClick={() => scrollToSection("2")} />
                    </button>
                </button>
            </div>

            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-16">
                <div className="relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center">
                    <FontAwesomeIcon icon="ban" className="text-gray-600 h-12 mb-2" />
                    <Typography className="text-center text-base text-gray-500">{t("illegal_uses")}</Typography>
                </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-16">
                <button
                    className={`relative w-2/3 h-2/3 py-4 rounded-lg flex flex-col gap-4 items-center ${pieceHover.commercial && "bg-gray-100"}`}
                    onMouseLeave={() => setPieceHover({ ...pieceHover, commercial: false })}
                    onMouseEnter={() => setPieceHover({ ...pieceHover, commercial: true })}
                    onClick={() => scrollToSection("3")}
                >
                    {!contract.commercialUse ? (
                        <>
                            <FontAwesomeIcon icon="shop" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("commercial_uses_s_a")}</Typography>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon="shop-slash" className="text-gray-600 h-12 mb-2" />
                            <Typography className="text-center text-base text-gray-500">{t("commercial_uses_s")}</Typography>
                        </>
                    )}
                    <button
                        onClick={() => scrollToSection("3")}
                        className={`cursor-pointer absolute -top-2 -right-2 bg-white h-12 w-12 rounded-full shadow-lg flex items-center justify-center ring ring-gray-500/20 transition ${
                            pieceHover.commercial ? "opacity-100 transition duration-300" : "opacity-0 transition duration-300"
                        }`}
                    >
                        <FontAwesomeIcon icon="pen" className="text-gray-500" onClick={() => scrollToSection("3")} />
                    </button>
                </button>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center mt-16">
                <button
                    className={`relative w-2/3 h-2/3 py-4 rounded-lg ${pieceHover.price && "bg-gray-100"}`}
                    onMouseLeave={() => setPieceHover({ ...pieceHover, price: false })}
                    onMouseEnter={() => setPieceHover({ ...pieceHover, price: true })}
                    onClick={() => scrollToSection("4")}
                >
                    <Typography className="text-center text-2xl font-bold h-12 align-middle flex items-center justify-center mb-2" color="gray">
                        {t("chf")} {contract.basePrice}
                    </Typography>
                    <Typography className="text-center text-base text-gray-500">{t("pricettc")}</Typography>
                    <button
                        onClick={() => scrollToSection("4")}
                        className={`cursor-pointer absolute -top-2 -right-2 bg-white h-12 w-12 rounded-full shadow-lg flex items-center justify-center ring ring-gray-500/20 transition ${
                            pieceHover.price ? "opacity-100 transition duration-300" : "opacity-0 transition duration-300"
                        }`}
                    >
                        <FontAwesomeIcon icon="pen" className="text-gray-500" onClick={() => scrollToSection("4")} />
                    </button>
                </button>
            </div>
        </div>
    );
}
