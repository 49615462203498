import { useTranslation } from "react-i18next";
import { Carousel } from "@material-tailwind/react";
import React, { useEffect } from "react";

import { FreeMode, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { Link } from "react-router-dom";
import { SearchPicturesType } from "../../../../components/Interfaces/PictureType";
import PicturesAPI from "../../../../services/API/Clients/PicturesAPI";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Discover() {
    const { t } = useTranslation();
    const [pictures, setPictures] = React.useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    })

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.searchPicturesPagination("pagetype=populars", 1);
            if (response.status === 200)
                setPictures(response.body);
            else
                handleError(response);
        }
        fetchData();
    }, [])

    return (
        <div className="bg-white w-full">
            <div className="bg-white relative z-10 pb-4 max-w-7xl mx-auto flex flex-col md:flex-row px-8">
                <div className="flex flex-col w-full md:w-1/2 md:pr-4">
                    <p className="mt-12 text-left text-4xl">{t("home_section2_title")}</p>
                    <p className="text-left mt-4 mx-auto">{t("home_section2_subtitle_p1")}</p>
                    <p className="text-left mt-4 mb-8">
                        <Link to="/search">
                            <GradientButton text={t("explore")} />    
                        </Link>
                    </p>
                </div>
                <div className="flex justify-center w-full md:w-1/2 md:mt-12 md:pl-4">
                    <Swiper
                        modules={[FreeMode, Navigation, Pagination, Scrollbar, A11y]}
                        freeMode={true}
                        spaceBetween={window.innerWidth > 768 ? 20 : 0}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {pictures.pagination.totalItems >= 6 ? (
                            <>
                                <SwiperSlide className="pb-8">
                                    <div className="h-[350px] overflow-hidden opacity-100 container">
                                        <div className="-m-1 flex flex-wrap md:-m-2">
                                            <div className="flex w-1/2 h-[350px] flex-wrap">
                                                <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[0].minPath}
                                                    />
                                                </div>
                                                <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[1].minPath}
                                                    />
                                                </div>
                                                <div className="w-full h-2/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[2].minPath}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex w-1/2 h-[350px] flex-wrap">
                                                <div className="w-full h-2/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[3].minPath}
                                                    />
                                                </div>
                                                <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[4].minPath}
                                                    />
                                                </div>
                                                <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                    <img
                                                        alt="gallery"
                                                        className="block h-full w-full rounded-lg object-cover object-center"
                                                        src={pictures.data[5].minPath}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {pictures.pagination.totalItems >= 12 && (
                                    <SwiperSlide className="pb-8">
                                        <div className="h-[350px] w-auto overflow-hidden opacity-100 container mx-auto ">
                                            <div className="-m-1 flex flex-wrap md:-m-2">
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[5].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[7].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[8].minPath}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[9].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[10].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[11].minPath}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                                {pictures.pagination.totalItems >= 18 && (
                                    <SwiperSlide className="pb-8">
                                        <div className="h-[350px] w-auto overflow-hidden opacity-100 container mx-auto ">
                                            <div className="-m-1 flex flex-wrap md:-m-2">
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[12].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[13].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[14].minPath}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[15].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[16].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[17].minPath}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                                {pictures.pagination.totalItems >= 24 && (
                                    <SwiperSlide className="pb-8">
                                        <div className="h-[350px] w-auto overflow-hidden opacity-100 container mx-auto ">
                                            <div className="-m-1 flex flex-wrap md:-m-2">
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[18].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[19].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[20].minPath}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[21].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[22].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[23].minPath}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                                {pictures.pagination.totalItems >= 30 && (
                                    <SwiperSlide className="pb-8">
                                        <div className="h-[350px] w-auto overflow-hidden opacity-100 container mx-auto ">
                                            <div className="-m-1 flex flex-wrap md:-m-2">
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[24].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[25].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[26].minPath}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex w-1/2 h-[350px] flex-wrap">
                                                    <div className="w-full h-2/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[27].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[28].minPath}
                                                        />
                                                    </div>
                                                    <div className="w-1/2 h-1/3 p-1 md:p-2">
                                                        <img
                                                            alt="gallery"
                                                            className="block h-full w-full rounded-lg object-cover object-center"
                                                            src={pictures.data[29].minPath}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </>
                        ) : pictures.pagination.totalItems > 0 && pictures.data.map((picture, index) => (
                                <SwiperSlide className="pb-8" key={picture.id}>
                                    <img src={picture.minPath} alt={picture.id} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                {/* <div className="flex md:hidden px-2">
                    <Carousel
                        className="rounded-xl h-48"
                        navigation={({ setActiveIndex, activeIndex, length }) => (
                            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                                {pictures.data.slice(0, 5).map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                            activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                        }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        {pictures.data.slice(0, 5).map((picture, index) => (
                            <img
                                key={index}
                                src={picture.minPath}
                                alt={picture.id}
                                className="h-full w-full object-cover"
                            />
                        ))}
                    </Carousel>
                </div> */}
            </div>
        </div>
    );
}
