import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography } from "@material-tailwind/react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Gallery from "react-photo-gallery"
import GalleryAPI from "../../../services/API/Clients/GalleryAPI"
import PicturesAPI from "../../../services/API/Clients/PicturesAPI"
import { Storage } from "../../../services/storage"
import PictureAlone from "../../Box/Photo/PictureAlone"
import { PictureSearch } from "../../Interfaces/PictureType"
import { Galleries } from "../../Interfaces/PortfolioType"
import SearchResult from "../SearchResult"
import { FilterConfiguration } from "../filters/FilterConfiguration"
import DrawerForFilter from "../mobile/DrawerForFilter"
import { CompatibleFilterList, FilterList, FilterRenderConfiguration, FilterSchema, FilterType, PaginatedResponse, SearchInterface } from "../types/global"
import { GalleryResult } from "./GalleryResult"
import { FilterStoreInterface } from "../../../services/redux/reducers/filtersReducer"
import { useSelector } from "react-redux"
import { handleError } from "../../../services/Errors/handleErrors"

interface EnumValue {
    enumValue: string
    frLabel: string
    enLabel: string
}

interface PictureCompatibleFilters extends CompatibleFilterList {
    category: EnumValue[]
    typestyle: EnumValue[]
    date: {
        takeDate: string
    }[]
    license: {
        license: string
    }[]
    region: {
        code: string
    }[]
}

export type PageType = 'gallery' | 'portfolio' | 'search' | 'favorites' | 'popular' | 'recent' | 'category' | 'typestyle' | 'license' | 'region' | 'date' | 'feed'

interface PictureFilterList extends FilterList {
    pagetype: PageType;
    popular?: boolean;
    query?: string;
    category?: string;
    typestyle?: string;
    portfolio?: string;
    gallery?: string;
    favorites?: boolean;
    from_followed?: boolean;
    country?: string;
    region?: string;
    date?: string;
    author_plan?: string;
    order?: string;
}

const PictureFilterSchema: FilterSchema<PictureFilterList> = {
    pagetype: 'string',
    popular: 'boolean',
    galleries: 'boolean',
    query: 'string',
    category: 'string',
    typestyle: 'string',
    portfolio: 'string',
    gallery: 'string',
    favorites: 'boolean',
    from_followed: 'boolean',
    country: 'string',
    region: 'string',
    date: 'string',
    author_plan: 'string',
    order: 'string',
}

const PictureFiltersRender: FilterRenderConfiguration<PictureCompatibleFilters> = {
    popular: {
        type: FilterType.BOOLEAN,
        mobile: FilterType.BOOLEAN,
        getKey: () => 'popular',
        getValue: (value) => value,
    },
    galleries: {
        type: FilterType.BOOLEAN,
        mobile: FilterType.BOOLEAN,
        getKey: () => 'galleries',
        getValue: (value) => value,
    },
    category: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: EnumValue) => o.enumValue,
        getValue: (o: EnumValue) => o.frLabel,
    },
    typestyle: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: EnumValue) => o.enumValue,
        getValue: (o: EnumValue) => o.frLabel,
    },
    date: {
        type: FilterType.DATEPICKER,
        mobile: FilterType.DROPDOWN,
        getKey: (o: { takeDate: string }) => o.takeDate,
        getValue: (o: { takeDate: string }) => o.takeDate,
    },
    license: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: { license: string }) => o.license,
        getValue: (o: { license: string }) => o.license,
    },
    region: {
        type: FilterType.MAPPICKER,
        mobile: FilterType.DROPDOWN,
        getKey: (o: { code: string }) => o.code,
        getValue: (o: { code: string }) => o.code,
    }
}

const fetchPictures = async (search: SearchInterface<PictureFilterList>) => {
    const allFilters = search.filters.getAllFilters();
    if (allFilters.galleries === true) {
        return undefined;
    }
    const response = await PicturesAPI.searchPicturesPagination(search.filters.getApiQueryParams(), search.page);
    if (response.status === 200) {
        return response.body as PaginatedResponse<PictureSearch>;
    } else {
        handleError(response);
    }
};

const fetchGalleries = async (search: SearchInterface<PictureFilterList>) => {
    const allFilters = search.filters.getAllFilters();
    if (allFilters.galleries === true) {
        return undefined;
    }
    const response = await GalleryAPI.searchGalleriePagination(search.filters.getApiQueryParams(), search.page);
    if (response.status === 200) {
        return response.body as PaginatedResponse<Galleries>;
    } else {
        handleError(response);
    }
};

const fetchCompatibleFilters = async (filters: FilterConfiguration<PictureFilterList>) => {
    const allFilters = filters.getAllFilters();
    if (allFilters.galleries === true) {
        return undefined;
    }
    const response = await PicturesAPI.compatibleFilters(filters.getApiQueryParams());
    if (response.status === 200) {
        return response.body as PictureCompatibleFilters;
    } else {
        handleError(response);
    }
}

interface PictureResultProps {
    galleries?: boolean
    filters?: boolean
    overrides?: PictureFilterList
    NoResults?: JSX.Element
    defaultSort?: string
}

const PictureNoResults = () => {
    const { t } = useTranslation();

    return (<div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
        <FontAwesomeIcon icon="video-slash" className="mt-12 text-4xl text-gray-500/50 mb-4" />
        <Typography className="text-base text-gray-700 mb-12">{t("noPictures")}</Typography>
    </div>)
}

const ParentElement = ({ items, loadMore }: { items: PictureSearch[], loadMore: () => void }) => {
    const [printNsfw, setPrintNsfw] = React.useState(false);

    const handleOpenNsfw = (arg: boolean) => {
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    };

    useEffect(() => {
        if (Storage.getCookie() === "true") setPrintNsfw(true);
        else setPrintNsfw(false);
    }, []);

    return (
        <div className={items.length < 3 ? `mx-auto w-${items.length}/4` : "w-full"}>
            <Gallery
                photos={items.map((item) => ({
                    src: item.minPath,
                    width: item.width,
                    height: item.height,
                    alt: item.id,
                }))}
                renderImage={(props) => (
                    <PictureAlone
                        key={props.photo.src}
                        width={props.photo.width}
                        height={props.photo.height}
                        margin={props.margin}
                        index={props.index}
                        data={items[props.index]}
                        heart={true}
                        printNsfw={printNsfw}
                        handleChangeNsfw={handleOpenNsfw}
                        pictures={items}
                        loadMore={loadMore}
                    />
                )}
            />
        </div>)
}

const PictureResultAsPicture = (props: PictureResultProps) => {
    return (<SearchResult<PictureFilterList, PictureSearch, PictureCompatibleFilters>
        filtersSchema={PictureFilterSchema}
        filters={props.filters ?? true}
        filtersRender={PictureFiltersRender}
        fetchResults={fetchPictures}
        fetchFilters={fetchCompatibleFilters}
        fetchGalleries={props.galleries === false ? undefined : fetchGalleries}
        filterOverrides={props.overrides}

        NoResults={props.NoResults ?? <PictureNoResults />}

        sortOptions={[
            { key: "takedate", value: 'sort.takedate' },
            { key: "publishdate", value: 'sort.publishdate' },
            { key: "viewcount", value: 'sort.viewcount' },
            { key: "favoritecount", value: 'sort.favoritecount' },
        ]}

        defaultSort={props.defaultSort}

        renderParent={(items, loadMore) => <ParentElement items={items} loadMore={loadMore} />}
    //     renderEntity={(item, index, context, loadMore) => (
    //         <PictureAlone
    //             key={item.id}
    //             index={index}
    //             data={item}
    //             heart={true}
    //             // changeSelectedId={handleChangeSelectedId}
    //             // printNsfw={printNsfw}
    //             // handleChangeNsfw={handleOpenNsfw}
    //             // changeSelectedId={() => { }}

    //             printNsfw={false}
    //             handleChangeNsfw={() => { }}
    //             pictures={context}
    //             loadMore={loadMore}
    //         />
    //     )}
    />
    )
}

export const PictureResult = (props: PictureResultProps) => {

    const [galleries, setGalleries] = React.useState(false);

    const filters: FilterStoreInterface = useSelector((state: any) => state.filters);

    useEffect(() => {
        if (filters.currentFilters?.galleries === true) {
            setGalleries(true);
        } else {
            setGalleries(false);
        }
    }, [filters]);

    if (galleries) {
        return (<GalleryResult
            filters={props.filters}
            overrides={props.overrides}
        />)
    }
    return (<PictureResultAsPicture {...props} />)
}


export const PictureDrawerForFilters = () => (
    <DrawerForFilter<PictureFilterList, PictureCompatibleFilters>
        renderConfiguration={PictureFiltersRender}
    />
)