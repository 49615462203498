import Gallery from "./Gallery";
import { SearchGalleriesType } from "../../Interfaces/PortfolioType";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function SwipeGalery({ galeries }: Readonly<{ galeries: SearchGalleriesType }>) {
    if (!galeries.data || galeries.data.length === 0) return <></>;
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                884: {
                    slidesPerView: 2,
                },
                1250: {
                    slidesPerView: galeries.data.length >= 3 ? 3 : 2,
                },
                1732: {
                    slidesPerView: galeries.data.length >= 3 ? 3 : 2,
                },
            }}
            navigation
            pagination={{ clickable: true }}
            className="w-full"
            centeredSlides={galeries.data.length < 2}
        >
            {galeries.data.map((item) => (
                <SwiperSlide key={item.id} className="pb-8">
                    <Gallery key={`gallery-${item.id}`} data={item} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
