import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import {
    Card,
    CardBody,
    CardHeader,
    Option,
    Select,
    Typography,
} from "@material-tailwind/react";
import React, { useCallback, useEffect, useState } from "react";

import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Storage } from "../../../services/storage";
import GradientButton from "../../Form/Button/GradientButton";
import {
    GalleryDetails,
    OverviewPicture,
    UserOverview
} from "../../Interfaces/UserOverview";
import ModalWrapper from "../ModalWrapper";
import SortableItem from "./SortableItem";

import DefaultPicture from "../../../assets/images/elements/default-banner.png";
import { handleError } from "../../../services/Errors/handleErrors";

const SortablePicture = ({
  picture,
  opacity = 1,
}: {
  picture: OverviewPicture;
  opacity?: number;
}) => (
  <div
    key={picture.id}
    style={{
      backgroundColor: "rgba(0,0,0,0.1)",
      backgroundImage: `url('${picture.minPath}')`,
      opacity,
    }}
    className="h-32 w-32 bg-cover bg-center rounded-lg"
  />
);

const PictureDragZone = ({
  id,
  pictures,
  currentId,
}: {
  id: string;
  pictures: OverviewPicture[];
  currentId?: string;
}) => {
  return (
    <SortableContext items={pictures.map((picture) => picture.id)} id={id}>
      <div className="flex flex-row flex-wrap gap-4 overflow-y-scroll flex-1">
        {pictures.map((picture) => (
          <SortableItem id={picture.id} key={picture.id}>
            <SortablePicture
              key={picture.id}
              picture={picture}
              opacity={currentId === picture.id ? 0 : 1}
            />
          </SortableItem>
        ))}
      </div>
    </SortableContext>
  );
};

function GalleriesList(
  props: Readonly<{
    overview: UserOverview;
    setOverview: React.Dispatch<React.SetStateAction<UserOverview | null>>;
    openedGallery: GalleryDetails | null;
    onOpenGallery: React.Dispatch<React.SetStateAction<GalleryDetails | null>>;
  }>
) {
  const { t } = useTranslation();
  const { overview, setOverview, openedGallery, onOpenGallery } = props;
  const { galleries } = overview;
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (!overview) return;
      const { active, over } = event;

      if (active.id !== over?.id) {
        // reorder galleries
        const originIndex = galleries.findIndex(
          (gallery) => gallery.gallery.id === active.id
        );
        const targetIndex = galleries.findIndex(
          (gallery) => gallery.gallery.id === over?.id
        );
        const originGallery = galleries[originIndex];
        galleries.splice(originIndex, 1);

        setOverview({
          portfolio: overview.portfolio,
          galleries: [
            ...galleries.slice(0, targetIndex),
            originGallery,
            ...galleries.slice(targetIndex),
          ],
        });
      }
    },
    [overview, openedGallery]
  );

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={[mouseSensor, touchSensor]}>
      <SortableContext
        items={galleries.map((gallery) => gallery.gallery.id)}
        id={"sort-galleries"}
      >
        <div className="flex-1 flex flex-col bg-gray-50 p-2 rounded-lg overflow-hidden">
          <Typography className="text-xl font-extrabold leading-6 text-gray-900 my-2">
            {t("galeries")}
          </Typography>
          <div className="flex-1 w-full flex flex-col flex-wrap gap-4 overflow-y-scroll overflow-x-hidden">
            {galleries.map((gallery) => (
              <SortableItem id={gallery.gallery.id} key={gallery.gallery.id}>
                <Card
                  key={gallery.gallery.title}
                  shadow={false}
                  className={`relative grid h-32 w-full items-end justify-center overflow-hidden text-center ${
                    openedGallery?.id === gallery.gallery.id
                      ? "border-4 border-orange-500"
                      : ""
                  } rounded-lg`}
                  onClick={() =>
                    openedGallery?.id === gallery.gallery.id
                      ? onOpenGallery(null)
                      : onOpenGallery(gallery.gallery)
                  }
                >
                  <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className={`absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center`}
                    style={{
                      backgroundImage: `url('${
                        gallery.gallery.banner?.minPath ?? DefaultPicture
                      }')`,
                    }}
                  >
                    <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                  </CardHeader>
                  <CardBody className="relative px-1">
                    <Typography color="white" className="text-sm font-medium">
                      {gallery.gallery.title}
                    </Typography>
                  </CardBody>
                </Card>
              </SortableItem>
            ))}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
}

const PICTURE_SORTS = [
  {
    name: "default",
    sorter: (a: OverviewPicture, b: OverviewPicture) =>
      (a.index ?? 0) - (b.index ?? 0),
  },
  {
    name: "date",
    sorter: (a: OverviewPicture, b: OverviewPicture) =>
      new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime(),
  },
];

function SortByDropDown<T>({
  elements: pictures,
  onSort,
  sorts,
}: Readonly<{
  elements: T[];
  onSort: (elements: T[]) => void;
  sorts: { name: string; sorter: (a: T, b: T) => number }[];
}>) {
  const { t } = useTranslation();

  const [sort, setSort] = useState<(typeof sorts)[number] | null>(null);

  const handleSort = (value: string | undefined) => {
    const newSort = sorts.find((sort) => sort.name === value);
    if (newSort) {
      setSort(newSort.name === "default" ? null : newSort);
      onSort([...pictures].sort(newSort.sorter));
    }
  };

  return (
    <div>
      <Select
        onChange={(v) => handleSort(v)}
        value={sort?.name}
        color="orange"
        variant="standard"
        label={t("sortby")}
      >
        {sorts.map((sort) => (
          <Option key={sort.name} value={sort.name}>
            {t(`sort.${sort.name}`)}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default function ModalOrganize({
  open,
  onClose,
  defaultGallery,
}: Readonly<{
  open: boolean;
  onClose: () => void;
  defaultGallery?: GalleryDetails;
}>) {
  const { t } = useTranslation();

  const [overview, setOverview] = React.useState<UserOverview | null>(null);
  const [openedGallery, setOpenedGallery] =
    React.useState<GalleryDetails | null>(null);
  const [activePicture, setActivePicture] =
    React.useState<OverviewPicture | null>(null);
  const [saving, setSaving] = React.useState(false);
  const openedGalleryDetails = openedGallery
    ? overview?.galleries.find((g) => g.gallery.id === openedGallery.id)
    : null;

  const modalRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await UserAPI.overview(Storage.getId() ?? "");
      if (response.status !== 200) {
        handleError(response);
        return;
      }
      setOverview(setIndexToThisOrder(response.body));
    };
    if (open) fetchData();
    if (defaultGallery) setOpenedGallery(defaultGallery);
  }, [open]);

  const getPictureList = (listName: string) => {
    if (overview === null) return [];
    if (listName === "portfolio") return overview.portfolio;
    else if (listName === "gallery")
      return openedGalleryDetails?.pictures ?? [];
    else return [];
  };

  const swapPictures = (
    origin: string,
    originId: string,
    target: string,
    targetOver: string
  ) => {
    const originList = getPictureList(origin);
    const targetList = getPictureList(target);

    const originIndex = originList.findIndex(
      (picture) => picture.id === originId
    );
    const targetIndex = targetList.findIndex(
      (picture) => picture.id === targetOver
    );

    const originPicture = originList[originIndex];

    originList.splice(originIndex, 1);
    targetList.splice(targetIndex, 0, originPicture);
  };

  const handleDragEnd = useCallback(() => {
    if (!overview) return;
    setIndexesToCurrentOrder();
    setActivePicture(null);
  }, [overview, openedGallery]);

  const handleDragStart = useCallback(
    (picture: DragStartEvent) => {
      const pictureId = picture.active.id;
      const listName = picture.active.data.current?.sortable.containerId;

      const pictureList = getPictureList(listName);

      const pictureDetails = pictureList.find((pict) => pict.id === pictureId);

      if (pictureDetails) setActivePicture(pictureDetails);
    },
    [overview, openedGallery]
  );

  const handleDragCancel = useCallback(() => {
    setActivePicture(null);
  }, [overview, openedGallery]);

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      if (!overview) return;
      const { active, over } = event;

      if (active.id !== over?.id) {
        const originSortable = active.data.current?.sortable?.containerId;
        const targetSortable = over?.data.current?.sortable?.containerId;

        if (originSortable && targetSortable) {
          swapPictures(
            originSortable,
            active.id as string,
            targetSortable,
            over.id as string
          );

          setOverview({
            portfolio: overview.portfolio,
            galleries: overview.galleries,
          });
        }
      }
    },
    [overview, openedGallery]
  );

  const handleSave = useCallback(() => {
    const save = async () => {
      if (!overview) return;

      const organizeRequest = {
        portfolio: overview.portfolio.map(
          (picture) => `/api/public/pictures/${picture.id}`
        ),
        galleries: overview.galleries.map((gallery) => ({
          gallery: `/api/public/galleries/${gallery.gallery.id}`,
          pictures: gallery.pictures.map(
            (picture) => `/api/public/pictures/${picture.id}`
          ),
        })),
      };

      const res = await PortfolioAPI.organize(organizeRequest);

      setSaving(false);

      if (res.status !== 200 && res.status !== 201) {
        handleError(res);
        return;
      }

      onClose();
    };

    save();
    setSaving(true);
  }, [overview]);

  const setIndexesToCurrentOrder = () => {
    if (overview) {
      setIndexToThisOrder(overview);
    }
  };

  const setIndexToThisOrder = (order: UserOverview) => {
    order.portfolio.forEach((picture, index) => {
      picture.index = index;
    });
    order.galleries.forEach((gallery) => {
      gallery.pictures.forEach((picture, index) => {
        picture.index = index;
      });
    });

    return order;
  };

  return (
    <ModalWrapper open={open} setOpen={onClose}>
      <Dialog.Panel
        className="relative transform rounded-2xl bg-white p-6 py-4 text-left align-middle shadow-xl transition-all h-[100%] overflow-auto w-full md:w-3/4 overflow-x-hidden"
        ref={modalRef}
      >
        <Dialog.Title className="min-h-[10%]">
          <Typography className="text-xl font-extrabold leading-6 text-gray-900 my-2">
            {t("organizepictures")}
          </Typography>
          <Typography className="text-base font-bold leading-6 text-gray-900 mb-2">
            {t("organizeexplain")}
          </Typography>
        </Dialog.Title>
        <button
          className="absolute top-4 right-4 w-10 h-10 text-orange-500 hover:text-gray-500 flex items-center justify-center rounded-full border border-orange-500 hover:border-gray-500 transition-all"
          onClick={onClose}
        >
          <FontAwesomeIcon icon="x" />
        </button>

        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
        >
          <div className="flex flex-row w-full gap-4 h-[75%] mt-4">
            <div className="flex flex-col gap-4 flex-1">
              {overview?.galleries && (
                <GalleriesList
                  overview={overview}
                  setOverview={setOverview}
                  openedGallery={openedGallery}
                  onOpenGallery={setOpenedGallery}
                />
              )}
              <div className="flex flex-col bg-gray-50 p-2 rounded-lg flex-1 relative overflow-hidden">
                <div className="flex justify-between items-center mb-2">
                  <Typography className="text-xl font-extrabold leading-6 text-gray-900 my-2">
                    {t("portfolio")}
                  </Typography>
                  <SortByDropDown
                    elements={overview?.portfolio ?? []}
                    onSort={(pictures) => {
                      if (overview) {
                        setOverview({
                          ...overview,
                          portfolio: pictures,
                        });
                      }
                    }}
                    sorts={PICTURE_SORTS}
                  />
                </div>
                <PictureDragZone
                  id="portfolio"
                  pictures={overview?.portfolio ?? []}
                  currentId={activePicture?.id}
                />
              </div>
            </div>

            {openedGalleryDetails && (
              <div className="flex flex-col flex-1">
                <div className="flex justify-between items-center mb-2">
                  <Typography className="text-xl font-extrabold leading-6 text-gray-900 my-2">
                    {openedGallery?.title}
                  </Typography>
                  <SortByDropDown
                    sorts={PICTURE_SORTS}
                    elements={openedGalleryDetails.pictures}
                    onSort={(pictures) => {
                      if (overview && openedGallery) {
                        const galleryIndex = overview.galleries.findIndex(
                          (gallery) => gallery.gallery.id === openedGallery?.id
                        );
                        const newGalleries = [...overview.galleries];
                        newGalleries[galleryIndex] = {
                          gallery: openedGallery,
                          pictures,
                        };
                        setOverview({
                          ...overview,
                          galleries: newGalleries,
                        });
                      }
                    }}
                  />
                </div>
                <PictureDragZone
                  id="gallery"
                  pictures={openedGalleryDetails.pictures}
                  currentId={activePicture?.id}
                />
              </div>
            )}
          </div>
          <DragOverlay
            modifiers={[
              ({ transform }) => {
                const leftBorder =
                  modalRef.current?.getBoundingClientRect().left ?? 0;
                return { ...transform, x: transform.x - leftBorder };
              },
            ]}
          >
            {activePicture ? <SortablePicture picture={activePicture} /> : null}
          </DragOverlay>
        </DndContext>
        <div
          style={saving ? { pointerEvents: "none", opacity: 0.5 } : {}}
          className="flex flex-row justify-center gap-4 mt-4 fixed bottom-0 left-0 right-0 py-4 bg-white"
        >
          <button onClick={handleSave}>
            <GradientButton text="save" />
          </button>
          <button
            type="button"
            className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
            onClick={onClose}
          >
            {t("cancel")}
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
