import WavesTop from "../../../assets/images/home/waves/top.svg";
import FooterTop from "../../../assets/images/home/waves/footer_top.svg";

import Discover from "./components/Discover";
import Footer from "../../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import Mandate from "./components/Mandate";
import JoinFree from "./components/JoinFree";
import Header from "./components/Header";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import { PictureType } from "../../../components/Interfaces/PictureType";
import { handleError } from "../../../services/Errors/handleErrors";

export default function Home() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("home")}`;
    const [loading, setLoading] = useState(true)
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body.picture);
                setLoading(false);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [])

    return (
        <div className="relative overflow-hidden pb-[360px] md:pb-36">
            <Loader loading={loading} />
            <Header backgroundPicture={backgroundPicture} />
            <WavesTop className="w-full" />
            <div className="">
                <Discover />
                <Mandate />
                <JoinFree />
                <div className="mb-0 bg-white">
                    <FooterTop />
                </div>
            </div>
            <Footer />
        </div>
    )
}