import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import GalerySmall from "./GalerySmall";

export default function SwipeGaleryUnique({ galeries } : Readonly<{ galeries: {
    id: string,
    title: string,
    banner: {
        minPath: string
    },
    bannerOffset: number,
}[] }>) {
    return (
        <div className="w-full h-fit">
            <Swiper 
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {galeries.map((item) => (
                    <SwiperSlide key={item.id}>
                        <div className="w-[100%] pb-8">
                            <GalerySmall key={`gallery-${item.id}`} data={item} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}