import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Declarations() {
    const { t } = useTranslation();
    const [openDescription, setOpenDescription] = React.useState(false);
    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openDescription)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">9. {t("warranty")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openDescription ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openDescription} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="small" className="text-justify">
                    {t("warranty_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("availability_warranty")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("availability_warranty_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("no_counterfeiting")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("no_counterfeiting_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("editorial_uses")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("editorial_uses_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("metadata_uses")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("metadata_uses_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("other_warranties")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("other_warranties_d")}
                </Typography>
            </Collapse>
        </div>
    );
}
