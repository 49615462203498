import { useTranslation } from "react-i18next";

export default function WhitButton({ name, onClick }: { name: string; onClick?: () => void }) {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
            onClick={onClick}
        >
            {t(name)}
        </button>
    );
}
