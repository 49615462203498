import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { SocialNetwork } from "../../../../components/Interfaces/PortfolioType";

export default function SocialNetworkView() {
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const { t } = useTranslation();


    const handleAddSocial = () => {
        const newBoitiers = [...portfolio.socialNetworks];
        newBoitiers.push({ brandLabel: "", modelLabel: "", type: "body" });

        dispatch({ type: "UPDATE_SOCIALNETWORK", payload: newBoitiers });
    };

    const handleSocialChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newSocialNetworks = [...portfolio.socialNetworks];
        newSocialNetworks[index].url = e.target.value;
        dispatch({ type: "UPDATE_SOCIALNETWORK", payload: newSocialNetworks });
    };

    const handleSocialRemove = (index: number) => {
        const newSocialNetworks = [...portfolio.socialNetworks];
        newSocialNetworks.splice(index, 1);
        dispatch({ type: "UPDATE_SOCIALNETWORK", payload: newSocialNetworks });
    };

    const URLTitle = (url: string) => {
        let domainExtensions = [
            "gov", "org", "co", "com", "in", "info", "net", "uk", "af", "am", "ar", "au", "as", "az", "be", "bg", "bn", "bo", "bs", "ca", "ch", "cs", "cy", "da", "de", "dv", "el", "en", "es", "et", "eu", "fa", "fi", "fo", "fr", "gd", "gl", "gn", "gu", "he", "hi", "hr", "hu", "hy", "id", "is", "it", "jp", "ka", "kk", "km", "kn", "ko", "ks", "la", "lo", "lt", "lv", "mi", "mk", "ml", "mn", "mr", "ms", "mt", "my", "nb", "ne", "nl", "or", "pa", "pl", "pt", "rm", "ro", "ru", "sa", "sb", "sd", "si", "sk", "sl", "so", "sq", "sr", "sv", "sw", "ta", "te", "tg", "th", "tk", "tn", "tr", "ts", "tt", "tv", "uk", "ur", "uz", "vi", "xh", "yi", "zh", "zu", "xyz", "io"
        ];
        let regex = new RegExp("\\.(?:" + domainExtensions.join('|') + ")$");
        return new URL(url).hostname.replace(regex, '').replace(/^[^.]+\./g, "").replace(/^\w/, c => c.toUpperCase());
    }

    const searchFavicon = async (url: string, index: number) => {
        if (!url) return;
        const oldSocialNetworks = [...portfolio.socialNetworks];
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "https://" + url;
            oldSocialNetworks[index].url = url;
        } else 
            oldSocialNetworks[index].url = url;
        if (!url.match(/(\.[a-z]{2,3})$/)) {
            oldSocialNetworks[index].icon = null;
            oldSocialNetworks[index].label = "URL invalide";
        } else {
            oldSocialNetworks[index].label = URLTitle(url);
            oldSocialNetworks[index].icon = `https://www.google.com/s2/favicons?domain=${url}&sz=${30}`;
        }
        dispatch({ type: "UPDATE_SOCIALNETWORK", payload: oldSocialNetworks });
    };

    return (
        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
            <Typography className={`text-lg font-bold`}>{t("findme")}</Typography>
            {portfolio.socialNetworks.map((social: SocialNetwork, index: number) => (
                <>
                    <Typography className="ml-6 text-base mt-2 flex flex-row gap-2 items-center">
                        <div className={`w-4 h-4 ${social.icon ? "bg-transparent" : social.label === "URL invalide" ? "bg-transparent" : "bg-gray-500"} rounded flex items-center justify-center`}>
                            {social.icon ? social.label !== "URL invalide" ? <img src={social.icon} alt={social.label} className="w-4 h-4 rounded" /> : <FontAwesomeIcon icon="triangle-exclamation" className="w-3.5 h-3.5 text-orange-500" /> : social.label !== "URL invalide" ? "" : <FontAwesomeIcon icon="triangle-exclamation" className="w-3.5 h-3.5 text-orange-500" /> }
                        </div>
                        {social.label ? social.label : t("newlink")}
                        <FontAwesomeIcon
                            icon="circle-xmark"
                            className="ml-1 text-purple-500 text-xs cursor-pointer"
                            onClick={() => handleSocialRemove(index)}
                        />
                    </Typography>
                    <div className="flex flex-row items-center mx-6 mt-2 mb-2">
                        <Input
                            variant="standard"
                            color="purple"
                            placeholder={t("link")}
                            value={social.url}
                            className="bg-white"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSocialChange(e, index)}
                            onBlur={() => searchFavicon(social.url, index)}
                        />
                    </div>
                </>
            ))}
            <button onClick={() => handleAddSocial()} className="flex">
                <Typography className="ml-6 text-base mt-2 cursor-pointer text-gray-500">
                    <FontAwesomeIcon icon="circle-plus" className="mr-2" /> {t("addnetwork")}
                </Typography>
            </button>
        </div>
    );
}
