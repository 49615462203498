import { useTranslation } from "react-i18next";
import Performance from "../../../components/Box/Photograph/Performance";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../services/redux/store";
import { Services } from "../../../components/Interfaces/PortfolioType";

export default function Performances() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const portfolio = useSelector((state: RootState) => state.form);
    const { id } = useParams();

    useEffect(() => {
        if (portfolio.services === undefined || portfolio.services.data.length === 0) {
            navigate(`/portfolio/${id}/photos`);
        }
    }, []);

    return (
        <div className="flex flex-wrap mt-4 mx-auto justify-around max-w-6xl">
            {portfolio.services && portfolio.services.data.length > 0 && portfolio.services.data.map((service: Services) => {
                const currentPage = window.location.pathname.split("/")[3];
                let actualLink
                if (currentPage === "performances")
                    actualLink = `/portfolio/${id}/performance/${service.id}`;
                else
                    actualLink = `/${window.location.pathname.split("/")[1]}/performance/${service.id}`
                return (
                    <Link key={service.id} to={actualLink} className="w-full md:w-1/3 p-2 cursor-pointer">
                        <Performance data={service}  />
                    </Link>
                )
            })}
            {(portfolio.services === undefined || portfolio.services.data.length === 0) && (
                <div className={`flex bg-orange-50/20 text-orange-800 px-4 py-3 font-sans mb-4 bg-white`} role="alert">
                    <p className="flex flex-col">
                        <span dangerouslySetInnerHTML={{ __html: t("noprestanow") }} />
                    </p>
                </div>
            )}
        </div>
    );
}
