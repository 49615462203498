import { GalleryInterfacePerformance } from "../../../components/Interfaces/GalleryInterface";
import { Banner, PerformanceType, Portfolio } from "../../../components/Interfaces/PerformanceType";
import { PictureSearch } from "../../../components/Interfaces/PictureType";

export const updateTitlePE = (name: string): any => ({
    type: "UPDATE_PE_TITLE",
    payload: name,
});

export const updateDescriptionPE = (description: string): any => ({
    type: "UPDATE_PE_DESCRIPTION",
    payload: description,
});

export const updatePriceTypePE = (priceType: string): any => ({
    type: "UPDATE_PE_PRICETYPE",
    payload: priceType,
});

export const updatePriceValuePE = (priceValue: number): any => ({
    type: "UPDATE_PE_PRICEVALUE",
    payload: priceValue,
});

export const updatePriceCeilPE = (priceCeil: number): any => ({
    type: "UPDATE_PE_PRICECEIL",
    payload: priceCeil,
});

export const updateTypePE = (type: string): any => ({
    type: "UPDATE_PE_TYPE",
    payload: type,
});

export const updatePicturesPE = (pictures: PictureSearch[]): any => ({
    type: "UPDATE_PE_PICTURES",
    payload: pictures,
});

export const updateGalleriesPE = (galleries: GalleryInterfacePerformance[]): any => ({
    type: "UPDATE_PE_GALLERIES",
    payload: galleries,
});

export const updateBannerPE = (banner: Banner): any => ({
    type: "UPDATE_PE_BANNER",
    payload: banner,
});

export const updateKeywordsPE = (keywords: {
    value: string,
    label: string,
    type: string,
}[]): any => ({
    type: "UPDATE_PE_KEYWORDS",
    payload: keywords,
});

export const updateBannerOffsetPE = (bannerOffset: number): any => ({
    type: "UPDATE_PE_BANNER_OFFSET",
    payload: bannerOffset,
});

export const updatePortfolioPE = (portfolio: Portfolio): any => ({
    type: "UPDATE_PE_PORTFOLIO",
    payload: portfolio,
});

export const updatePublishedPE = (published: boolean): any => ({
    type: "UPDATE_PE_PUBLISHED",
    payload: published,
});

export const updateCreatedAtPE = (createdAt: string): any => ({
    type: "UPDATE_PE_CREATEDAT",
    payload: createdAt,
});

export const updatePerformancesPE = (performance: PerformanceType): any => ({
    type: "UPDATE_PE_PERFORMANCES",
    payload: performance,
});

export const updateResetPerformancePE = (): any => ({
    type: "RESET_PERFORMANCE",
});