import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

import Footer from "../../components/Footer/Footer";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { PortfolioDrawerForFilters, PortfolioResult } from "../../components/SearchResult/impl/PortfolioResult";
import MobileFilterButton from "../../components/SearchResult/mobile/MobileFilterButton";
import TitlePageNoMaxSize from "../../components/TitlePageNoMaxSize";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { getPageName, getPageOverride } from "../../services/Search/PhotographerSearchUtils";
import { RootState } from "../../services/redux/store";

export default function Photographers() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("photographs")}`;
    const [searchBar, setSearchBar] = React.useState(true);
    const [hidden, setHidden] = React.useState(false);

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })


    const general = useSelector((state: RootState) => state.general);

    const { type, value } = useParams();

    const pageName = (type && value && getPageName(general, type, value)) ?? t('searching');

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <PortfolioDrawerForFilters />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-0">
                    <motion.div
                        variants={{
                            visible: { top: "48px", paddingTop: "8px" },
                            hidden: { top: "0px", paddingTop: "-2px" },
                            barsInactive: { top: "48px", paddingTop: "12px" },
                            barsActive: { top: "102px", paddingTop: "8px" }
                        }}
                        animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible" }
                        transition={{ 
                            duration: 0.35,
                            ease: "easeInOut"
                        }}
                        className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pt-6 pb-2 md:static bg-white z-30 border-b border-gray-200"
                    >
                        <TitlePageNoMaxSize mt={0}><FontAwesomeIcon icon="users" className="text-purple-500 mr-2" />
                            {pageName}
                        </TitlePageNoMaxSize>
                        <div className="flex md:hidden flex-row items-center justify-end gap-2 w-1/2 md:mt-4 pr-2">
                            <MobileFilterButton />
                        </div>
                    </motion.div>
                    <WrapperGrayZone pt="0" px="0">
                        <PortfolioResult
                            overrides={getPageOverride(type, value)}
                        />
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
