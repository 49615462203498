import Footer from "../../../components/Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SearchPortfolioType } from "../../../components/Interfaces/PortfolioType";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import { PictureResult } from "../../../components/SearchResult/impl/PictureResult";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import RightSide from "./components/RightSide";
import RightSideUnder from "./components/RightSideUnder";
import { handleError } from "../../../services/Errors/handleErrors";


export default function Home() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("home")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const general = useSelector((state: any) => state.general);
    const [completePortfolio, setCompletePortfolio] = React.useState({
        completionPercentage: 0,
        hasProfilePicture: false,
        hasBanner: false,
        hasPublishedPhotos: false,
        hasAbout: false,
        hasPublishedPortfolio: false
    });
    const [photographers, setPhotographers] = React.useState<SearchPortfolioType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
        data: [],
    });

    const fetchPortfolio = async () => {
        if (general && general.me && general.me.region && general.me.region.id && general.me.country && general.me.country.code) {
            const responsePhotographers = await PortfolioAPI.searchPortfolioPA(general.me.region.id, general.me.country.code);
            if (responsePhotographers.status === 200 || responsePhotographers.status === 201) {
                setPhotographers(responsePhotographers.body);
            } else {
                handleError(responsePhotographers);
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (general?.me?.portfolio?.id) {
                const responseComplete = await PortfolioAPI.complete(general.me.portfolio.id);
                if (responseComplete.status === 200 || responseComplete.status === 201) {
                    setCompletePortfolio(responseComplete.body);
                } else {
                    handleError(responseComplete);
                }
            }
            fetchPortfolio();
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="min-h-screen relative">
                {/* <Loader loading={true} /> */}
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full pb-[390px] md:pb-40 max-w-7xl mx-auto bg-white">
                    <div
                        className={`w-2/3 md:w-full mt-4 md:mt-6 mb-4 md:mb-6  
                            bg-white
                            max-w-7xl md:mx-auto
                            px-3.5 md:px-8
                        `}
                    >
                        {/** md:px-8 px-2 */}
                        <h1 className="text-2xl md:text-4xl w-full max-w-7xl mx-auto mt-2 md:mt-0">{t("feed")}</h1>
                    </div>
                    <div className="flex flex-col-reverse md:flex-row px-2 md:px-8 w-full bg-white">
                        <div className="flex flex-col w-full md:w-[70%] h-fit bg-gray-50 md:p-5 rounded-lg pb-12">

                            <PictureResult
                                filters={false}
                                overrides={{
                                    pagetype: "feed",
                                    from_followed: true,
                                }}
                                NoResults={
                                    <div className="flex flex-col justify-center items-center py-4">
                                        <FontAwesomeIcon icon="user-plus" className="text-gray-500/50 text-3xl" />
                                        <p className="text-base text-gray-500 font-sans mt-4 text-center">{t("sub_for_feed")}</p>
                                    </div>
                                }
                            />


                        </div>
                        <div className="flex md:hidden flex-col w-full md:w-[30%] h-fit gap-4 md:px-5 rounded-lg relative mb-4">
                            <RightSide complete={completePortfolio} photographers={photographers} />
                        </div>
                        <div className="hidden md:flex flex-col w-full md:w-[30%] h-fit gap-4 md:px-5 rounded-lg relative mb-12">
                            <RightSideUnder complete={completePortfolio} photographers={photographers} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
