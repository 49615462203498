import { useTranslation } from "react-i18next";
import TitlePage from "../../components/TitlePage";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import React from "react";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import Footer from "../../components/Footer/Footer";
import { useMotionValueEvent, useScroll, motion } from "framer-motion";

export default function Privacy() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("privacy")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="flex flex-col h-full bg-white -mt-4 pb-[340px] md:pb-36">
                <motion.div
                        variants={{
                            visible: { top: "46px", paddingTop: "12px" },
                            hidden: { top: "0px", paddingTop: "4px" },
                            barsInactive: { top: "46px", paddingTop: "12px" },
                            barsActive: { top: "93px", paddingTop: "8px" }
                        }}
                        animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible" }
                        transition={{ 
                            duration: 0.35,
                            ease: "easeInOut"
                        }}
                        className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200">
                    <TitlePage mt={0} mb={0}>
                        {t("privacy_title")}
                    </TitlePage>
                </motion.div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6">
                        <div className="-m-1 flex flex-col flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto"></div>

                            <div className="max-w-[1280px] w-full mx-auto">
                                <span dangerouslySetInnerHTML={{ __html: t("privacy_subtitle_1")}} />

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_1")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_1")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_1")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_2")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_1")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_2")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_3")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_5")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_2_6")}}></li>
                                </ul>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_2")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_3")}</h1>
                                <ul className="list-decimal pl-8">
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_1")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_2")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_3")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_4")}}></li>
                                    <li className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_ul_3_5")}}></li>
                                </ul>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_4")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_4")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_4")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_5")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_5")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_5")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_6")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_6")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_6")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_7")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_7")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_7")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_8")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_8")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_8")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_9")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_9")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_9")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_10")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_10")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_10")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_11")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_11")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_11")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_12")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_12")}}></p>
                                <p className="mt-4 font-bold italic">{t("privacy_resume_12")}</p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_13")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_13")}}></p>

                                <h1 className="mt-10 text-xl font-bold">{t("privacy_title_14")}</h1>
                                <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("privacy_text_14")}}></p>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
