import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Dispositions() {
    const { t } = useTranslation();
    const [openDescription, setOpenDescription] = React.useState(false);
    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openDescription)} 
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">11. {t("general_provisions")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openDescription ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openDescription} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("assignment")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("assignment_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("digital_storage")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("digital_storage_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("applicable_law")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("applicable_law_p1")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("applicable_law_p2", { for: "FOR ????"})}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("applicable_law_p3")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("autonomy_provisions")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("autonomy_provisions_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("responibility")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("responibility_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("entire_contract")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("entire_contract_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("taxes")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("taxes_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("charges")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("charges_d")}
                </Typography>
            </Collapse>
        </div>
    )
}