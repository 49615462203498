import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchPicturesType } from "../../../components/Interfaces/PictureType";
import { SearchGalleriesType } from "../../../components/Interfaces/PortfolioType";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import GalleryAPI from "../../../services/API/Clients/GalleryAPI";
import { useTranslation } from "react-i18next";
import Charts from "./Chart";
import PaginationCallNextOne from "../../../components/Tables/PaginationCallNextOne";
import { RootState } from "../../../services/redux/store";
import { handleError } from "../../../services/Errors/handleErrors";

export default function Pictures() {
    const { t } = useTranslation();
    const general = useSelector((state: RootState) => state.general);
    const [whichGalery, setWhichGalery] = useState("");
    const [whichPicture, setWhichPicture] = useState("");
    const [galleries, setGalleries] = useState<SearchGalleriesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0
        },
        data: []
    });
    const [pictures, setPictures] = useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0
        },
        data: []
    });
    const [picturesWithFiltering, setPicturesWithFiltering] = useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0
        },
        data: []
    });
    const [datas, setDatas] = useState<{ label: string, beginQuery: string}[]>([
        { label: "print", beginQuery: "?entity=picture-print" },
        { label: "view", beginQuery: "?entity=picture-view" },
        { label: "like", beginQuery: "?entity=picture-like" }
    ]);

    useEffect(() => {
        if (whichGalery === "" && whichPicture === "") {
            setDatas([
                { label: "picturesPrint", beginQuery: "?entity=picture-print" },
                { label: "picturesView", beginQuery: "?entity=picture-view" },
                { label: "picturesLike", beginQuery: "?entity=picture-like" }
            ])
        } else if (whichGalery !== "" && whichPicture === "") {
            setDatas([
                { label: "galleryPrint", beginQuery: `?entity=gallery-print&gallery=${whichGalery}` },
                { label: "galleryView", beginQuery: `?entity=gallery-view&gallery=${whichGalery}` },
                { label: "galleryLike", beginQuery: `?entity=gallery-like&gallery=${whichGalery}` }
            ])
        } else if (whichGalery === "" && whichPicture !== "") {
            setDatas([
                { label: "picturePrint", beginQuery: `?entity=picture-print&picture=${whichPicture}` },
                { label: "pictureView", beginQuery: `?entity=picture-view&picture=${whichPicture}` },
                { label: "pictureLike", beginQuery: `?entity=picture-like&picture=${whichPicture}` }
            ])
        } else if (whichGalery !== "" && whichPicture !== "") {
            setDatas([
                { label: "galleryPrint", beginQuery: `?entity=gallery-print&gallery=${whichGalery}` },
                { label: "galleryView", beginQuery: `?entity=gallery-view&gallery=${whichGalery}` },
                { label: "galleryLike", beginQuery: `?entity=gallery-like&gallery=${whichGalery}` },
                { label: "picturePrint", beginQuery: `?entity=picture-print&picture=${whichPicture}` },
                { label: "pictureView", beginQuery: `?entity=picture-view&picture=${whichPicture}` },
                { label: "pictureLike", beginQuery: `?entity=picture-like&picture=${whichPicture}` }
            ])
        } else
            return;
    }, [whichGalery, whichPicture]);

    useEffect(() => {
        const fetchData = async () => {
            if (!general?.me?.portfolio?.id) return;
            const respPictures = await PicturesAPI.searchPicturesPagination(`pagetype=portfolio&portfolio=${general?.me?.portfolio?.id}`, 1);
            if (respPictures.status !== 200 && respPictures.status !== 201)
                handleError(respPictures);
            else {
                setPictures(respPictures.body);
                setPicturesWithFiltering(respPictures.body);
            }
            const respGalleries = await GalleryAPI.searchGalleriePagination(`portfolio=${general?.me?.portfolio?.id}`, 1);
            if (respGalleries.status !== 200 && respGalleries.status !== 201) 
                handleError(respGalleries);
            else
                setGalleries(respGalleries.body);
        };
        fetchData();
    }, []);

    const handleChangeTab = async (id: string) => {
        if (!general?.me?.portfolio?.id) return;
        if (whichGalery === id) {
            setWhichGalery("")
            setWhichPicture("")
            setPicturesWithFiltering(pictures);
        } else {
            setWhichGalery(id)
            setWhichPicture("")
            const respPictures = await PicturesAPI.searchPicturesPagination(`pagetype=gallery&gallery=${id}`, 1);
            if (respPictures.status !== 200 && respPictures.status !== 201)
                handleError(respPictures);
            else
                setPicturesWithFiltering(respPictures.body);
        }
    }

    const callNextPictures = async (page: number) => {
        if (!general?.me?.portfolio?.id) return;
        if (whichGalery === "") {
            const respPictures = await PicturesAPI.searchPicturesPagination(`pagetype=portfolio&portfolio=${general?.me?.portfolio?.id}`, page);
            if (respPictures.status !== 200 && respPictures.status !== 201)
                handleError(respPictures);
            else {
                setPictures(respPictures.body);
                setPicturesWithFiltering(respPictures.body);
            }
        } else {
            const respPictures = await PicturesAPI.searchPicturesPagination(`pagetype=gallery&gallery=${whichGalery}`, page);
            if (respPictures.status !== 200 && respPictures.status !== 201)
                handleError(respPictures);
            else
                setPicturesWithFiltering(respPictures.body);
        }
    }

    const handleWhichPicture = (id: string) => {
        if (whichPicture === id)
            setWhichPicture("")
        else
            setWhichPicture(id);
    }

    return (
        <div className="bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pt-4 mx-0 md:px-8 flex flex-col pb-2 md:pb-8">
            <div className="flex h-[400px]">
                <div className="hidden md:flex flex-col w-1/6 grow border-r my-4 pr-2">
                    <p className="mb-3 text-base font-sans w-full">{t("all_pics")}</p>
                    <div className="flex flex-col gap-2">
                        {galleries.data !== undefined && galleries.data.length > 0 && galleries.data.map((item) => (
                            <button
                                key={item.id}
                                className={`${
                                    whichGalery === item.id && "text-white bg-orange-500 rounded"
                                } hover:bg-orange-200 hover:text-white rounded transition-all px-2 py-2 text-xs font-sans cursor-pointer text-left`}
                                onClick={() => handleChangeTab(item.id)}
                            >
                                {item.title}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="w-full md:w-5/6 flex flex-col">
                    <div className="w-full flex gap-2 p-2 flex-wrap h-[325px] overflow-y-auto">
                        {picturesWithFiltering.data !== undefined && picturesWithFiltering.data.length > 0 && picturesWithFiltering.data.map((item) => (
                            <button key={item.id} onClick={() => handleWhichPicture(item.id)} className={`w-[150px] h-[150px] border-2 rounded-md ${whichPicture === item.id ? "border-orange-500" : "border-transparent"}`}>
                                <img src={item.minPath} alt={item.id} className="w-full h-full object-cover rounded" />
                            </button>
                        ))}
                    </div>
                    <PaginationCallNextOne pagination={picturesWithFiltering.pagination} callNextPicture={callNextPictures} />
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-row flex-wrap justify-center">
                    {datas.map((item) => (
                        <Charts color={"orange"} query={item.beginQuery} title={item.label} key={item.label} />
                    ))}
                </div>
            </div>
        </div>
    );
}
