import { Checkbox } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface Item {
    key: string
    value: string
}

interface BooleanProps {
    title: string
    selected: boolean
    setSelected: () => void
    removeSelected: () => void
}

export default function BooleanFilterMobile({ title, selected, setSelected, removeSelected }: BooleanProps) {

    const { t } = useTranslation();
    return (
        <Checkbox
            color="orange"
            label={t(title)}
            checked={selected}
            onChange={(e) => {
                if (e.target.checked) {
                    setSelected();
                } else {
                    removeSelected();
                }
            }} />
    );
}
