import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DefaultProfilePicture from "../../assets/images/elements/default-profile.png";
import { RootState } from "../../services/redux/store";

export default function CardMe() {
    const { t } = useTranslation();
    const general = useSelector((state: RootState) => state.general);

    const returnColor = () => {
        if (general.me === undefined) return;
        if (general.me.plan === "PRO") return "bg-black text-white";
        else if (general.me.plan === "PHOTOGRAPHER") return " bg-white text-black border border-black";
        else if (general.me.plan === "PREMIUM") return " bg-white text-black border border-black";
        else return " bg-white text-black border border-black";
    };

    const returnValue = () => {
        if (general.me === undefined) return;
        if (general.me.plan === "PRO") return t("pro");
        else if (general.me.plan === "PHOTOGRAPHER") return t("photographer");
        else if (general.me.plan === "PREMIUM") return t("premium");
        else return t("user");
    };

    const returnIcon = () => {
        if (general.me === undefined) return;
        if (general.me.plan === "PRO") return <FontAwesomeIcon icon="crown" className="h-3 ml-0.5" />;
        else if (general.me.plan === "PREMIUM") return <FontAwesomeIcon icon="star" className="h-3 ml-0.5" />;
        else return;
    };

    return (
        <>
            <div className="flex md:hidden flex-col gap-1 px-4 w-auto rounded-xl py-4">
                <div className="flew-row items-center gap-4 w-auto">
                    <Avatar size="sm" src={general?.me?.picture ?? DefaultProfilePicture} />
                    <div className="flex flex-col">
                        <p className="text-xs font-bold flex flex-row gap-2">
                            <span>
                                {general?.me?.lastname ?? "NOM"} {general?.me?.firstname ?? "PRENOM"}
                            </span>
                            {general?.me?.verified && <FontAwesomeIcon icon="check-circle" className="text-gray-500" />}
                        </p>
                        <Chip className={`w-fit h-fit ${returnColor()}`} size="sm" variant="ghost" value={returnValue()} icon={returnIcon()} />
                    </div>
                </div>
                {general?.me?.email && (<div className="text-sm">{general?.me?.email}</div>)}
                {general?.me?.phone && (<div className="text-sm">{general?.me?.phone}</div>)}
                {general?.me?.country && (<div className="text-sm">{general?.me?.country?.frLabel}</div>)}
            </div>
            <div className="hidden md:flex flex-col gap-1 px-6 w-fit bg-gray-200 rounded-xl py-4">
                <div className="flex flew-row items-center gap-4 w-fit">
                    <Avatar src={general?.me?.picture ?? DefaultProfilePicture} />
                    <div className="flex flex-col">
                        <p className="text-lg font-bold flex flex-row gap-2 items-center">
                            <span>
                                {general?.me?.lastname ?? "NOM"} {general?.me?.firstname ?? "PRENOM"}
                            </span>
                            {general?.me?.verified && <FontAwesomeIcon icon="check-circle" className="text-gray-500" />}
                        </p>
                        <Chip className={`w-fit h-fit ${returnColor()}`} size="sm" variant="ghost" value={returnValue()} icon={returnIcon()} />
                    </div>
                </div>
                {general?.me?.email && (<div className="flex gap-1 items-center text-sm"><FontAwesomeIcon icon="at" className="w-3 h-3 text-gray-500" /> {general?.me?.email}</div>)}
                {general?.me?.phone && (<div className="flex gap-1 items-center text-sm"><FontAwesomeIcon icon="phone" className="w-3 h-3 text-gray-500" /> {general?.me?.phone}</div>)}
                {general?.me?.country && (<div className="flex gap-1 items-center text-sm"><FontAwesomeIcon icon="globe" className="w-3 h-3 text-gray-500" /> {general?.me?.country?.frLabel}</div>)}
                {general?.me?.country && (<div className="flex gap-1 items-center text-sm"><FontAwesomeIcon icon="location-dot" className="w-3 h-3 text-gray-500" /> {general?.me?.place?.label}</div>)}
            </div>
        </>
    );
}
