import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import { GalleryInterface } from "../../components/Interfaces/GalleryInterface";
import { SearchPicturesType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { PictureResult } from "../../components/SearchResult/impl/PictureResult";
import WrapperGrayZoneLittle from "../../components/WrapperGrayZoneLittle";
import GalleryAPI from "../../services/API/Clients/GalleryAPI";
import GalleryHeader from "./components/Mobile/GalleryHeader";
import ModalAlert from "./components/ModalAlert";
import GalleryHeaders from "./components/Web/GalleryHeader";
import { handleError } from "../../services/Errors/handleErrors";

export default function Gallery() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("gallery")}`;
    const { hashid } = useParams();
    const navigate = useNavigate();
    const [searchBar, setSearchBar] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<GalleryInterface>({
        id: "",
        title: "",
        createdAt: "",
        banner: {
            publicPath: "",
        },
        bannerOffset: 0,
        favoriteCount: 0,
        viewCount: 0,
        pictureLength: 0,
        portfolio: {
            id: "",
            name: "",
            path: "",
            picture: "",
            publicLink: "",
        },
        private: false,
        published: false,
        isFavorited: false,
        owner: {
            id: "",
            verified: false,
        },
        place: {
            id: "",
            label: "",
        },
    });
    const [pictures, setPictures] = React.useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
        data: [],
    });
    
    const handleClose = () => {
        setOpenModal(false);
    };

    const fetchData = async () => {
        if (!hashid) return;

        if (!hashid) return;
        const datas = {
            gallery: `/api/public/galleries/${hashid}`
        }
        await GalleryAPI.addView(datas);

        const [galleryResponse, picturesResponse] = await Promise.all([
            GalleryAPI.getGallerie(hashid),
            GalleryAPI.getGalleriePictures(hashid)
        ]);

        if (galleryResponse.status === 200 || galleryResponse.status === 201) {
            setData(galleryResponse.body);
        } else {
            handleError(galleryResponse);
        }

        if (picturesResponse.status === 200 || picturesResponse.status === 201) {
            setPictures(picturesResponse.body);
        } else {
            handleError(picturesResponse);
        }

        setLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteGallery = async () => {
        if (!hashid) return;

        const response = await GalleryAPI.deleteGallerie(hashid);

        if (response.status === 200 || response.status === 201 || response.status === 204) {
            toast.success(t("galleryDeleted"));
            navigate("/my-portfolio")
        } else {
            handleError(response);
        }
    };

    return (
        <>
            <div className="min-h-full relative">
                <Loader loading={loading} />
                <ModalAlert open={openModal} setOpen={handleClose} onDelete={handleDeleteGallery} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full -mt-3 pb-[340px] md:pb-36">
                    <GalleryHeader searchBar={searchBar} fetchData={fetchData} picturesLength={pictures.pagination.totalItems} data={data} setOpenModal={setOpenModal} />
                    <GalleryHeaders fetchData={fetchData} picturesLength={pictures.pagination.totalItems} data={data} setOpenModal={setOpenModal} />
                    <WrapperGrayZoneLittle px="4">
                        <PictureResult
                            galleries={false}
                            overrides={{
                                pagetype: "gallery",
                                gallery: hashid,
                            }}
                        />
                    </WrapperGrayZoneLittle>
                </div>
                <Footer />
            </div>
        </>
    );
}
