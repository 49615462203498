import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Resiliation() {
    const { t } = useTranslation();
    const [openGeneralites, setOpenGeneralites] = React.useState(false);
    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenGeneralites(!openGeneralites)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">8. {t("cancelation_title")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openGeneralites ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openGeneralites} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("resiliation")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("resiliation_p1")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("resiliation_p2")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("resiliation_social_networks")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("resiliation_social_networks_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("refund_cancelation")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("refund_cancelation_p1")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("refund_cancelation_p2")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("refund_cancelation_p3")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("content_removal")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("content_removal_d")}
                </Typography>
            </Collapse>
        </div>
    );
}
