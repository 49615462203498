import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { HistoryInterface } from "../../components/Interfaces/HistoryInterface";
import { Pagination } from "../../components/Interfaces/PortfolioType";
import Loader from "../../components/Loader";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import SalesAPI from "../../services/API/Clients/SalesAPI";
import { Storage } from "../../services/storage";
import History from "./components/History";
import Photos from "./components/Photos";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { handleError } from "../../services/Errors/handleErrors";

export default function Sales() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    document.title = `ezoom | ${t("purchases")}`;
    const myId = Storage.getId();
    const { type } = useParams();
    const [searchBar, setSearchBar] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [actualUrl, setActualUrl] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [balance, setBalance] = React.useState(0);
    const [history, setHistory] = React.useState<HistoryInterface>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
        data: [],
    });
    const [sales, setSales] = React.useState<{
        pagination: Pagination,
        data: {
            id: string;
            picture: {
                minPath: string;
                id: string;
            };
            saleCount: number,
            available: string,
            originalCount: number,
            basePrice: number,
            status: string
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0
        },
        data: []
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!myId) return;
            const responseSales = await SalesAPI.sales(myId);
            if (responseSales.status === 200) {
                setSales(responseSales.body);
            } else {
                handleError(responseSales);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async (param: string) => {
            if (!myId) return;
            const response = await SalesAPI.balance(myId);
            if (response.status === 200) {
                setBalance(response.body.balance);
            } else {
                handleError(response);
            }
        };
        fetchData(type === "history" ? "history" : 'photos');
        setActualUrl(type === "history" ? "history" : 'photos');
        setLoading(false);
    }, [type]);

    useEffect(() => {
        const fetchData = async () => {
            if (!myId) return;
            const responseSales = await SalesAPI.history(myId);
            if (responseSales.status === 200) {
                setHistory(responseSales.body);
            } else {
                handleError(responseSales);
            }
        };
        fetchData();
    }, []);

    const callNextHistory = async (page?: number) => {
        if (!myId) return;
        const responseSales = await SalesAPI.historyPagination(myId, page ?? history.pagination.current);
        if (responseSales.status === 200) {
            setHistory(responseSales.body);
        } else {
            handleError(responseSales);
        }
    };

    const callNextPicture = async (page: number) => {
        if (!myId) return;
        const responseSales = await SalesAPI.salesPagination(myId, page);
        if (responseSales.status === 200) {
            setSales(responseSales.body);
        } else {
            handleError(responseSales);
        }
    }
    
    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <TitlePage>{t("sales")}</TitlePage>
                    {balance > 50 && (
                        <div className="px-4 mx-auto max-w-7xl">
                            <Alert variant="ghost" className="w-full" color="gray">
                                <div className="text-sm font-bold">
                                    {t("sold_paiement")}
                                    {t("chf")} {balance.toFixed(2)}
                                </div>
                                <div className="text-sm mt-2" dangerouslySetInnerHTML={{ __html: t("sold_paiement_d")}}></div>
                            </Alert>
                        </div>
                    )}
                    <WrapperGrayZone pt="0" hr={false}>
                        <motion.div 
                            variants={{
                                visible: { top: "48px", paddingTop: "12px" },
                                hidden: { top: "0px", paddingTop: "-2px" },
                                barsInactive: { top: "48px", paddingTop: "12px" },
                                barsActive: { top: "102px", paddingTop: "8px" }
                            }}
                            animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible" }
                            transition={{ 
                                duration: 0.35,
                                ease: "easeInOut"
                            }}
                            className="border-b border-gray-200 w-full sticky md:static top-0 bg-white z-30"
                        >
                            <ul className="flex flex-row flex-nowrap -mb-px text-sm font-medium text-center text-gray-500 justify-center w-full">
                                <li className="mr-2">
                                    <button
                                        onClick={() => navigate("/sales")}
                                        className={`${
                                            actualUrl === "photos"
                                                ? "border-orange-400 text-orange-400"
                                                : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                        } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                    >
                                        <FontAwesomeIcon icon="image" />
                                        <span className="hidden md:flex">{t("sell_cours")}</span>
                                        <div
                                            className={`${
                                                actualUrl === "photos"
                                                    ? "text-orange-700 bg-white-100 border border-orange-300"
                                                    : "text-gray-500 bg-white-100 border border-gray-500"
                                            } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                        >
                                            <div className="text-xs font-normal leading-none max-w-full flex-initial">{sales.pagination.totalItems ?? 0}</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="mr-2">
                                    <button
                                        onClick={() => navigate("/sales/history")}
                                        className={`${
                                            actualUrl === "photos"
                                                ? "border-transparent hover:text-gray-600 hover:border-gray-300"
                                                : "border-orange-400 text-orange-400"
                                        } border-b-2 flex-grow inline-flex items-center flex-row gap-2 justify-center p-4 rounded-t-lg group`}
                                    >
                                        <FontAwesomeIcon icon="check" />
                                        <span className="hidden md:flex">{t("sell_good")}</span>
                                        <div
                                            className={`${
                                                actualUrl === "photos"
                                                    ? "text-gray-500 bg-white-100 border border-gray-500"
                                                    : "text-orange-700 bg-white-100 border border-orange-300"
                                            } ml-1 flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full`}
                                        >
                                            <div className="text-xs font-normal leading-none max-w-full flex-initial">{history.pagination.totalItems ?? 0}</div>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </motion.div>
                        <div className="flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pb-16 max-w-7xl mx-auto px-4 md:px-0">
                            {actualUrl === "photos" ? <Photos sales={sales} callNextPhotos={callNextPicture} /> : <History history={history} callNextPicture={callNextHistory} />}
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
