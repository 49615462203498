import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import Lens from "./Lens";
import Flash from "./Flash";
import Boitier from "./Boitiers";
import React, { useEffect } from "react";
import BrandAPI from "../../../../services/API/Clients/BrandAPI";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Material() {
    const { t } = useTranslation();

    const [brands, setBrands] = React.useState<{ value: string; label: string }[]>([]);

    const getData = async () => {
        const respBands = await BrandAPI.setup();
        if (respBands.status === 200) {
            const formatBands = respBands.body.map((band: { id: string; label: string }) => ({ value: band.label, label: band.label }));
            setBrands(formatBands);
        } else {
            handleError(respBands);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
            <Typography className={`text-lg font-bold`}>{t("mysetup")}</Typography>
            <Boitier brands={brands} />
            <Lens brands={brands} />
            <Flash brands={brands} />
        </div>
    );
}
