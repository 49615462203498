import { Dialog } from "@headlessui/react";
import ModalWrapper from "../../../../components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateBackground } from "../../../../services/redux/actions/formAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { RootState } from "../../../../services/redux/store";

export default function ModalChangeBackgroundPortfolio({ open, setOpen } : Readonly<{ open: boolean; setOpen: () => void }>) {
    const { t } = useTranslation();
    const formData = useSelector((state: RootState) => state.form);
    const dispatch = useDispatch();

    const handleSelectBackground = (id: string, minPath: string) => async () => {
        dispatch(updateBackground({ id: id, minPath: minPath }));
        setOpen();
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen overflow-y-auto">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900 text-center">
                    {t("selectbanner")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                {formData.pictures && formData.pictures.data.length > 0 ?
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        {formData.pictures.data.map((picture: { id: string, minPath: string }) => (
                            <button className="aspect-square" key={picture.id} onClick={handleSelectBackground(picture.id, picture.minPath)}>
                                <img src={picture.minPath} alt={picture.id} className="object-cover h-full w-full rounded-lg" />
                            </button>
                        ))}
                    </div>
                : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <FontAwesomeIcon icon="image" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                        <span className="text-base text-gray-700 mb-12 text-center" dangerouslySetInnerHTML={{ __html: t("selectbanner_noPicture")}}></span>
                    </div>
                )}

                <div className="flex justify-center gap-4 mt-4">
                    <button
                        type="button"
                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
