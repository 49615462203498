import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Avatar, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Storage } from "../../../services/storage";
import { DateDiffBetweenTwo } from "../../DateDiffBetweenTwo";
import moment from "moment";
import DefaultPictureEzoom from "../../../assets/images/elements/default-profile.png";
import { returnSubtitle, returnTitle, returnUrl } from "../../../enums/Notifications";
import { handleError } from "../../../services/Errors/handleErrors";

export default function NotificationsMobil({ isOpen, setOpen, side = "right" }: Readonly<{ isOpen: boolean; setOpen: Function; side?: string }>) {
    const { t } = useTranslation();
    const myId = Storage.getId();
    const [notifications, setNotifications] = useState<{
        pagination: {
            current: 0;
            last: 0;
            totalItems: 0;
            itemsPerPage: 0;
        };
        data: {
            context: {
                picture?: string;
                status?: string;
                author?: string;
                image?: string;
                portfolio?: string;
                contact?: string;
            };
            type: string;
            createdAt: string;
            read: boolean;
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            itemsPerPage: 0,
        },
        data: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            if (!isOpen) return;
            if (!myId) return;
            const response = await UserAPI.getNotifications(myId);
            if (response.status === 200) {
                setNotifications(response.body);
            } else {
                handleError(response);
            }
        };
        fetchData();
    }, [isOpen]);

    const hasUnreadNotifications = notifications.data.some((notification) => notification.read === false);

    const indexOfFirstReadTrue = notifications.data.findIndex((notification, index) => {
        if (notification.read === true && (index === 0 || notifications.data[index - 1].read === false)) {
            return true;
        }
        return false;
    });

    return (
        <div className="relative inline-block text-left w-full">
            <div>
                <button onClick={() => setOpen(false)} className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                    <FontAwesomeIcon icon="arrow-left" className="mr-2" />
                    {t("notification")}
                </button>
            </div>

            <div
                className={`flex flex-col justify-center text-center px-2 py-4 items-center w-full rounded-md bg-white `}
            >
                {notifications.data.length !== 0 ? (
                    <>
                        {notifications.data.map((notif, index) => (
                            <>
                                {index === indexOfFirstReadTrue && hasUnreadNotifications && (
                                    <div className="flex flex-row justify-center items-center w-full py-1 px-2 bg-gray-100 rounded-t-md">
                                        <Typography color="orange" className="text-xs font-comfortaa font-light">
                                            {t("old_notifs")}
                                        </Typography>
                                    </div>
                                )}
                                {index === 0 && !hasUnreadNotifications && (
                                    <>
                                        <div className="flex flex-col shadow w-full mb-2 py-4">
                                            <FontAwesomeIcon icon="bell-slash" className="text-3xl my-4 text-gray-400" />
                                            <span className="text-lg text-gray-400 italic mb-2 font-comfortaa">{t("no_notifs")}</span>
                                            <Link to="/#" className="text-sm text-orange-500">
                                                {t("notif_settings")}
                                            </Link>
                                        </div>
                                        <div className="flex flex-row justify-center items-center w-full py-1 px-2 mb-2 rounded-t-md">
                                            <Typography color="orange" className="text-xs font-comfortaa font-light">
                                                {t("old_notifs")}
                                            </Typography>
                                        </div>
                                    </>
                                )}
                                <Link
                                    to={returnUrl(notif.type, notif.context)}
                                    className="w-full flex flex-row p-1 items-center mb-2 shadow-lg bg-white rounded-lg"
                                    key={notif.createdAt}
                                >
                                    <Avatar size="lg" variant="rounded" src={notif.context.image ?? notif.context.picture ?? DefaultPictureEzoom} />
                                    <div className="flex flex-col ml-2">
                                        <Typography color="blue-gray" className="font-light pb-0.5 pt-1 text-sm font-comfortaa text-left">
                                            {returnTitle(t, notif.type)}
                                        </Typography>
                                        <Typography className="font-light pb-0.5 text-xs font-comfortaa text-left">
                                            {returnSubtitle(t, notif.type, notif.context)}
                                        </Typography>
                                        <Typography className="flex flex-row font-light text-xs font-comfortaa text-gray-500 text-left">
                                            <FontAwesomeIcon icon="clock" className="mt-0.5 mr-1" />
                                            {DateDiffBetweenTwo(moment(notif.createdAt), moment())}
                                        </Typography>
                                    </div>
                                </Link>
                            </>
                        ))}
                    </>
                ) : (
                    <div className="flex flex-col">
                        <FontAwesomeIcon icon="bell-slash" className="text-3xl my-4 text-gray-400" />
                        <span className="text-lg text-gray-400 italic mb-2 font-comfortaa">{t("no_notifs")}</span>
                        <Link to="/#" className="text-sm text-orange-500">
                            {t("notif_settings")}
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}
