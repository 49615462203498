import { useTranslation } from "react-i18next";

export default function CGV() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("cgv")}`;
    return (
        <div className="flex flex-col h-full w-full md:w-2/3 px-4 md:px-0 text-justify gap-4 pt-4 max-w-7xl mx-auto">
            <h1 className="text-4xl font-bold text-orange-500">{t("cgv")}</h1>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in pulvinar elit. Donec in dolor non lectus fermentum iaculis id in urna.
                Vivamus vehicula nisi ac tincidunt viverra. Etiam mollis risus id felis vehicula semper. Sed ac erat et dolor rutrum blandit non vitae est.
                Vivamus semper id risus et accumsan. Integer lorem metus, facilisis at convallis eleifend, rutrum eu mi. Phasellus ultricies erat ac consequat
                pulvinar.
            </p>
            <p>
                Aenean iaculis non sem ac suscipit. Nam blandit, turpis at efficitur mattis, libero magna molestie massa, vel dapibus erat nunc commodo est.
                Nulla libero mi, sollicitudin quis libero nec, lobortis dignissim mauris. Pellentesque convallis sodales magna nec accumsan. Ut eleifend posuere
                dolor, sed cursus sem vestibulum a. Ut suscipit, ipsum et tristique consectetur, dui ipsum imperdiet est, eget sollicitudin velit urna ut
                lectus. Maecenas metus ligula, faucibus ac vehicula vitae, volutpat id massa. Donec elit ante, lobortis vel rutrum sed, eleifend vel velit.
            </p>
            <p>
                Etiam vehicula, eros nec elementum interdum, augue libero porttitor dui, at tincidunt risus velit eu massa. Proin non elementum felis. Curabitur
                arcu diam, aliquam quis tortor ut, fermentum lacinia libero. Suspendisse id lorem a nibh cursus gravida. Integer suscipit sem metus, nec
                tristique quam mollis at. Etiam sed metus id enim ullamcorper facilisis sed euismod turpis. Suspendisse fermentum nec ex et posuere. Etiam
                gravida leo nec elit semper, eu auctor sem sollicitudin. Nullam sed hendrerit magna. Pellentesque eu dolor quis nunc bibendum posuere ut
                lobortis erat. Suspendisse posuere lectus non consectetur venenatis.
            </p>
            <p>
                Maecenas et blandit felis. Nullam id cursus justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut ac dictum lacus. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean viverra sapien augue, non porttitor eros pretium laoreet.
                Donec arcu enim, dapibus ac sem eu, elementum mollis augue. Vivamus id ante ac enim consectetur ultrices id in leo. Nulla metus tellus, egestas
                in nibh eget, rhoncus ultricies augue. Phasellus eu gravida nisi. Mauris consectetur cursus diam nec hendrerit. Fusce blandit augue vitae lacus
                fermentum tincidunt. Integer nulla magna, tincidunt at tortor non, consectetur faucibus quam. Cras sit amet justo euismod, tempus augue sed,
                mattis tellus.
            </p>
            <p>
                Phasellus sit amet lectus id erat hendrerit posuere eu in turpis. In ultrices vitae purus ac lobortis. Orci varius natoque penatibus et magnis
                dis parturient montes, nascetur ridiculus mus. Praesent imperdiet purus nec aliquam dignissim. Donec vehicula orci nec tincidunt auctor. Duis
                convallis finibus maximus. Etiam malesuada ut neque vitae commodo. Integer pellentesque, erat vel rhoncus vestibulum, nisl justo posuere neque,
                non volutpat magna nibh nec odio. Cras nec ornare tortor. Nulla posuere nibh ut mollis malesuada. Morbi euismod nunc nec ante finibus, nec
                suscipit est consectetur. Praesent non viverra nisl. Donec efficitur convallis ante, vestibulum malesuada libero molestie sit amet. Phasellus
                porta ultrices finibus. Morbi magna lacus, ultrices vel felis non, accumsan semper lacus.
            </p>
        </div>
    );
}
