import { useEffect } from "react"
import UserAPI from "../../services/API/Clients/UserAPI";
import PortfolioAPI from "../../services/API/Clients/PortfolioAPI";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../services/Errors/handleErrors";

export default function TmpPortfolio() {
    const navigate = useNavigate();
    const redirector = async () => {
        const response = await UserAPI.me();
        if (response.status === 200) {
            const portfolio = await PortfolioAPI.portfolio(response.body.portfolio.id);
            if (portfolio.status === 200) {
                if (portfolio.body.path) {
                    navigate(`/${portfolio.body.path}/photos`, { replace: true });
                } else {
                    navigate(`/portfolio/${response.body.portfolio.id}/photos`, { replace: true });
                }
            } else {
                handleError(portfolio);
            }
        } else {
            handleError(response);
        }
    }
    useEffect(() => {
        redirector();
    }, [])

    return (
        <></>
    )
}