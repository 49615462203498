import Header from "./components/Header";
import HomeSection from "./components/HomeSection";

import WavesTop from "../../assets/images/home/waves/top.svg";
import FooterTop from "../../assets/images/home/waves/footer_top.svg";
import MinimalFooter from "../../components/Footer/MinimalFooter";
import Register from "./components/Register";
import Loader from "../../components/Loader";
import { useEffect, useState } from "react";
import JoinFirst from "./components/JoinFirst";

export default function Teaser() {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="relative overflow-hidden pb-12">
            <Loader loading={loading} />
            <Header />
            <WavesTop className="w-full" />
            <div className="">
                <HomeSection />
                <JoinFirst />
                <Register />
                <div className="hidden md:flex mb-0 absolute bottom-0 left-0 right-0">
                    <FooterTop />
                </div>
            </div>
            <MinimalFooter />
        </div>
    )
}