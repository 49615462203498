import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Textarea, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { useNavigate, useParams } from "react-router-dom";
import { Storage } from "../../../../services/storage";
import React, { useEffect } from "react";
import CardUserForm from "../../../../components/Box/CardUserForm";
import ContactsAPI from "../../../../services/API/Clients/ContactAPI";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/redux/store";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Inquiry() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [myId, setMyId] = React.useState<string>("");
    const general = useSelector((state: RootState) => state.general);
    const portfolio = useSelector((state: RootState) => state.form);
    const [data, setData] = React.useState<{
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    }>({
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        place: "",
        phone: "",
        email: "",
    });
    const [message, setMessage] = React.useState<string>("");

    const fetchMyID = async () => {
        const iHaveToken = Storage.getToken();
        if (iHaveToken) {
            if (!general?.me) return;
            setMyId(general?.me?.portfolio?.id);
            setData({
                firstname: general?.me?.firstname,
                lastname: general?.me?.lastname,
                email: general?.me?.email,
                plan: general?.me?.plan,
            });
            if (general?.me?.company)
                setData({ ...data, company: general?.me?.company });
            if (general?.me?.country)
                setData({ ...data, country: general?.me?.country?.code });
            if (general?.me?.place)
                setData({ ...data, place: general?.me?.place?.id });
            if (general?.me?.phone)
                setData({ ...data, phone: general?.me?.phone });
        }
    }

    useEffect(() => {
        fetchMyID();
    }, []);

    const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeDataPhone = (value: string) => {
        setData({ ...data, phone: value });
    }

    const handleChangeTextarea = (e: any) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async () => {
        const iHaveToken = Storage.getToken();

        if (message === "" || message === undefined) {
            toast.warning(t("missingDataPlace"));
            return;
        }

        const datasBis: {
            sender: {
                company?: string;
                firstname?: string;
                lastname?: string;
                country?: string;
                place?: string;
                phone?: string;
                email?: string;
            };
            message: string;
            receiver: string;
        } = {
            message: message,
            receiver: `/api/public/portfolios/${id}`,
            sender: {}
        };
        if (!iHaveToken) {
            if (!data.firstname || data.firstname === "") {
                toast.warning(t("missingDataFirstname"));
                return;
            }
            if (!data.lastname || data.lastname === "") {
                toast.warning(t("missingDataLastname"));
                return;
            }
            if (!data.email || data.email === "") {
                toast.warning(t("missingDataEmail"));
                return;
            }
            datasBis.sender = {
                ...(data.company && { company: data.company }),
                firstname: data.firstname,
                lastname: data.lastname,
                ...(data.country && { country: `/api/public/countries/${data.country}` }),
                ...(data.place && { place: `/api/public/places/${data.place}` }),
                ...(data.phone && { phone: data.phone }),
                email: data.email,

            }
        }
        const response = await ContactsAPI.newMessage(datasBis);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("message_sent"));
        } else 
            handleError(response);
    };

    const couldIContact = portfolio?.features?.contact && !portfolio?.features?.actions?.contact

    return (
        <div className="mx-auto px-4 py-6 flex flex-col md:flex-row flex-wrap w-full md:w-2/3">
            <div className="w-full flex flex-row items-center justify-between mb-4">
                {portfolio?.features?.contact && !(!portfolio?.features?.contact?.offer && !portfolio?.features?.contact?.appointment && !portfolio?.features?.contact?.buy && portfolio?.features?.contact?.contact) ? (
                    <FontAwesomeIcon icon="arrow-left" onClick={() => navigate(portfolio?.publicLink?.startsWith("/portfolio") ? `${portfolio.publicLink}/contact` : `/portfolio/${portfolio.id}/contact`)} className="text-purple-500 mr-4 cursor-pointer" />
                ) : <div></div>}
                <Typography className="text-2xl font-bold text-center">{t("inquire")}</Typography>
                <div></div>
            </div>
            {myId === id && (
                <div className="flex flex-col gap-4 my-4 px-4 py-2 rounded-lg bg-gray-100 w-full">
                    {t("n_contact_own_portfolio")}
                </div>
            )}
            <CardUserForm disabled={couldIContact} myId={myId} data={data} handleChangeData={handleChangeData} handleChangeDataPhone={handleChangeDataPhone} />
            <Typography className="text-lg font-bold text-left w-full mt-4 mb-3">{t("yourmessage")}</Typography>
            <Textarea color="purple" label={t("description")} className="w-full bg-white" value={message} onChange={handleChangeTextarea} disabled={couldIContact} />
            <button onClick={() => handleSubmit()} className="w-fit mx-auto mt-4">
                <GradientButton text={t("send")} disabled={couldIContact} />
            </button>
        </div>
    );
}