import { useTranslation } from "react-i18next";
import { PortfolioState } from "../../../components/Interfaces/PortfolioType";
import GradientButton from "../../../components/Form/Button/GradientButton";
import { CompletionDetails } from "../../Home/LogIn/components/RightSide";
import { useState } from "react";

interface PublishPortfolioButtonProps {
    data: PortfolioState
    onPublish: () => void
}

enum MandatoryField {
    ABOUT = "about",
    PROFILEPIC = "profilepic",
    BANNER = "banner",
    PHOTO = "photo"
}

const PublishPortfolioButton = ({ data, onPublish }: PublishPortfolioButtonProps) => {
    const { t } = useTranslation();

    const getMissingFields = (): MandatoryField[] => {
        const fields: MandatoryField[] = [];

        if (!data.complete.hasAbout && !data.description) {
            fields.push(MandatoryField.ABOUT);
        }
        if (!data.complete.hasProfilePicture && !data.pictureForModif) {
            fields.push(MandatoryField.PROFILEPIC);
        }
        if (!data.complete.hasBanner && !data.backgroundForModif.id) {
            fields.push(MandatoryField.BANNER);
        }
        if (!data.complete.hasPublishedPhotos) {
            fields.push(MandatoryField.PHOTO);
        }

        return fields;
    }

    const [showDetails, setShowDetails] = useState(false);

    const missingFields = getMissingFields();

    return (<div onMouseEnter={() => setShowDetails(true)} onMouseLeave={() => setShowDetails(false)}>
        <button onClick={() => onPublish()} className={`pt-0.5 text-base whitespace-nowrap ${missingFields.length > 0 ? "opacity-50 pointer-events-none" : ""}`}>
            <GradientButton text={t("saveandpublish")} />
        </button>
        {missingFields.length > 0 && <div className={`absolute bg-white border-gray-300 border p-3 m-2 rounded-lg min-w-[200px] z-10 ${showDetails ? "" : "hidden"}`}>
            <b className="text-lg">{t("beforepublish")}</b>
            <CompletionDetails
                hasAbout={missingFields.indexOf(MandatoryField.ABOUT) === -1}
                hasProfilePicture={missingFields.indexOf(MandatoryField.PROFILEPIC) === -1}
                hasBanner={missingFields.indexOf(MandatoryField.BANNER) === -1}
                hasPublishedPhotos={missingFields.indexOf(MandatoryField.PHOTO) === -1}
                hasPublishedPortfolio={false}
                hideFomalities={true}
            />
        </div>}
    </div>);
}

export default PublishPortfolioButton;