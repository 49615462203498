import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import WatermarkedImage from "../Watermark";

export default function ModalPreview({ open, setOpen, type }: Readonly<{ open: boolean; setOpen: any; type: string }>) {
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen">
                <div className="flex flex-row gap-8 h-full">
                    <div className="w-full h-full max-h-screen items-center flex justify-center">
                        <WatermarkedImage
                            imageUrl="https://images.unsplash.com/photo-1554080353-a576cf803bda?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGhvdG98ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                            position={type === "br" ? "bottom-right" : type === "bl" ?  "bottom-left" : "center"}
                        />
                    </div>
                    <div className="w-full h-full max-h-screen items-center flex justify-center">
                        <WatermarkedImage
                            imageUrl="https://images.pexels.com/photos/2893685/pexels-photo-2893685.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            position={type === "br" ? "bottom-right" : type === "bl" ?  "bottom-left" : "center"}
                        />
                    </div>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
