import Footer from "../../components/Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import GradientButton from "../../components/Form/Button/GradientButtonBig";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { handleError } from "../../services/Errors/handleErrors";

export default function Signin() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("forgotpassword_t")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [email, setEmail] = React.useState("");
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body.picture);
                setLoading(false);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [])

    const handleSubmit = async () => {
        const data = {
            email: email
        }

        const response = await UserAPI.forgot(data)
        if (response.status === 200) {
            alert(t("recoverpassword_alert"))
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="relative min-h-screen">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <Loader loading={loading} />
            <div className="hidden md:block h-full" style={{ backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`, backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vmax", zIndex: -1, position: "fixed", top: 0, left: "50%", transform: 'translate(-50%, 0)', pointerEvents: 'none', backgroundPositionX: 'center'}}></div>
            <div className="relative min-h-screen flex justify-start items-center">
                <div className="relative w-screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 xl:w-1/3 bg-white rounded-0 md:rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <FontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-4 text-center">{t("forgotpassword_t")}</h1>
                    <h3 className="text-sm mb-6 text-center leading-6">{t("forgotpassword_d")}</h3>
                    <div className="flex flex-col w-full mb-6">
                        <Input name="email" type="email" color="orange" value={email} onChange={(e) => setEmail(e.target.value)} label={t("mail")} className="rounded-md" />
                    </div>
                    <button className="w-full mx-auto" onClick={handleSubmit}>
                        <GradientButton text="recoverpassword" />
                    </button>
                    <hr className="border-t border-t-gray-500 shadow-none rounded-lg mt-4 w-3/4 mx-auto" />
                    <p className="text-center text-gray-500 mt-4 flex flex-col gap-2">{t("donthaveaccount")} <Link to="/signup" className="text-orange-500 hover:text-orange-200">{t("signup2")}</Link></p>
                    <hr className="border-t border-t-gray-500 shadow-none rounded-lg mt-4 w-3/4 mx-auto" />
                    <p className="text-center text-gray-500 mt-4 flex flex-col gap-2">{t("alreadyhaveaccount")} <Link to="/signin" className="text-orange-500 hover:text-orange-200">{t("signin")}</Link></p>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
