import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ModalWrapper from "./ModalWrapper";

export default function ModalSignInUp({ open, setOpen }: Readonly<{ open: boolean; setOpen: any }>) {
    const { t } = useTranslation();
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 pt-12 pb-6 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-orange-500 text-center">
                    {t("next_step")}
                </Dialog.Title>
                <div className="absolute top-0 right-0 p-4">
                    <button
                        className="text-gray-400 hover:text-gray-600"
                        onClick={() => setOpen(false)}
                    >
                        <FontAwesomeIcon icon="times" className="text-2xl" />
                    </button>
                </div>
                <div className="flex justify-center gap-4 mt-4">
                    <div className="flex flex-row items-center justify-between text-center gap-2 my-2">
                        <Link
                            className={`w-1/2 border-2 border-transparent inline bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full text-black hover:shadow-lg transition-all text-center whitespace-nowrap cursor-pointer backdrop-blur-sm flex justify-center`}
                            to="/signin"
                        >
                            <span className="flex">{t("signin")}</span>
                        </Link>
                        <Link
                            to="/signup"
                            className="w-1/2 border-2 border-transparent inline bg-orange-500 hover:bg-orange-200 py-1 px-4 rounded-full text-white hover:text-black transition-all text-center whitespace-nowrap cursor-pointer flex justify-center"
                        >
                            <span className="flex">{t("signup")}</span>
                        </Link>
                    </div>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
