import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Locale, Region, Setup, SocialNetwork } from "../../../components/Interfaces/PortfolioType";
import { RootState } from "../../../services/redux/store";
import moment from "moment";

export default function AboutPortfolio() {
    const { t } = useTranslation();
    const portfolio = useSelector((state: RootState) => state.form);

    return (
        <div className="mx-auto  px-2 md:px-4 py-0 md:py-6 flex flex-col md:flex-row max-w-6xl">
            <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-4 mt-4 mb-4">
                <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100">
                    <Typography className={`text-lg font-bold`}>{t("caption")}</Typography>
                    <Typography className="text-base text-gray-800 italic mt-2">
                        {portfolio.description === "" ? t("nodescription") : portfolio.description}
                    </Typography>
                </div>
                {portfolio.photoStyles.length > 0 && (
                    <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <Typography className={`text-lg font-bold`}>{t("mystyles")}</Typography>
                        <div className="flex flex-row flex-wrap mt-2 gap-2">
                            {portfolio.photoStyles.map((style: { enumValue: string; frLabel: string; enLabel: string }) => (
                                <a
                                    href={`/photographers/photoStyle/${style.enumValue}`}
                                    key={style.enumValue}
                                >
                                    <div
                                        className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full"
                                    >
                                        {style.frLabel}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                )}

                {portfolio.socialNetworks.length > 0 && (
                    <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
                        <Typography className={`text-lg font-bold`}>{t("findme")}</Typography>
                        {portfolio.socialNetworks.map((network: SocialNetwork) => (
                            <a href={network.url} target="_blank" key={network.url} className="ml-6 text-base mt-2 flex gap-1.5 items-center font-light">
                                <img src={network.icon} alt={network.label} className="w-4 h-4 rounded" />
                                {network.label}
                            </a>
                        ))}
                    </div>
                )}
            </div>

            <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-4 mt-4 mb-4">
                {portfolio.regions.length > 0 && (
                    <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100">
                        <Typography className={`text-lg font-bold`}>{t("myregions")}</Typography>
                        <div className="flex flex-row flex-wrap mt-2 gap-2">
                            {portfolio.regions.map((region: Region) => (
                                <a
                                    href={`/photographers/region/${region.code}`}
                                    key={region.id}
                                >
                                    <div
                                        className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full"
                                    >
                                        {region.frLabel}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                )}
                {portfolio.locales.length > 0 && (
                    <div className={`flex flex-col w-full p-4 rounded-lg bg-gray-100 ${portfolio.regions.length > 0 ? "mt-4" : "mt-0"}`}>
                        <Typography className={`text-lg font-bold`}>{t("mylangs")}</Typography>
                        <div className="flex flex-row flex-wrap mt-2 gap-2">
                            {portfolio.locales.map((locale: Locale) => (
                                <div
                                    key={locale.enumValue}
                                    className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-orange-200/30 text-orange-700 rounded-full"
                                >
                                    {locale.label}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {(portfolio.body.length > 0 || portfolio.lens.length > 0 || portfolio.flash.length > 0) && (
                    <div
                        className={`flex flex-col w-full p-4 rounded-lg bg-gray-100 ${
                            portfolio.locales.length > 0 || portfolio.regions.length > 0 ? "mt-4" : "mt-0"
                        }`}
                    >
                        <Typography className={`text-lg font-bold`}>{t("mysetup")}</Typography>
                        {portfolio.body.length > 0 && <Typography className="ml-2 text-base mt-2">{t("setup_b")}</Typography>}
                        {portfolio.body.map((set: Setup) => (
                            <Typography key={set.brandLabel} className="ml-6 text-sm text-gray-800 mt-2">
                                {set.brandLabel} {set.modelLabel}
                            </Typography>
                        ))}
                        {portfolio.lens.length > 0 && <Typography className="ml-2 text-base mt-2">{t("setup_o")}</Typography>}
                        {portfolio.lens.map((set: Setup) => (
                            <Typography key={set.brandLabel} className="ml-6 text-sm text-gray-800 mt-2">
                                {set.brandLabel} {set.modelLabel}
                            </Typography>
                        ))}
                        {portfolio.flash.length > 0 && <Typography className="ml-2 text-base mt-2">{t("setup_f")}</Typography>}
                        {portfolio.flash.map((set: Setup) => (
                            <Typography key={set.brandLabel} className="ml-6 text-sm text-gray-800 mt-2">
                                {set.brandLabel} {set.modelLabel}
                            </Typography>
                        ))}
                    </div>
                )}

                <div
                    className={`flex flex-col w-full p-4 rounded-lg bg-gray-100 ${
                        portfolio.body.length > 0 ||
                        portfolio.lens.length > 0 ||
                        portfolio.flash.length > 0 ||
                        portfolio.regions.length > 0 ||
                        portfolio.locales.length > 0
                            ? "mt-4"
                            : "mt-0"
                    }`}
                >
                    <Typography className="text-base text-gray-800 italic ">{t("signupdate", { date: moment(portfolio.createdAt).format("DD.MM.YYYY") })}</Typography>
                </div>
            </div>
        </div>
    );
}
