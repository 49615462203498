import React from 'react';

import Logo from "../assets/images/logos/logo.png"
import Full from "../assets/images/elements/fullwm.png"

interface WatermarkProps {
  imageUrl: string; 
  position: 'bottom-left' | 'bottom-right' | 'center'; 
}

const WatermarkedImage: React.FC<WatermarkProps> = ({ imageUrl, position }) => {
  return (
    <div className='relative inline-block h-full'>
      <img src={imageUrl} alt="principale" className='object-cover object-center h-full w-full' />
      {position === 'center' ? (
        <img src={Full} alt="Filigrane" className='absolute inset-0 h-full w-full' style={{ opacity: 0.5 }} />
      ) : (
        <img
          src={Logo}
          alt="Filigrane"
          style={{
            position: 'absolute',
            bottom: position.includes('bottom') ? '10px' : 'auto',
            top: position.includes('top') ? '10px' : 'auto',
            left: position.includes('left') ? '10px' : 'auto',
            right: position.includes('right') ? '10px' : 'auto',
            opacity: 0.5, 
          }}
          width={150}
        />
      )}
    </div>
  );
};

export default WatermarkedImage;
