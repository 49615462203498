import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Spinner } from "@material-tailwind/react";
import { useState } from "react";
import Auth from "../../services/API/Clients/AuthAPI";
import { Storage } from "../../services/storage";
import { toast } from "react-toastify";
import { handleError } from "../../services/Errors/handleErrors";

export default function Modal2fa({ open, setOpen, token }: Readonly<{ open: boolean; setOpen: () => void; token: string }>) {
    const { t } = useTranslation();
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleConnect = async (e?: React.FormEvent<HTMLFormElement>) => {
        if (e)
            e.preventDefault();
        if (code === "") return;
        if (token === "") return;
        setIsLoading(true);

        const datas = {
            token: token,
            code: code
        }

        const response = await Auth.connect2fa(datas)
        if (response.status === 200 || response.status === 201) {
            Storage.setToken(response.body.token);
            Storage.setRefreshToken(response.body.refresh_token);
            Storage.setId(response.body.id);
            toast.success(t("signin_success"));
            window.location.href = "/";
            setIsLoading(false);
        } else {
            handleError(response);
        }
        // else if (response.body.detail === "Invalid TOTP code") {
        //     toast.warning(t("twofactor_step4error"));
        //     setIsLoading(false);
        // } else {
        //     toast.error(t("expired_token"));
        //     setIsLoading(false);
        //     setOpen();
        // }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("twofactor")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen()
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500 mb-4">{t("twofactor_step3")}</p>
                </div>

                <form onSubmit={handleConnect}>
                    <Input color="orange" onChange={(e) => setCode(e.target.value)} label={t("code")} />

                    <div className="flex justify-center gap-4 mt-6">
                        <button
                            type="button"
                            className="rounded-full bg-orange-500 px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans"
                            onClick={() => handleConnect()}
                        >
                            {isLoading 
                                ? <Spinner color="orange" />
                                : t("signin")}
                        </button>
                        <button
                            type="button"
                            className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                            onClick={() => setOpen()}
                        >
                            {t("cancel")}
                        </button>
                    </div>
                </form>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
