import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-tailwind/react";
import Premium from "../../components/Tarifs/Premium";
import Pro from "../../components/Tarifs/Pro";
import { useMotionValueEvent, useScroll, motion } from "framer-motion";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";

const forfaitsIntitules = [
    {
        title: "sellPrestations",
        isTitle: true,
        infobulles: "sellPrestations_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "comSell",
        isTitle: false,
        infobulles: "comSell_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        pro: {
            isBoolean: false,
            isCheck: false,
            value: "0%",
        },
    },
    {
        title: "sellLicences",
        isTitle: true,
        infobulles: "sellLicences_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "comSellLicences",
        isTitle: false,
        infobulles: "comSellLicences_d",
        free: {
            isBoolean: false,
            isCheck: false,
            value: "> 30% HT",
        },
        premium: {
            isBoolean: false,
            isCheck: false,
            value: "> 8% HT",
        },
        pro: {
            isBoolean: false,
            isCheck: false,
            value: "> 5% HT",
        },
    },
    {
        title: "visibiliteBoosted",
        isTitle: true,
        infobulles: "visibiliteBoosted_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "badgeProPremium",
        isTitle: false,
        infobulles: "badgeProPremium_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "statistic",
        isTitle: true,
        infobulles: "statsPrices_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "otherFonctionnalites",
        isTitle: true,
        infobulles: "",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "followPhotographers",
        isTitle: false,
        infobulles: "followPhotographers_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "likesFavorites",
        isTitle: false,
        infobulles: "likesFavorites_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "shares",
        isTitle: false,
        infobulles: "shares_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "downloads",
        isTitle: false,
        infobulles: "downloads_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "contacts",
        isTitle: false,
        infobulles: "contacts_d",
        free: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
    {
        title: "prioritaryAssistance",
        isTitle: true,
        infobulles: "prioritaryAssistance_d",
        free: {
            isBoolean: true,
            isCheck: false,
            value: "",
        },
        premium: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
        pro: {
            isBoolean: true,
            isCheck: true,
            value: "",
        },
    },
];

export default function Tarifs() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("avis")}`;
    const [searchBar, setSearchBar] = useState<boolean>(false);
    const [monthly, setMonthly] = useState<boolean>(true);
    const [hidden, setHidden] = useState(false);

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <div className="min-h-full relative">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="flex flex-col h-full bg-white -mt-4 pb-[340px] md:pb-36">
                <motion.div
                    variants={{
                        visible: { top: "46px", paddingTop: "12px" },
                        hidden: { top: "0px", paddingTop: "4px" },
                        barsInactive: { top: "46px", paddingTop: "12px" },
                        barsActive: { top: "93px", paddingTop: "8px" },
                    }}
                    animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut",
                    }}
                    className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200"
                >
                    <TitlePage mt={0} mb={0}>
                        {t("aboTarifs")}
                    </TitlePage>
                </motion.div>
                <WrapperGrayZone pt="0" px="0">
                    <div className="mx-auto py-6">
                        <div className="flex flex-col h-full w-full sm:w-[90%] lg:w-2/3 px-1 md:px-0 text-justify pt-4 max-w-7xl mx-auto">
                            <span className="flex gap-2 text-lg text-center mx-auto my-4">{t("aboTarifs_d")}</span>

                            <div className="mx-auto w-fit flex justify-center items-center mb-10 mt-2">
                                <button
                                    onClick={() => setMonthly(true)}
                                    className={`flex px-8 py-2 justify-center items-center cursor-pointer border transition-all rounded-l-full ${
                                        monthly ? "border-r-transparent bg-gray-100" : ""
                                    }`}
                                >
                                    <span className="text-base">{t("monthly")}</span>
                                </button>
                                <button
                                    onClick={() => setMonthly(false)}
                                    className={`flex px-8 py-2 justify-center items-center cursor-pointer border transition-all rounded-r-full ${
                                        !monthly ? "border-l-transparent bg-orange-500 text-white" : ""
                                    }`}
                                >
                                    <span className="text-base">{t("yearlyProm")}</span>
                                </button>
                            </div>
                            <div className="flex flex-col md:flex-row justify-center items-center md:items-start md:justify-center gap-4">
                                <Premium monthly={monthly} />
                                <Pro monthly={monthly} />
                            </div>

                            <div id="detailTarifs"></div>

                            <span id="table" className="flex gap-2 text-lg text-center mx-auto mt-20 mb-4">
                                {t("aboTarifs_d_2")}
                            </span>

                            {/* SEPARATION BEFORE / AFTER */}

                            <div className="w-full 2xl:w-2/3 mx-auto flex grow h-full">
                                <div className="overflow-x-auto w-full">
                                    <table className="min-w-full border-2 border-collapse border-gray-50">
                                        <tbody>
                                            <tr>
                                                <td className="sticky left-0 bg-gray-50">
                                                    <div className="h-10 text-xs flex justify-center items-center border-2 border-gray-50"></div>
                                                    <div className="h-20 text-xs flex justify-center items-center border-2 border-gray-50"></div>
                                                    <div className="h-48 text-xs flex flex-col justify-center text-left px-1 gap-2 bg-gray-100 border-2 border-gray-50 rounded-tl-xl">
                                                        <span className="text-sm max-[670px]:text-base text-black font-bold whitespace-nowrap px-0.5">{t("profesionnalPortfolio")}</span>
                                                        <span className="text-xs max-[670px]:text-sm text-gray-800 whitespace-nowrap px-0.5">{t("storage")}</span>
                                                        <span className="text-xs max-[670px]:text-sm text-gray-800 whitespace-nowrap px-0.5">{t("uploads")}</span>
                                                        <div className="flex justify-between items-center px-0.5">
                                                            <span className="text-xs max-[670px]:text-sm text-gray-800 whitespace-nowrap">{t("galleries")}</span>
                                                            <Tooltip className="max-w-[400px] ml-4 text-[10px]text-sm" content={t("galleries_d")}>
                                                            <FontAwesomeIcon icon="circle-info" className="text-sm" />
                                                            </Tooltip>
                                                        </div>
                                                        <div className="flex justify-between items-center px-0.5">
                                                            <span className="text-xs max-[670px]:text-sm text-gray-800 whitespace-nowrap">{t("privatesGalleries")}</span>
                                                            <Tooltip className="max-w-[400px] ml-4 text-[10px]text-sm" content={t("privatesGalleries_d")}>
                                                            <FontAwesomeIcon icon="circle-info" className="text-sm" />
                                                            </Tooltip>
                                                        </div>
                                                        <div className="flex justify-between items-center px-0.5">
                                                            <span className="text-xs max-[670px]:text-sm text-gray-800 whitespace-nowrap">{t("subdomainPersonnel")}</span>
                                                            <Tooltip className="max-w-[400px] ml-4 text-[10px]text-sm" content={t("subdomainPersonnel_d")}>
                                                            <FontAwesomeIcon icon="circle-info" className="text-sm ml-1" />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-1/5 border border-gray-50">
                                                    <div className="h-10 text-xs flex justify-center items-center border border-gray-50">
                                                        {t("standard")}
                                                    </div>
                                                    <div className="h-20 text-xs font-bold flex justify-center items-center bg-gray-100 border border-gray-50 rounded-tl-xl">
                                                        <span>{t("free")}</span>
                                                    </div>
                                                    <div className="h-48 text-xs flex flex-col justify-center items-center gap-2 bg-gray-100 border border-gray-50 px-0.5">
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-black" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm whitespace-nowrap">100 {t("photos_min")}</span>
                                                        <span className="text-sm whitespace-nowrap">{"<"} 7 {t("weekly_label")}</span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-black" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="minus" className="text-black" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="minus" className="text-black" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="w-1/5 border border-gray-50">
                                                    <div className="h-10 text-xs flex justify-center items-center border border-gray-50">
                                                        <span className="bg-white border border-black rounded py-1 px-3 flex gap-2">
                                                            <FontAwesomeIcon icon="crown" className="block" />
                                                            {t("premium")}
                                                        </span>
                                                    </div>
                                                    <div className="h-20 px-1 text-xs flex flex-col justify-center items-center bg-gray-100 border border-gray-50">
                                                        <span className="text-sm font-bold text-orange-500 text-center whitespace-nowrap">
                                                            {t("chf")} 6.00 {t("monthly_label")}
                                                        </span>
                                                        <br />
                                                        {!monthly && (
                                                            <span className="text-[10px] font-bold text-black text-center">
                                                                {t("orYearly")} {t("chf")} 72.00 {t("pricesttcYearly")}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="h-48 text-xs flex flex-col justify-center items-center gap-2 bg-gray-100 border border-gray-50 px-0.5">
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-orange-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm whitespace-nowrap">5'000 {t("photos_min")}</span>
                                                        <span className="text-sm whitespace-nowrap">{t("unlimited")}</span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-orange-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="minus" className="text-orange-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-orange-500" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="w-1/5 border border-gray-50">
                                                    <div className="h-10 text-xs flex justify-center items-center border border-gray-50">
                                                        <span className="bg-black text-white rounded py-1 px-3 flex gap-2">
                                                        <FontAwesomeIcon icon="crown" className="block" />
                                                        {t("pro")}
                                                        </span>
                                                    </div>
                                                    <div className="h-20 px-1 text-xs flex flex-col justify-center items-center bg-gray-100 border border-gray-50 rounded-tr-xl">
                                                        <span className="text-sm font-bold text-purple-500 text-center whitespace-nowrap">
                                                        {t("chf")} 16.50 {t("monthly_label")}
                                                        </span>
                                                        <br />
                                                        {!monthly && (
                                                            <span className="text-[10px] font-bold text-black text-center">
                                                                {t("orYearly")} {t("chf")} 198.00 {t("pricesttcYearly")}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="h-48 text-xs flex flex-col justify-center items-center gap-2 bg-gray-100 border border-gray-50 px-0.5">
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-purple-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm whitespace-nowrap">10'000 {t("photos_min")}</span>
                                                        <span className="text-sm whitespace-nowrap">{t("unlimited")}</span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-purple-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-purple-500" />
                                                            </span>
                                                        </span>
                                                        <span className="text-sm font-bold text-gray-900 text-left mb-1 gap-6 flex items-center">
                                                            <span className="w-6 flex justify-center">
                                                                <FontAwesomeIcon icon="check" className="text-purple-500" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>

                                            {forfaitsIntitules.map((forfait, index) => (
                                                <tr key={forfait.title} className={`bg-gray-100 ${index === 0 ? "border-t-2 border-t-gray-50 pt-4" : index === forfaitsIntitules.length -1 && "border-b-2 border-b-gray-50 pb-4"} border-l-2 border-l-gray-50 border-r-2 border-r-gray-50`}>
                                                    <td className={`sticky left-0 border-r-2 border-r-gray-50 bg-gray-100 px-0.5 ${index === 0 ? "pt-4" : index === forfaitsIntitules.length -1 && "pb-4"} ${forfait.isTitle ? "pb-2" : "pb-1"}`}>
                                                        <div className="flex justify-between items-center px-0.5">
                                                            <span className={`${forfait.isTitle ? "text-black text-sm font-bold" : "text-xs text-gray-800"}`}>
                                                            {t(forfait.title)}
                                                            </span>
                                                            {forfait.infobulles !== "" && (
                                                                <Tooltip className="max-w-[80%] ml-4 text-sm" content={<div dangerouslySetInnerHTML={{ __html: t(forfait.infobulles) }}></div>}>
                                                                    <FontAwesomeIcon icon="circle-info" className="text-sm ml-1" />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className={`w-1/5 border-r-2 border-r-gray-50 border-l-2 border-l-gray-50 ${index === 0 ? "pt-4" : index === forfaitsIntitules.length -1 && "pb-4"} ${forfait.isTitle ? "pb-2" : "pb-1"}`}>
                                                        <div className="text-xs flex flex-col justify-center items-center gap-2 px-0.5">
                                                            {forfait.free?.isBoolean ? <FontAwesomeIcon icon={forfait.free?.isCheck ? "check" : "minus"} /> : <span className="text-sm">{forfait.free?.value}</span>}
                                                        </div>
                                                    </td>
                                                    <td className={`w-1/5 border-r-2 border-r-gray-50 border-l-2 border-l-gray-50 ${index === 0 ? "pt-4" : index === forfaitsIntitules.length -1 && "pb-4"} ${forfait.isTitle ? "pb-2" : "pb-1"}`}>
                                                        <div className="text-xs flex flex-col justify-center items-center gap-2 px-0.5">
                                                            {forfait.premium?.isBoolean ? <FontAwesomeIcon icon={forfait.premium?.isCheck ? "check" : "minus"} className="text-orange-500" /> : <span className="text-sm">{forfait.premium?.value}</span>}
                                                        </div>
                                                    </td>
                                                    <td className={`w-1/5 border-r-2 border-r-gray-50 border-l-2 border-l-gray-50 ${index === 0 ? "pt-4" : index === forfaitsIntitules.length -1 && "pb-4"} ${forfait.isTitle ? "pb-2" : "pb-1"}`}>
                                                        <div className="text-xs flex flex-col justify-center items-center gap-2 px-0.5">
                                                            {forfait.pro?.isBoolean ? <FontAwesomeIcon icon={forfait.pro.isCheck ? "check" : "minus"} className="text-purple-500" /> : <span className="text-sm">{forfait.pro?.value}</span>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="w-full 2xl:w-2/3 mx-auto flex flex-col justify-center items-center gap-1 bg-gray-100 px-2 py-3 border-t-2 border-t-white rounded-b-xl">
                                <span className="text-base text-black font-bold text-center">{t("legend")}</span>
                                <span className="text-xs text-gray-800 text-center" dangerouslySetInnerHTML={{ __html: t("legend_d") }}></span>
                            </div>

                            <span className="flex gap-2 text-lg text-center mx-auto mt-8 mb-4">{t("aboTarifs_d_3")}</span>
                            <Link
                                to="/signup"
                                className="mx-auto w-fit px-6 py-2 rounded-full bg-orange-500 hover:bg-orange-200 transition-all text-white mb-16"
                            >
                                {t("registerForFree")}
                            </Link>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
            </div>
    );
}
