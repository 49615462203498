
import LogoWhite from "../../assets/images/logos/SVG/whitelogo.svg";
import LogoWhiteBeta from "../../assets/images/logos/ezoombetaneg.png";
import MiniLogoWhite from "../../assets/images/logos/SVG/logo_e.svg";

import LogoGray from "../../assets/images/logos/logo.png";
import LogoGrayBeta from "../../assets/images/logos/ezoombeta.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientButton from "../Form/Button/GradientButtonResponsive";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuHeader from "./composants/MenuHeader";
import { Link } from "react-router-dom";

export default function HeaderLight() {
    const { t } = useTranslation();
    const [withColor, setWithColor] = React.useState(false);

    useEffect(() => {
        function handleScroll() {
            const scrollY = window.scrollY;
            if (scrollY < (window.innerHeight/5*4)) 
                setWithColor(false);
            else 
                setWithColor(true);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            className={`px-2 md:px-[30px] w-full flex justify-between items-center py-2  fixed top-0 left-0 z-40 ${withColor ? "bg-white border-b shadow-md shadow-var" : "bg-transparent border-b border-b-transparent"} transition-all duration-300`}
            data-entrance="from-top"
        >
            <Link to="/" style={{ maxWidth: "250px" }}>
                {withColor ?
                    <>
                        <img src={LogoGrayBeta} alt="logo" className="flex" style={{ height: "45px" }} />
                    </>
                :
                    <>
                        {/* <LogoWhite className="hidden xl:flex h-7" /> */}
                        <img src={LogoWhiteBeta} alt="logo" className="flex" style={{ height: "45px" }} />
                    </>
                }
            </Link>
            <div className="flex justify-between w-fit text-white my-auto mx-0 gap-6">
            </div>
            <div className="my-auto mx-0 flex items-center gap-2 md:gap-4">
                <Link to="/iam-photographer" className={`font-extrabold text-sm ${withColor ? "text-black hover:text-gray-700" : "text-white hover:text-gray-50"} transition-all mr-1`} style={{ textShadow: !withColor ? "#000000BB 1px 0 10px" : "" }}>
                    <span className="hidden md:flex">{t("iam_photograph")}</span>
                </Link>
                <Link
                    className={`hidden md:flex border-2 border-transparent w-auto bg-gray-200/30 hover:bg-white py-1 px-4 rounded-full  ${withColor ? "text-gray-600 hover:text-gray-700" : "text-white hover:text-black"} hover:shadow-lg transition-all text-center cursor-pointer backdrop-blur-sm`}
                    to="/signin"
                >
                    <span className="hidden md:flex">{t("signin")}</span>
                </Link>
                <Link to="/signup" className="hidden md:flex">
                    <GradientButton text="signup" icon="user-plus" />
                </Link>
                <div className="-mt-1 ml-1">
                    <MenuHeader />
                </div>
            </div>
        </div>
    );
}
