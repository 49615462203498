import { faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd, faCreativeCommonsSa, faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import LinkGeneric from "./components/LinkGeneric";
import SelectForUpload from "./components/SelectForUpload";
import TitleDescribe from "./components/TitleDescribe";
import { Option, Select } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const listLicence = [
    { value: 'all-rights-reserved', label: "Tous droits réservés", icons: [faCopyright]},
    { value: 'own-license', label: "Licence personnalisée", icons: [faCopyright]},
    { value: 'cc0', label: "CC0 1.0", icons: [faCreativeCommons, faCreativeCommonsZero]},
    { value: 'cc-by', label: "CC BY 4.0", icons: [faCreativeCommons, faCreativeCommonsBy] },
    { value: 'cc-by-sa', label: "CC BY-SA 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa] },
    { value: 'cc-by-nd', label: "CC BY-ND 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd] },
    { value: 'cc-by-nc', label: "CC BY-NC 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc] },
    { value: 'cc-by-nc-sa', label: "CC BY-NC-SA 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsSa] },
    { value: 'cc-by-nc-nd', label: "CC BY-NC-ND 4.0", icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd] },
]

export default function Licence({ optionChoose, handleChangeLicence, general, cantChangeIt = false }: Readonly<{ optionChoose: string, handleChangeLicence: any, general: any, cantChangeIt?: boolean}>) {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <div className=" pb-4 border-b border-gray-300">
            <ModalChangePlan open={openModal} setOpen={setOpenModal} />
            <div className="flex flex-row justify-between items-center w-full mt-4">
                <div className="flex flex-col w-full pr-2">
                    <TitleDescribe title="userights_license" describe="license_d" />
                    <div className="w-full h-full relative">
                        <SelectForUpload name="userights_license" options={listLicence} optionChoose={optionChoose} handleChangeSelect={handleChangeLicence} isDisabled={cantChangeIt} />
                        {/* <div className="flex flex-row gap-2 items-center justify-start">
                            <div className="w-full md:w-72 2xl:w-full">
                                <Select className="bg-white w-full" color="orange" label={t("defaultlicense")} value={optionChoose} onChange={handleChangeLicence}>
                                    <Option value="all-rights-reserved">
                                        <div className="flex justify-between flex-row">
                                            <span className="mr-auto whitespace-nowrap">Tout droits réservés</span>
                                        </div>
                                    </Option>
                                    <Option value="own-license">
                                        <div className="flex justify-between flex-row">
                                            <span className="mr-auto whitespace-nowrap">Licence personnalisée</span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by" className="">
                                        <div className="flex justify-between flex-row w-full">
                                            <span className="whitespace-nowrap mr-auto">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY 4.0</span>
                                            <span className="ml-36 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by-sa" className="">
                                        <div className="flex justify-between flex-row w-full">
                                            <span className="whitespace-nowrap">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY-SA 4.0</span>
                                            <span className="ml-24 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                                <FontAwesomeIcon icon={faCreativeCommonsSa} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by-nc" className="">
                                        <div className="flex justify-between flex-row">
                                            <span className="whitespace-nowrap">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY-NC 4.0</span>
                                            <span className="ml-24 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                                <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by-nc-sa" className="">
                                        <div className="flex justify-between flex-row">
                                            <span className="whitespace-nowrap">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY-NC-SA 4.0</span>
                                            <span className="ml-12 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                                <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                                <FontAwesomeIcon icon={faCreativeCommonsSa} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by-nd" className="">
                                        <div className="flex justify-between flex-row">
                                            <span className="whitespace-nowrap">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY-ND 4.0</span>
                                            <span className="ml-24 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                                <FontAwesomeIcon icon={faCreativeCommonsNd} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc-by-nc-nd" className="">
                                        <div className="flex justify-between flex-row w-full">
                                            <span className="whitespace-nowrap">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC BY-NC-ND 4.0</span>
                                            <span className="ml-12 flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsBy} />
                                                <FontAwesomeIcon icon={faCreativeCommonsNc} />
                                                <FontAwesomeIcon icon={faCreativeCommonsNd} />
                                            </span>
                                        </div>
                                    </Option>
                                    <Option value="cc0" className="">
                                        <div className="flex justify-between flex-row w-full">
                                            <span className="whitespace-nowrap w-full">{(window.innerWidth < 720 || window.innerWidth > 1536) && "Creative Commons :"} CC0 1.0</span>
                                            <span className="ml-36 text-right flex flex-row gap-1 text-lg">
                                                <FontAwesomeIcon icon={faCreativeCommons} />
                                                <FontAwesomeIcon icon={faCreativeCommonsZero} />
                                            </span>
                                        </div>
                                    </Option>
                                </Select>
                            </div>
                        </div> */}
                    </div>
                    <LinkGeneric link="https://fr.wikipedia.org/wiki/Licence_Creative_Commons" title="learnmorecc" />
                </div>
            </div>
        </div>
    )
}