import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer/Footer";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Avatar, Card, CardBody, CardHeader, Textarea, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DefaultPictureEzoom from "../../assets/images/elements/default-profile.png";
import JsonIconDico from "../../components/Interfaces/IconDico.json";
import Loader from "../../components/Loader";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import ContactsAPI from "../../services/API/Clients/ContactAPI";
import { handleError } from "../../services/Errors/handleErrors";

export default function Contact() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("message")}`;
    const { type, id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any>({});
    const [searchBar, setSearchBar] = React.useState(false);

    useEffect(() => {
        setLoading(false);
        const fetchData = async () => {
            if (!type || !id) return;
            if (type === "message") {
                const response = await ContactsAPI.contactMessage(id);
                if (response.status === 200) {
                    setData(response.body);
                } else {
                    handleError(response);
                }
            } else if (type === "offer" || type === "appointment") {
                const response = await ContactsAPI.contactService(id);
                if (response.status === 200) {
                    setData(response.body);
                } else {
                    handleError(response);
                }
            }
        };
        fetchData();
    }, [type, id]);

    return (
        <>
            <div className="min-h-screen bg-gray-50 relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full bg-white -mt-4 pt-4 pb-[340px] md:pb-36">
                    <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-2 bg-white z-30 border-b border-gray-200 mt-4 md:mt-0">
                        <TitlePage mt={0} mb={0}>
                            {type === "message" 
                                ?  t("messagefrom", { user: data?.sender?.firstname + " " + data?.sender?.lastname })
                                : data?.type === "ask" 
                                    ? t("offerfrom", { user: data?.sender?.firstname + " " + data?.sender?.lastname })
                                    : t("rdvfrom", { user: data?.sender?.firstname + " " + data?.sender?.lastname })
                            }   
                        </TitlePage>
                    </div>
                    <WrapperGrayZone pt="0" px="8">
                        <div className="mx-auto py-6">
                            <div className="-m-1 flex flex-wrap md:-m-2">
                                <div className="w-full md:w-3/4 mx-auto">
                                    <div className="flex flex-col md:flex-row gap-4">
                                        <Card color="transparent" shadow={false} className="w-full max-w-[26rem]">
                                            <CardHeader
                                                color="transparent"
                                                floated={false}
                                                shadow={false}
                                                className="mx-0 flex items-center gap-4 pt-2 pl-2 pb-8"
                                            >
                                                <Avatar
                                                    size="lg"
                                                    variant="circular"
                                                    src={data?.sender?.picture ?? DefaultPictureEzoom}
                                                    alt="tania andrew"
                                                    className="ring ring-orange-500/50"
                                                />
                                                <div className="flex w-full flex-col gap-0.5">
                                                    <div className="flex items-center justify-between">
                                                        <Typography variant="h5" color="blue-gray">
                                                            {data?.sender?.firstname} {data?.sender?.lastname}
                                                        </Typography>
                                                    </div>
                                                    <Typography color="blue-gray">{data?.sender?.plan ?? "USER"}</Typography>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="mb-6 p-0 pl-2 flex flex-col">
                                                <Typography>
                                                    <FontAwesomeIcon icon="envelope" className="text-orange-500 mr-1" />
                                                    {data?.sender?.email}
                                                </Typography>
                                                <div className="flex flex-col items-start">
                                                    <Typography>
                                                        <FontAwesomeIcon icon="clock" className="text-orange-500 mr-1" />
                                                        {t("creneaux")}
                                                    </Typography>
                                                    {data?.dates !== undefined && data?.dates.length > 0 && data?.dates.map((date: any, index: number) => (
                                                        <Typography className="ml-5 text-xs" key={date.begin + date.end}>
                                                            {moment(date.begin).format("DD.MM.YYYY HH:mm")} - {moment(date.end).format("DD.MM.YYYY HH:mm")}
                                                        </Typography>
                                                    ))}
                                                </div>
                                                {data?.service && (
                                                    <div className="flex flex-col items-start">
                                                        <Typography>
                                                            <FontAwesomeIcon icon="folder" className="text-orange-500 mr-1" />
                                                            {t("performance")}
                                                        </Typography>
                                                        <Typography className="ml-5 text-xs">
                                                            {data?.service?.title} - {t(data?.service?.priceType)} {data?.service?.priceValue && `${data?.service?.priceValue} ${t("chf")}`}{data?.service?.priceCeil && `- ${data?.service?.priceCeil}  ${t("chf")}`} 
                                                        </Typography>
                                                        <Typography className="ml-5 text-xs">
                                                            <FontAwesomeIcon icon={JsonIconDico[data?.service?.type?.enumValue as keyof typeof JsonIconDico] as IconProp} className="mr-2" />
                                                            {data?.service?.type?.frLabel}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                        <div className="bg-gray-200 p-4 rounded-lg w-full max-h-96 overflow-y-scroll text-justify flex flex-col gap-4 h-full">
                                            <Textarea color="orange" label="Contenu du message" className="bg-white h-full grow" value={data?.message} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WrapperGrayZone>
                </div>
                <Footer />
            </div>
        </>
    );
}
