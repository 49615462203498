import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Chip, IconButton, Typography } from "@material-tailwind/react";
import AutosizeInput from "react-input-autosize";
import { useTranslation } from "react-i18next";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import React, { useEffect, useRef, useState } from "react";
import ModalChangeBackgroundGallry from "../ModalChangeBackgroundGallery";
import { useSelector } from "react-redux";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import { Link } from "react-router-dom";
import InputPlace from "../../../../components/Form/Input/InputPlace";
import { v4 as uuidv4 } from "uuid";
import PublishGalleryButton from "../../../Portfolio/componentsEdit/PublishButtonEdit";


export default function EditHeaders({
    hashid,
    data,
    picturesLength,
    tmpNewBanner,
    setTmpNewBanner,
    handleSave,
    handleSavePublish,
    handleChange,
    changeBannerOffset,
    handleChangeDate,
    onSelected
}: Readonly<{
    hashid: string | undefined;
    data: GalleryInterface;
    picturesLength: number,
    tmpNewBanner: { id: string, minPath: string },
    setTmpNewBanner: Function,
    handleSave: Function,
    handleSavePublish: Function,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    changeBannerOffset: (number: number) => void;
    handleChangeDate: (date: string) => void;
    onSelected: (value: string, label: string | undefined) => void;
}>) {
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalChangePlan, setOpenModalChangePlan] = React.useState(false);
    const general = useSelector((state: any) => state.general);
    const { t } = useTranslation();
    const [uuid, setUuid] = React.useState<string>("");
    const parentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputWidth, setInputWidth] = useState('100px');

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const handleChangeBackground = (id: string, minPath: string) => {
        setTmpNewBanner({ id, minPath });
        setOpenModal(false);
    };

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setInputWidth(`calc(${parentHeight}px - 60px)`);
        }
    }, [window.innerWidth, window.outerWidth]);

    return (
        <>
            <ModalChangePlan open={openModalChangePlan} setOpen={setOpenModalChangePlan} />
            <ModalChangeBackgroundGallry handleSelectBackground={handleChangeBackground} open={openModal} setOpen={() => setOpenModal(!openModal)} />
            <div
                className="hidden md:flex relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${(
                        tmpNewBanner && tmpNewBanner.minPath !== "" && tmpNewBanner.minPath) || (data && data.banner && data.banner.publicPath) || DefaultBannerEzoom

                    })`,
                }}
            >
                <div
                    ref={parentRef}
                    style={{ height: "calc(100% - 60px)", width: "40px" }}
                    className={`absolute bottom-0 top-2 right-2 flex flex-col items-center justify-center ${((tmpNewBanner && tmpNewBanner.minPath !== "" && tmpNewBanner.minPath) || (data && data.banner && data.banner.publicPath)) ? "opacity-100" : "opacity-0"}`}
                >
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={data.bannerOffset}
                        onChange={(e) => changeBannerOffset(Number(e.target.value))}
                        ref={inputRef}
                        style={{ width: inputWidth }}
                        className="-rotate-90 transparent h-[4px] cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 rounded-full"
                        id="customRange1"
                    />
                </div>
                <div style={{ height: "40px" }} className="absolute bottom-2 right-2 flex flex-col items-center justify-center">
                    <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 cursor-pointer" onClick={() => setOpenModal(true)}>
                        <FontAwesomeIcon icon="pen" />
                    </IconButton>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="flex flex-row flex-wrap md:flew-nowrap justify-center md:justify-end gap-4 mt-2 px-4">
                    {!data.published && (
                        // <button type="button" onClick={() => handleSavePublish()} className="pt-0.5">
                        //     <GradientButton text={t(data.private ? "saveandpublishprivate" : "saveandpublish")} />
                        // </button>
                        <PublishGalleryButton onPublish={() => handleSavePublish()} data={data} banner={data.banner} tmpBanner={tmpNewBanner} pictures={picturesLength} />
                    )}
                    <button type="button" onClick={() => handleSave()} >
                        <GradientButton text={t("save")} />
                    </button>
                    <Link to={`/upload`}>
                        <button className="border inline w-fit hover:text-black py-2.5 px-4 rounded-full bg-white hover:bg-gray-50 transition-all">
                            <span className="flex">{t("importPictures")}</span>
                        </button>
                    </Link>
                    <a href={`/gallery/${hashid}`}>
                        <button className="border inline w-fit hover:text-black py-2.5 px-4 rounded-full bg-white hover:bg-gray-50 transition-all">
                            <span className="flex">{t("cancel")}</span>
                        </button>
                    </a>
                </div>
                <Typography className="text-4xl font-bold text-center font-sans px-4 mt-4">
                    <AutosizeInput
                        type="text"
                        value={data.title ?? ""}
                        name="title"
                        placeholder="Titre"
                        onChange={handleChange}
                        className="border-b pb-1 border-b-gray-500"
                        style={{ fontFamily: "Great Vibes" }}
                    />
                </Typography>
                <div className="w-full flex flex-row justify-center items-end gap-2 mt-4 px-4">
                    <FontAwesomeIcon icon="map-marker-alt" className="text-gray-500 mb-2.5" />
                    <div className="w-fit">
                        <InputPlace uuid={uuid} onSelect={onSelected} value={data.place} whichStyle="other" />
                    </div>
                    <FontAwesomeIcon icon="calendar" className="text-gray-500 mb-2.5" />
                    <input type="date" placeholder="Date" value={data.date} onChange={(e) => handleChangeDate(e.target.value)}  className="border-b pb-1 border-b-gray-500" />
                    {general && general.me && (general.me.plan === "PRO") ? (
                        <button className="flex flex-row gap-2 items-center" onClick={() => data.private ? handleChange({ target: { name: "private", value: "nok" } } as React.ChangeEvent<HTMLInputElement>) :  handleChange({ target: { name: "private", value: "ok" } } as React.ChangeEvent<HTMLInputElement>)}>
                            <FontAwesomeIcon icon={data.private ? "lock" : "lock-open"} className="text-gray-500 mb-2.5" />
                            <Typography className="border-b pb-1 border-b-gray-500">{data.private ? t("private") : t("public")}</Typography>
                        </button>
                    ) : (
                        <button className="flex flex-row gap-2 items-center" onClick={() => setOpenModalChangePlan(!openModalChangePlan)}>
                            <FontAwesomeIcon icon="lock-open" className="text-gray-500 mb-2.5" />
                            <Typography className="border-b pb-1 border-b-gray-500">{t("public")}</Typography>
                        </button>
                    )}
                </div>
                <div className="flex flex-col md:flex-row justify-between px-4 mt-4 max-w-7xl mx-auto w-full mb-4 items-center">
                    <div className="flex flex-row justify-center md:justify-start gap-4 px-4 mx-auto w-full md:w-1/3">
                        <Avatar src={data.portfolio.picture ?? DefaultPictureEzoom} />
                        <div className="flex flex-col">
                            <Typography className="text-lg font-bold">{data.portfolio.name}</Typography>
                            <Typography className="text-sm">Photographe</Typography>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center gap-4 px-4 mx-auto w-full md:w-1/3">
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${picturesLength ?? 0} ${t("photos")}`}
                            icon={<FontAwesomeIcon icon="images" className="h-4 mt-0.5" />}
                        />
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${data.viewCount ?? 0} ${t("views")}`}
                            icon={<FontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                        />
                        <Chip
                            className="w-fit h-fit"
                            color="gray"
                            variant="ghost"
                            value={`${data.favoriteCount ?? 0} ${t("favs")}`}
                            icon={<FontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                        />
                    </div>
                    <div className="flex flex-row justify-center md:justify-end gap-4 px-4 mx-auto w-full md:w-1/3 text-gray-500">
                        
                    </div>
                </div>
            </div>
        </>
    );
}
