import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Input, Radio, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateExclusive, updateLimited, updateLimitedCount, updateUntil } from "../../../services/redux/actions/contractActions";
import React from "react";

export default function UseSousLicence({ followChangeUntil, setFollowChangeUntil, openGeneralites, setOpenDescription }: Readonly<{ followChangeUntil: string, setFollowChangeUntil: (value: string) => void, openGeneralites: boolean, setOpenDescription: (value: boolean) => void}>) {
    const { t } = useTranslation();
    const contract = useSelector((state: any) => state.contract);
    const dispatch = useDispatch();
    const handleChangeAvailability = (event: any) => {
        if (event.target.value === "none") {
            setFollowChangeUntil("none")
            dispatch(updateUntil(null))
        } else 
            setFollowChangeUntil("until")
    };

    const handleChangeExclusive = (e: boolean) => {
        dispatch(updateExclusive(e))
    }

    const handleCheckLimited = (e: boolean) => {
        dispatch(updateLimited(e))
    }

    const handleChangeUntil = (event: any) => {
        dispatch(updateUntil(event.target.value))
    };

    const handleChangeLimitedCount = (event: any) => {
        dispatch(updateLimitedCount(Number(event.target.value)))
    };

    const labelDate = (
        <div className="flex flex-col md:flex-row md:items-center gap-2 w-full">
            <p className="font-sans">{t("available_input")}</p>
            <div className="flex flex-row items-center gap-2">
                <Input type="date" color="orange" label={t("nombre_exemplaire")} onChange={handleChangeUntil} disabled={followChangeUntil === "none"} />
            </div>
        </div>
    );

    const labelExemplaire = (
        <div className="flex flex-col md:flex-row md:items-center gap-2 w-full">
            <p className="font-sans">{t("limited_original_p1")}</p>
            <div className="flex flex-row items-center gap-2">
                <Input type="number" color="orange" label={t("nombre_exemplaire")} className="bg-white" onChange={handleChangeLimitedCount} value={contract.limitedCount} min={0} disabled={!contract.limited} />
            </div>
            <p className="font-sans">{t("limited_p2")}</p>
        </div>
    );

    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openGeneralites)}
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">3. {t("howtobuy")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openGeneralites ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openGeneralites} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="small" className="text-justify">
                    {t("howtobuy_d")}
                </Typography>
                <Radio
                    className="min-w-[20px] forceSizeRadioButtons mr-5 sm:mr-0 bg-white"
                    color="orange"
                    name="userLimitdezdeez"
                    value="none"
                    onChange={handleChangeAvailability}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("perpetual")}</Typography>}
                    checked={followChangeUntil === "none"}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${followChangeUntil === "none" ? "" : "text-gray-500"}`}>
                    <div className="text-xs mb-2">{t("perpetual_d_asterix")}</div>
                    {t("perpetual_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="userLimitdezdeez"
                    value="all"
                    label={labelDate}
                    onChange={handleChangeAvailability}
                    checked={followChangeUntil === "until"}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${followChangeUntil === "until" ? "" : "text-gray-500"}`}>
                    {t("available_until_d")}
                </Typography>

                <hr className="w-2/3 border-gray-300" id="section2" />

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="exclusivity"
                    value="all"
                    onChange={() => handleChangeExclusive(false)}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("non_exclusive")}</Typography>}
                    checked={!contract.exclusive}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${!contract.exclusive ? "" : "text-gray-500"}`}>
                    {t("non_exclusive_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="exclusivity"
                    value="1"
                    onChange={() => handleChangeExclusive(true)}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("exclusive")}</Typography>}
                    checked={contract.exclusive}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.exclusive ? "" : "text-gray-500"}`}>
                    {t("exclusive_d")}
                </Typography>
                
                <hr className="w-2/3 border-gray-300" />

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="project"
                    value="none"
                    onChange={() => handleCheckLimited(false)}
                    label={<Typography variant="paragraph" className="font-semibold text-justify w-full md:w-full break-words">{t("unlimited")}</Typography>}
                    checked={!contract.limited}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${!contract.limited ? "" : "text-gray-500"}`}>
                    {t("unlimited_d")}
                </Typography>

                <Radio
                    className="min-w-[20px] forceSizeRadioButtons bg-white"
                    color="orange"
                    name="project"
                    value="1"
                    onChange={() => handleCheckLimited(true)}
                    label={labelExemplaire}
                    checked={contract.limited}
                />
                <Typography variant="small" className={`text-justify -mt-4 px-4 ${contract.limited ? "" : "text-gray-500"}`}>
                    {t("limited_d")}
                </Typography>

                <Typography variant="small" className="text-justify">
                    {t("usage_d")}
                </Typography>
                
            </Collapse>
        </div>
    );
}
