import Charts from "./Chart";

const datas = [
    { label: "viewPortfolio", beginQuery: "?entity=portfolio-view" },
    { label: "subsPortfolio", beginQuery: "?entity=portfolio-subscription" }
]

export default function Portfolio() {
    return (
        <div className="bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pt-4 mx-0 md:px-8 flex flex-col pb-2 md:pb-8">
            <div className="w-full">
                <div className="flex flex-row flex-wrap justify-center">
                    {datas.map((item, index) => (
                        <Charts color={"purple"} query={item.beginQuery} title={item.label} key={item.label} />
                    ))}
                </div>
            </div>
        </div>
    )
}