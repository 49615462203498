import { GeneralReducerType } from "../../components/Interfaces/GeneralReducerType"

export const getPageName = (general: GeneralReducerType, type: string, value: string) => {
    console.log(general.photoStyles)
    if (type === "category") return general.serviceTypes.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "photoStyle") return general.photoStyles.find(item => item.enumValue === value)?.frLabel ?? value
    if (type === "region") return general.regions.find(item => item.code === value)?.frLabel ?? value
    return null;
}

const ALLOWED_TYPES = ["service", "photoStyle", "region"];

export const getPageOverride = (type?: string, value?: string) => {
    if (!type || !value) return { pagetype: "search" };
    if (!ALLOWED_TYPES.includes(type)) return { pagetype: "search" };
    if (type === "region") return { region: value, country: "CHE", pagetype: "search" };
    return { [type]: value, pagetype: type };
}