import APIClient from "../APIClients";

export default class BrandAPI {
    public static async setup() {
        return APIClient.ezoom.get(`/setup-brands`);
    }

    public static async model(model: string) {
        return APIClient.ezoom.get(`/brand-models?brand=${model}`);
    }

    public static async modelSimple() {
        return APIClient.ezoom.get(`/brand-models`);
    }
}
