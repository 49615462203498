import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";

import FullLogoEzoom from "../../assets/images/logos/SVG/logo.svg";
import FullLogoEzoomBeta from "../../assets/images/logos/ezoombeta.png"
import MenuHeader from "./composants/MenuHeader";
import MenuProfil from "./composants/MenuProfil";

import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import DoubleBar from "./DoubleBar";

export default function HeaderMobile({ searchBar, openSearchBar } : Readonly<{ searchBar: boolean, openSearchBar: any }>) {
    const location = useLocation();
    const pathname = location.pathname;
    const [hidden, setHidden] = React.useState(false);
    const signin = localStorage.getItem("token") ?? false;

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    return (
        <motion.nav 
            className="block lg:hidden w-full sticky top-0 left-0 z-40 -mt-2.5"
            variants={{
                visible: { y: 0 },
                hidden: { y: "-100%" },
                bars: { y: "-48px"}
            }}
            animate={hidden ? searchBar ? "bars" : "hidden" : "visible" }
            transition={{ 
                duration: 0.35,
                ease: "easeInOut"
            }}
        >
            <div className="overflow-visible bg-white pt-0.5 shadow-md shadow-var">
                <div
                    className={`mx-2 ${
                        pathname === "/search" ||
                        pathname === "/favorites" ||
                        pathname === "/favorites/photographers" ||
                        pathname === "/photographers" ||
                        pathname === "/popular-photographers" ||
                        pathname === "/recent" ||
                        pathname === "/galeries" ||
                        pathname === "/popular"
                            ? "pb-2.5"
                            : "pb-0"
                    }  relative flex flex-col`}
                >
                    <div className="my-1.5 flex flex-around flex-row w-full gap-1">
                        <Link to="/" className="flex items-center ml-1" style={{ maxWidth: "250px" }}>
                            {/* <FullLogoEzoom style={{ height: "30px" }} alt="full_logo_ezoom" /> */}
                            <img src={FullLogoEzoomBeta} alt="logo" className="w-60" />
                        </Link>
                        <div className="flex m-auto relative w-full justify-end pr-1 items-center gap-1" style={{ lineHeight: 0 }}>
                            <button
                                onClick={() => openSearchBar(!searchBar)}
                                className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 py-2 px-2 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                            >
                                <FontAwesomeIcon icon="magnifying-glass" className="text-base text-gray-500 hover:text-orange-500" />
                            </button>
                            {signin && (
                                <>
                                    <Link
                                        className="flex items-center justify-center text-center cursor-pointer text-base font-extralight w-6 h-full hover:bg-gray-100 rounded-full bg-white text-gray-500 hover:text-gray-700"
                                        to="/upload"
                                    >
                                        <FontAwesomeIcon icon="add" className="text-base text-gray-500 hover:text-orange-500" />
                                    </Link>
                                    <MenuProfil />
                                </>
                            )}
                        </div>
                        <MenuHeader />
                    </div>
                </div>
                {(pathname === "/search" ||
                    pathname === "/favorites" ||
                    pathname === "/favorites/photographers" ||
                    pathname === "/photographers" ||
                    pathname === "/popular-photographers" ||
                    pathname === "/recent" ||
                    pathname === "/recent-photographers" ||
                    pathname === "/galeries" ||
                    pathname === "/popular") && <SubMenu pathname={pathname} />}
                <div className={`${searchBar ? "flex h-12 opacity-1 pointer-events-auto" : "flex h-0 opacity-0 pointer-events-none"} w-full mx-auto flex-wrap justify-between text-base px-2 transition-all bg-white`}>
                    <DoubleBar />
                </div>
            </div>
        </motion.nav>
    );
}
