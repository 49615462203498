import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GalleryAPI from "../../../services/API/Clients/GalleryAPI"
import Gallery from "../../Box/Gallery/Gallery"
import { Galleries } from "../../Interfaces/PortfolioType"
import SearchResult from "../SearchResult"
import { FilterConfiguration } from "../filters/FilterConfiguration"
import DrawerForFilter from "../mobile/DrawerForFilter"
import { CompatibleFilterList, FilterList, FilterRenderConfiguration, FilterSchema, FilterType, PaginatedResponse, SearchInterface } from "../types/global"
import { Typography } from "@material-tailwind/react"
import { useTranslation } from "react-i18next"
import { handleError } from "../../../services/Errors/handleErrors"

interface EnumValue {
    enumValue: string
    frLabel: string
    enLabel: string
}

interface GalleryCompatibleFilters extends CompatibleFilterList {
    category: EnumValue[]
    typestyle: EnumValue[]
    date: {
        takeDate: string
    }[]
    region: {
        code: string
    }[]
}


interface GalleryFilterList extends FilterList {
    query?: string;
    category?: string;
    typestyle?: string;
    portfolio?: string;
    date?: string;
    country?: string;
    region?: string;
    from_followed?: boolean;
    favorites?: boolean;
}

const GalleryFilterSchema: FilterSchema<GalleryFilterList> = {
    query: 'string',
    category: 'string',
    typestyle: 'string',
    portfolio: 'string',
    date: 'string',
    country: 'string',
    region: 'string',
    from_followed: 'boolean',
    favorites: 'boolean',
    galleries: 'boolean',
}

const GalleryFiltersRender: FilterRenderConfiguration<GalleryCompatibleFilters> = {
    galleries: {
        type: FilterType.BOOLEAN,
        mobile: FilterType.BOOLEAN,
        getKey: () => 'galleries',
        getValue: (value) => value,
    },
    category: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: EnumValue) => o.enumValue,
        getValue: (o: EnumValue) => o.frLabel,
    },
    typestyle: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: EnumValue) => o.enumValue,
        getValue: (o: EnumValue) => o.frLabel,
    },
    date: {
        type: FilterType.DATEPICKER,
        mobile: FilterType.DATEPICKER,
        getKey: (o: { takeDate: string }) => o.takeDate,
        getValue: (o: { takeDate: string }) => o.takeDate,
    },
    region: {
        type: FilterType.DROPDOWN,
        mobile: FilterType.DROPDOWN,
        getKey: (o: { code: string }) => o.code,
        getValue: (o: { code: string }) => o.code,
    }
}

const fetchGalleries = async (search: SearchInterface<GalleryFilterList>) => {
    const response = await GalleryAPI.searchGalleriePagination(search.filters.getApiQueryParams(), search.page);
    if (response.status === 200) {
        return response.body as PaginatedResponse<Galleries>;
    } else {
        handleError(response);
    }
};

const fetchCompatibleFilters = async (filters: FilterConfiguration<GalleryFilterList>) => {
    const response = await GalleryAPI.compatibleFilters(filters.getApiQueryParams());
    if (response.status === 200) {
        return response.body as GalleryCompatibleFilters;
    } else {
        handleError(response);
    }
}

interface GalleryResultProps {
    filters?: boolean
    overrides?: GalleryFilterList
}

const PictureNoResults = () => {
    const { t } = useTranslation();

    return (<div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
        <FontAwesomeIcon icon="video-slash" className="mt-12 text-4xl text-gray-500/50 mb-4" />
        <Typography className="text-base text-gray-700 mb-12">{t("noPictures")}</Typography>
    </div>)
}

export const GalleryResult = (props: GalleryResultProps) => {
    return (<SearchResult<GalleryFilterList, Galleries, GalleryCompatibleFilters>
        filtersSchema={GalleryFilterSchema}
        filters={props.filters ?? true}
        filtersRender={GalleryFiltersRender}
        fetchResults={fetchGalleries}
        fetchFilters={fetchCompatibleFilters}
        fetchGalleries={undefined}
        filterOverrides={props.overrides}

        NoResults={<PictureNoResults />}

        sortOptions={[
            { key: "publishdate", value: 'sort.publishdate' },
            { key: "viewcount", value: 'sort.viewcount' },
            { key: "favoritecount", value: 'sort.favoritecount' },
        ]}

        renderEntity={(item, index, context, loadMore) => (
            <Gallery
                key={item.id}
                data={item}
            />
        )}
    />
    )
}


export const GalleryDrawerForFilters = () => (
    <DrawerForFilter<GalleryFilterList, GalleryCompatibleFilters>
        renderConfiguration={GalleryFiltersRender}
    />
)