import { Collapse, Input, Rating, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TestimonialAPI from "../../../../services/API/Clients/Testimonial";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DefaultPicture from "../../../../assets/images/elements/default-profile.png"
import { Storage } from "../../../../services/storage";
import { RootState } from "../../../../services/redux/store";
import { handleError } from "../../../../services/Errors/handleErrors";
import moment from "moment";
import { DateDiffBetweenTwo } from "../../../../components/DateDiffBetweenTwo";
import { Link } from "react-router-dom";

export default function BigComment({
    message,
    answer,
    handleReloadTestimonials,
}: Readonly<{
    message: { id: string; content: string; name: { publicName: string, portfolio?: string }; photo: string; pictureQuality: number; professionalism: number; qualityPrice: number; createdAt: string};
    answer?: { content?: string; photo?: string };
    handleReloadTestimonials: () => void;
}>) {
    const portfolio = useSelector((state: RootState) => state.form);
    const general = useSelector((state: RootState) => state.general);
    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => setOpen((cur) => !cur);
    const [answering, setAnswering] = React.useState<string>("");
    const { t } = useTranslation();
    const token = Storage.getToken();
    const [myId, setMyId] = React.useState("");

    useEffect(() => {
        const fetchId = async () => {
            if (!general?.me) return;
            setMyId(general?.me?.portfolio?.id)
        }
        if (token) {
            fetchId();
        }
    }, []);

    const handleSubmitAnswer = async () => {
        const response = await TestimonialAPI.answer(message.id, { answer: answering });
        if (response.status === 200) {
            setAnswering("");
            toast.success(t("answerSent"));
            handleReloadTestimonials();
        } else 
            handleError(response);
    }

    const getMyNote = (): string => {
        let result = 0;
        let divider = 0;
        if (message.pictureQuality) {
            result += message.pictureQuality;
            divider++;
        }
        if (message.professionalism) {
            result += message.professionalism;
            divider++;
        }
        if (message.qualityPrice) {
            result += message.qualityPrice;
            divider++;
        }
        if (divider === 0) return "0"
        return `${(result / divider).toFixed(2)}`;
    }

    const getMyNoteNumber = (): number => {
        let result = 0;
        let divider = 0;
        if (message.pictureQuality) {
            result += message.pictureQuality;
            divider++;
        }
        if (message.professionalism) {
            result += message.professionalism;
            divider++;
        }
        if (message.qualityPrice) {
            result += message.qualityPrice;
            divider++;
        }
        if (divider === 0) return 0
        return Number((result / divider).toFixed(2));
    }

    return (
        <div className="flex-col w-full py-4 mx-auto mt-3 bg-white border-b-2 border-r-2 border-gray-200 sm:px-4 sm:py-4 md:px-4 sm:rounded-lg sm:shadow-sm md:w-2/3">
            <div className="flex flex-row md-10">
                {message.name.portfolio ? (
                    <Link to={`${message.name.portfolio}/photos`}>
                        <img className="w-12 h-12 rounded-full ml-2" alt="Anonymous's avatar" src={message.photo} />
                    </Link>
                ) : (
                    <img className="w-12 h-12 rounded-full ml-2" alt="Anonymous's avatar" src={message.photo} />
                )}
                <div className="flex-col mt-1">
                    <div className="flex items-center px-4 font-bold leading-tight text-sans flex-wrap gap-2">
                        {message.name.portfolio ? (
                            <Link to={`${message.name.portfolio}/photos`}>
                                {message.name.publicName}
                            </Link>
                        ) : (
                            message.name.publicName
                        )}
                        <span className="font-normal text-sm text-gray-500">
                            {DateDiffBetweenTwo(moment(message.createdAt), moment())}
                        </span>
                    </div>
                    <div className="font-normal text-xs text-gray-500 px-4">
                        {message.name.portfolio && (
                            t("photographer")
                        )}
                    </div>
                    <div className="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600 text-sans flex gap-1">{message.content} - <div className="cursor-pointer" onClick={toggleOpen}>{getMyNote()}</div></div>
                    {message?.qualityPrice && message?.professionalism ? (
                        <>
                            <Rating className="ml-4" value={message.pictureQuality} readonly onClick={toggleOpen}  unratedColor="orange" ratedColor="orange" />
                            <Collapse open={open} className="ml-8">
                                <div className="flex items-center gap-2">
                                    <Rating value={message.pictureQuality} unratedColor="gray" ratedColor="gray" readonly />
                                    <Typography color="blue-gray" className="text-sans text-xs font-medium">
                                        {t("picturequality")}
                                    </Typography>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Rating value={message.professionalism} unratedColor="gray" ratedColor="gray" readonly />
                                    <Typography color="blue-gray" className="text-sans text-xs font-medium">
                                        {t("prolevel")}
                                    </Typography>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Rating value={message.qualityPrice} unratedColor="gray" ratedColor="gray" readonly />
                                    <Typography color="blue-gray" className="text-sans text-xs font-medium">
                                        {t("qualityprice")}
                                    </Typography>
                                </div>
                            </Collapse>
                        </>
                    ) : (
                        <Rating className="ml-4 cursor-default" value={message.pictureQuality} readonly unratedColor="orange" ratedColor="orange" />
                    )}
                </div>
            </div>
            {answer ? (
                <>
                    <hr className="my-2 ml-16 border-gray-200" />
                    <div className="flex flex-row pt-1 md-10 md:ml-16">
                        <img className="w-12 h-12 border-2 border-gray-300 rounded-full ml-2" alt="Emily's avatar" src={portfolio.picture ?? DefaultPicture} />
                        <div className="flex-col mt-1">
                            <div className="flex-1 px-2 ml-2 text-sm font-medium leading-loose text-gray-600">{answer.content}</div>
                        </div>
                    </div>
                </>
            ) : portfolio.id === myId && (
                <>
                    <hr className="my-2 ml-16 border-gray-200" />
                    <div className="flex flex-col pt-1 md-10 md:ml-16">
                        <Input
                            type="text"
                            color="orange"
                            value={answering}
                            label={t("answer")}
                            onChange={(e) => setAnswering(e.target.value)}
                        />
                        <button type="button" className="flex w-fit justify-center px-4 py-2 border border-orange-500 mt-2 rounded transition-all text-orange-500 hover:bg-orange-500 hover:text-white" onClick={handleSubmitAnswer}>
                            <Typography className="font-medium">
                                {t("answering")}
                            </Typography>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
