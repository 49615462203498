import { useSelector } from "react-redux";
import { PictureResult } from "../../../components/SearchResult/impl/PictureResult";
import { RootState } from "../../../services/redux/store";

export default function Photos() {
    const portfolio = useSelector((state: RootState) => state.form);

    return (
        <div className="mx-auto px-2 md:px-4 py-6">
            <PictureResult
                overrides={{
                    pagetype: "portfolio",
                    portfolio: portfolio.id,
                }}
            />
        </div>
    );
}
