import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class PhoneVerficiationAPI {
    public static async getPhone(data: {}) {
        return APIClient.ezoom.post(`/phone-verifications`, data)
    }

    public static async verifPhone(uuid: string, body: { verificationCode: string }) {
        if (token)
            return APIClient.ezoom.put(`/phone-verifications/${uuid}`, body)
        else
            return APIClient.noInterceptor.put(`/phone-verifications/${uuid}`, body)
    }
}
