import React, { useState, useRef } from 'react';

function OtpInput({ otpValues, setOtpValues } : Readonly<{ otpValues: string[], setOtpValues: Function}>) {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleInputChange = (index: number, value: string) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    if (value) {
      // Move to the next input field if there's a value
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
    } else {
      // Clear the value and stay in the current input field
      setOtpValues(newOtpValues);
      inputRefs[index].current?.focus();
    }

    setOtpValues(newOtpValues);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace') {
      event.preventDefault();

      const newOtpValues = [...otpValues];
      if (!otpValues[index] && index > 0) {
        // Clear the value and move to the previous input field
        newOtpValues[index - 1] = '';
        setOtpValues(newOtpValues);
        inputRefs[index - 1].current?.focus();
      } else {
        // Clear the value and stay in the current input field
        newOtpValues[index] = '';
        setOtpValues(newOtpValues);
        inputRefs[index].current?.focus();
      }
    }
  };

  return (
    <div id="otp" className="flex flex-row justify-center text-center px-2 mt-2 mb-2">
      {otpValues.map((value, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          className="m-2 border h-10 w-10 text-center form-control rounded"
          type="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleInputKeyDown(e, index)}
        />
      ))}
    </div>
  );
}

export default OtpInput;
