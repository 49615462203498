import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import { RootState } from "../../../services/redux/store";
import { handleError } from "../../../services/Errors/handleErrors";

export default function ContactPortfolio() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const portfolio = useSelector((state: RootState) => state.form);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [id, setId] = useState<string>("");

    const handleLinkClick = (url: string) => {
        navigate(url);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!portfolio?.features?.contact) return;
            setIsLoading(true);
            const respPortfolio = await PortfolioAPI.portfolio(portfolio.id);
            if (respPortfolio.status === 200) {
                setId(respPortfolio.body.publicLink ?? "");
                if (respPortfolio.body.path) {
                    if (
                        !portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.path}/inquiry`, { replace: true });
                    } else if (
                        portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.path}/offers`, { replace: true });
                    } else if (
                        !portfolio.features.contact.offer &&
                        portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.path}/appointment`, { replace: true });
                    } else if (
                        !portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.path}/photos?license=OWN`, { replace: true });
                    } else {
                        setIsLoading(false);
                        return;
                    }
                } else {
                    if (
                        !portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.publicLink}/inquiry`, { replace: true });
                    } else if (
                        portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.publicLink}/offers`, { replace: true });
                    } else if (
                        !portfolio.features.contact.offer &&
                        portfolio.features.contact.appointment &&
                        !portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.publicLink}/appointment`, { replace: true });
                    } else if (
                        !portfolio.features.contact.offer &&
                        !portfolio.features.contact.appointment &&
                        portfolio.features.contact.buy &&
                        !portfolio.features.contact.contact
                    ) {
                        navigate(`/${respPortfolio.body.publicLink}/photos?license=OWN`, { replace: true });
                    } else {
                        setIsLoading(false);
                        return;
                    }
                }
            } else {
                handleError(respPortfolio);
            }
        };
        fetchData();
    }, [portfolio]);

    if (isLoading)
        return (
            <>
                <Spinner color="orange" className="mt-6 mx-auto w-7 h-7" />
            </>
        );

    return (
        <div className="mx-auto px-2 md:px-4 py-2 md:py-6 flex flex-col md:flex-row flex-wrap max-w-6xl">
            {portfolio?.features?.contact?.offer && (
                <button
                    className="flex flex-col justify-left items-center px-4 mt-4 mb-4 w-full md:w-1/2 grow"
                    onClick={() => handleLinkClick(id.startsWith("/portfolio") ? `${id}/offers` : `/portfolio/${portfolio.id}/offers`)}
                >
                    <div className="flex flex-row w-full p-4 rounded-lg bg-white hover:bg-gray-400/20 items-center grow text-left">
                        <FontAwesomeIcon icon="handshake" className="text-3xl text-gray-500/50 ml-4 mr-8 w-12" />
                        <div className="flex flex-col gap-2">
                            <Typography className="text-lg font-bold">{t("askdeal")}</Typography>
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: t("askdeal_d", { name: portfolio?.name ?? "" }) }}></p>
                        </div>
                    </div>
                </button>
            )}
            {portfolio?.features?.contact?.appointment && (
                <button
                    className="flex flex-col justify-left items-center px-4 mt-4 mb-4 w-full md:w-1/2 grow"
                    onClick={() => handleLinkClick(id.startsWith("/portfolio") ? `${id}/appointment` : `/portfolio/${portfolio.id}/appointment`)}
                >
                    <div className="flex flex-row w-full p-4 rounded-lg bg-white hover:bg-gray-400/20 items-center grow text-left">
                        <FontAwesomeIcon icon="cart-shopping" className="text-3xl text-gray-500/50 ml-4 mr-8 w-12" />
                        <div className="flex flex-col gap-2">
                            <Typography className="text-lg font-bold">{t("takerdv")}</Typography>
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: t("takerdv_d", { name: portfolio?.name ?? "" }) }}></p>
                        </div>
                    </div>
                </button>
            )}
            {portfolio?.features?.contact?.buy && (
                <button
                    className="flex flex-col justify-left items-center px-4 mt-4 mb-4 w-full md:w-1/2 grow"
                    onClick={() => handleLinkClick(id.startsWith("/portfolio") ? `${id}/photos?license=OWN` : `/portfolio/${portfolio.id}/photos?license=OWN`)}
                >
                    <div className="flex flex-row w-full p-4 rounded-lg bg-white hover:bg-gray-400/20 items-center grow text-left">
                        <FontAwesomeIcon icon="calendar-check" className="text-3xl text-gray-500/50 ml-4 mr-8 w-12" />
                        <div className="flex flex-col gap-2">
                            <Typography className="text-lg font-bold">{t("buypicture")}</Typography>
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: t("buypicture_d", { name: portfolio?.name ?? "" }) }}></p>
                        </div>
                    </div>
                </button>
            )}
            {portfolio?.features?.contact?.contact && (
                <button
                    className="flex flex-col justify-left items-center px-4 mt-4 mb-4 w-full md:w-1/2 grow"
                    onClick={() => handleLinkClick(id.startsWith("/portfolio") ? `${id}/inquiry` : `/portfolio/${portfolio.id}/inquiry`)}
                >
                    <div className="flex flex-row w-full p-4 rounded-lg bg-white hover:bg-gray-400/20 items-center grow text-left">
                        <FontAwesomeIcon icon="square-poll-horizontal" className="text-3xl text-gray-500/50 ml-4 mr-8 w-12" />
                        <div className="flex flex-col gap-2">
                            <Typography className="text-lg font-bold">{t("inquire")}</Typography>
                            <p className="text-sm" dangerouslySetInnerHTML={{ __html: t("inquire_d", { name: portfolio?.name ?? "" }) }}></p>
                        </div>
                    </div>
                </button>
            )}
        </div>
    );
}
