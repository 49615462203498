import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class ContactsAPI {
    public static async contactMessages() {
        return APIClient.ezoom.get(`/contact-messages`);
    }

    public static async contactMessage(id: string) {
        return APIClient.ezoom.get(`/contact-messages/${id}`);
    }

    public static async newMessage(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/contact-message`, data);
        else
            return APIClient.ezoom.post(`/public/contact-message`, data);
    }

    public static async contactServices() {
        return APIClient.ezoom.get(`/contact-services`);
    }

    public static async contactService(id: string) {
        return APIClient.ezoom.get(`/contact-services/${id}`);
    }

    public static async newService(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/contact-service`, data);
        else
            return APIClient.ezoom.post(`/public/contact-service`, data);
    }

    public static async newSupport(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/contact-support`, data);
        else
            return APIClient.ezoom.post(`/public/contact-support`, data);
    }
}
