import { useTranslation } from "react-i18next";

import Select from "react-select";
import Footer from "../../components/Footer/Footer";

import { Textarea } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CardUserForm from "../../components/Box/CardUserForm";
import { PurpleCustomStyles } from "../../components/CustomStyles/PurpleCustomStyle";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { Storage } from "../../services/storage";
import ContactsAPI from "../../services/API/Clients/ContactAPI";
import { toast } from "react-toastify";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { handleError } from "../../services/Errors/handleErrors";

const listSubject = [
    {
        value: "info",
        label: "Besoin d'un renseignement",
    },
    {
        value: "commercial",
        label: "Contact commercial",
    },
    {
        value: "bug",
        label: "Signaler un bug",
    },
    {
        value: "other",
        label: "Autre",
    },
];

export default function Contact() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("message")}`;
    const [hidden, setHidden] = React.useState(false);
    const [searchBar, setSearchBar] = React.useState(false);
    const general = useSelector((state: any) => state.general);
    const [myId, setMyId] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<{
        company?: string;
        firstname: string;
        lastname: string;
        country?: string;
        place?: string;
        phone?: string;
        email?: string;
        plan?: string;
    }>({
        company: "",
        firstname: "",
        lastname: "",
        country: "",
        place: "",
        phone: "",
        email: "",
    });
    const [dataForm, setDataForm] = React.useState<{
        object: string;
        message: string;
    }>({
        object: "",
        message: "",
    });

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    })

    const fetchMyID = async () => {
        const iHaveToken = Storage.getToken();
        if (iHaveToken) {
            if (!general || !general.me) return;
            setMyId(general.me.portfolio.id);
            setData({
                firstname: general.me.firstname,
                lastname: general.me.lastname,
                email: general.me.email,
                plan: general.me.plan,
            });
            if (general.me.company) setData({ ...data, company: general.me.company });
            if (general.me.country) setData({ ...data, country: general.me.country });
            if (general.me.place) setData({ ...data, place: general.me.place });
            if (general.me.phone) setData({ ...data, phone: general.me.phone });
        }
    };

    useEffect(() => {
        fetchMyID();
    }, []);

    const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeDataPhone = (value: string) => {
        setData({ ...data, phone: value });
    };

    const handleSubmit = async () => {
        const iHaveToken = Storage.getToken();

        if (isLoading) return;
        setIsLoading(true);

        if (dataForm.message === "" || dataForm.message === undefined) {
            toast.warning(t("missingDataMessage"));
        } else if (dataForm.object === "" || dataForm.object === undefined) {
            toast.warning(t("missingDataSubject"));
        } else if (!general?.me && (data.firstname === "" || data.firstname === undefined)) {
            toast.warning(t("missingFirstname"));
        } else if (!general?.me && (data.lastname === "" || data.lastname === undefined)) {
            toast.warning(t("missingLastname"));
        } else if (!general?.me && (data.email === "" || data.email === undefined)) {
            toast.warning(t("missingEmail"));
        } else {
            const datas: {
                sender?: {
                    company?: string;
                    firstname?: string;
                    lastname?: string;
                    country?: string;
                    place?: string;
                    phone?: string;
                    email?: string;
                };
                message: string;
                subject: string;
            } = {
                message: dataForm.message,
                subject: dataForm.object,
                sender: {},
            };
            if (!iHaveToken) {
                if (!data.firstname || data.firstname === "") {
                    toast.warning(t("missingDataFirstname"));
                    return;
                }
                if (!data.lastname || data.lastname === "") {
                    toast.warning(t("missingDataLastname"));
                    return;
                }
                if (!data.email || data.email === "") {
                    toast.warning(t("missingDataEmail"));
                    return;
                }
                datas.sender = {
                    ...(data.company && { company: data.company }),
                    firstname: data.firstname,
                    lastname: data.lastname,
                    ...(data.country && { country: `/api/public/countries/${data.country}` }),
                    ...(data.place && { place: `/api/public/places/${data.place}` }),
                    ...(data.phone && { phone: data.phone }),
                    email: data.email,
                };
            }
            const response = await ContactsAPI.newSupport(datas);
            if (response.status === 200 || response.status === 201) {
                toast.success(t("messagesend_success"));
                setDataForm({
                    object: "",
                    message: "",
                });
            } else handleError(response);
        }
        setIsLoading(false);
    };

    return (
        <div className="relative min-h-full">
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div className="flex flex-col h-full bg-white -mt-4  pb-[340px] md:pb-36 min-h-screen">
                <motion.div
                        variants={{
                            visible: { top: "46px", paddingTop: "12px" },
                            hidden: { top: "0px", paddingTop: "4px" },
                            barsInactive: { top: "46px", paddingTop: "12px" },
                            barsActive: { top: "93px", paddingTop: "8px" }
                        }}
                        animate={hidden ? searchBar ? "barsInactive" : "hidden" : searchBar ? "barsActive" : "visible" }
                        transition={{ 
                            duration: 0.35,
                            ease: "easeInOut"
                        }}
                        className="flex flex-row md:flex-col items-end justify-between w-full sticky top-0 pb-2 md:static bg-white z-30 border-b border-gray-200">
                    <TitlePage mt={0} mb={0}>
                        {t("contact")}
                    </TitlePage>
                </motion.div>
                <WrapperGrayZone pt="0" px="8">
                    <div className="mx-auto py-6 min-h-[50vh]">
                        <div className="-m-1 flex flex-wrap md:-m-2">
                            <div className="w-full md:w-2/3 mx-auto">
                                <div className="flex flex-col lg:flex-row gap-4 items-start">
                                    <CardUserForm myId={myId} data={data} handleChangeData={handleChangeData} handleChangeDataPhone={handleChangeDataPhone} />
                                    <div className="bg-gray-200 p-4 rounded-lg w-full max-h-96  h-fit overflow-y-scroll text-justify flex flex-col gap-4">
                                        <Select
                                            placeholder="Sujet du message"
                                            name="Sujet du message"
                                            className="w-full relative top-0 bg-white"
                                            options={listSubject}
                                            styles={PurpleCustomStyles}
                                            menuPortalTarget={document.body}
                                            onChange={(e) => setDataForm({ ...dataForm, object: e?.value ?? "" })}
                                            value={listSubject.find((e) => e.value === dataForm.object)
                                                ? listSubject.find((e) => e.value === dataForm.object)
                                                : null
                                            }
                                            menuPosition="fixed"
                                        />
                                        <Textarea
                                            color="purple"
                                            label="Contenu du message"
                                            className="bg-white min-w-[72px]"
                                            value={dataForm.message}
                                            onChange={(e) => setDataForm({ ...dataForm, message: e.target.value })}
                                        />
                                        <button onClick={handleSubmit} className="w-full">
                                            <GradientButton text={t("send")} loading={isLoading} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
            <Footer />
        </div>
    );
}
