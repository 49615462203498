import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer/Footer";
import { GalleryInterface } from "../../components/Interfaces/GalleryInterface";
import { SearchPicturesType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import ModalOrganize from "../../components/Modals/Organize/ModalOrganize";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import { PictureResult } from "../../components/SearchResult/impl/PictureResult";
import GalleryAPI from "../../services/API/Clients/GalleryAPI";
import MobileGalleryHeader from "./components/Mobile/MobileGalleryHeader";
import EditHeaders from "./components/Web/EditHeader";
import { handleError } from "../../services/Errors/handleErrors";

export default function Edit() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("galleryedit")}`;
    const { hashid } = useParams();
    const navigate = useNavigate();
    const [searchBar, setSearchBar] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [tmpNewBanner, setTmpNewBanner] = React.useState({ id: "", minPath: "" });
    const [openModal, setOpenModal] = React.useState(false);
    const [data, setData] = React.useState<GalleryInterface>({
        id: "",
        title: "",
        createdAt: "",
        banner: {
            publicPath: "",
        },
        bannerOffset: 0,
        favoriteCount: 0,
        viewCount: 0,
        pictureLength: 0,
        portfolio: {
            id: "",
            name: "",
            path: "",
            picture: "",
            publicLink: "",
        },
        private: false,
        published: false,
        isFavorited: false,
        owner: {
            id: "",
            verified: false,
        },
        date: "",
        place: {
            id: "",
            label: "",
        }
    });
    const [pictures, setPictures] = React.useState<SearchPicturesType>({
        pagination: {
            current: 0,
            last: 0,
            parPage: 0,
            totalItems: 0,
        },
        data: [],
    });

    const onSelected = (value: string, label: string | undefined) => {
        if (label === undefined) return;
        setData({ ...data, place: { id: value, label: label } });
    }

    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = () => setOpenModal(true);

    const handleChangeDate = (date: string) => {
        setData({ ...data, date: date });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "private") {
            if (e.target.value === "nok")
                setData({ ...data, private: false });
            else
                setData({ ...data, private: true });
        } else
            setData({ ...data, [e.target.name]: e.target.value });
    };

    const changeBannerOffset = (value: number) => {
        setData({ ...data, bannerOffset: value });
    }

    const fetchData = async () => {
        if (!hashid) return;
        const [galleryResponse, picturesResponse] = await Promise.all([
            GalleryAPI.getGallerie(hashid),
            GalleryAPI.getGalleriePictures(hashid)
        ]);
        if (galleryResponse.status === 200 || galleryResponse.status === 201)
            setData(galleryResponse.body);
        else
            handleError(galleryResponse);
        if (picturesResponse.status === 200 || picturesResponse.status === 201)
            setPictures(picturesResponse.body);
        else
            handleError(picturesResponse);
        setLoading(false)
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleSave = async () => {
        if (!hashid) return;


        const datas: { title: string, banner?: string, bannerOffset: number, private: boolean } = {
            title: data.title,
            ...(tmpNewBanner.minPath !== "" && tmpNewBanner.id !== "" && { banner: `/api/public/pictures/${tmpNewBanner.id}` }),
            bannerOffset: Number(data.bannerOffset),
            private: data.private,
            ...(data.date && { date: data.date }),
            ...(data?.place?.id && { place: `/api/public/places/${data.place.id}` }),
        }
        const res = await GalleryAPI.putGallerie(hashid, datas);
        if (res.status === 200 || res.status === 201) {
            toast.success(t("saved"))
            navigate(`/gallery/${hashid}`)
        } else {
            handleError(res);
        }
    }

    const handleSavePublish = async () => {
        if (!hashid) return;

        if (data.title === "") {
            toast.warn(t("gallerytitleempty"))
            return;
        }
        if (tmpNewBanner.minPath === "" && tmpNewBanner.id === "" && data.banner.publicPath === "") {
            toast.warn(t("gallerybannerempty"))
            return;
        }

        if (pictures.pagination.totalItems === 0) {
            toast.warn(t("gallerypicturesempty"))
            return;
        }
        
        const datas: { title: string, banner?: string, bannerOffset: number, private: boolean } = {
            title: data.title,
            ...(tmpNewBanner.minPath !== "" && tmpNewBanner.id !== "" && { banner: `/api/public/pictures/${tmpNewBanner.id}` }),
            bannerOffset: Number(data.bannerOffset),
            private: data.private,
            ...(data.date && { date: data.date }),
            ...(data?.place?.id && { place: `/api/public/places/${data.place.id}` }),
        }
        const res = await GalleryAPI.putGallerie(hashid, datas);
        if (res.status === 200 || res.status === 201) {
            toast.success(t("saved"))
            const rest = await GalleryAPI.publish(hashid);
            if (rest.status === 200 || rest.status === 201) {
                toast.success(t("published"))
                navigate(`/gallery/${hashid}`)
            } else {
                handleError(rest);
            }
        } else {
            handleError(res);
        }
    }

    return (
        <>
            {hashid && <ModalOrganize
                open={openModal}
                onClose={handleCloseModal}
                defaultGallery={{
                    id: hashid,
                    title: data.title,
                    bannerOffset: data.bannerOffset,
                }}
            />}
            <div className="min-h-full relative">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="flex flex-col h-full -mt-3 pb-[340px] md:pb-36">
                    <EditHeaders tmpNewBanner={tmpNewBanner} setTmpNewBanner={setTmpNewBanner} picturesLength={pictures.pagination.totalItems} hashid={hashid} data={data} handleChange={handleChange} handleSave={handleSave} handleSavePublish={handleSavePublish} changeBannerOffset={changeBannerOffset} handleChangeDate={handleChangeDate} onSelected={onSelected} />
                    <MobileGalleryHeader searchBar={searchBar} tmpNewBanner={tmpNewBanner} setTmpNewBanner={setTmpNewBanner} picturesLength={pictures.pagination.totalItems} hashid={hashid} data={data} handleChange={handleChange} handleSave={handleSave} handleSavePublish={handleSavePublish} changeBannerOffset={changeBannerOffset} handleChangeDate={handleChangeDate} onSelected={onSelected} />
                    <div className="hidden mf:flex border-b border-gray-200 w-full sticky md:static top-0 bg-white z-30 mb-6"></div>
                    <div className="flex-grow w-full bg-gray-50 rounded-b-lg md:rounded-b-none md:rounded-r-lg pb-16 px-2 md:px-4 pt-6 md:pt-2">
                        {pictures.pagination.totalItems > 1 && (
                            <button onClick={handleOpenModal} className="hidden md:flex border w-fit hover:text-black py-2 mt-2 mr-2 px-4 rounded-full ml-auto bg-white hover:bg-gray-50 transition-all">{t("organizepictures")}</button>
                        )}
                        <PictureResult
                            galleries={false}
                            overrides={{
                                pagetype: "gallery",
                                gallery: hashid,
                            }}
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
