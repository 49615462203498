export const PurpleCustomStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    control: (baseStyles: any, state: { isFocused: any }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(98, 81, 153)" : "",
        borderColor: state.isFocused ? "rgb(98, 81, 153)" : "rgb(176, 190, 197)",
        backgroundColor: "transparent",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(98, 81, 153)" : "rgb(176, 190, 197)",
        },
            fontSize: "14px"
    }),
    option: (base: any, state: { isSelected: any; isFocused: any }) => ({
        ...base,
        backgroundColor: state.isSelected ? "rgb(98, 81, 153)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            backgroundColor: state.isSelected ? "rgb(98, 81, 153)" : "lightgray",
            color: "black",
        },
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
};
