import { PictureType } from "../../Interfaces/PictureType";
import DefaultPictureProfil from "../../../assets/images/elements/default-profile.png"
import { useNavigate } from "react-router-dom";

export default function Avatar({ portfolio } : Readonly<{ portfolio: { id: string, name: string, picture: PictureType | null }}>) {
    const navigate = useNavigate();

    console.log(portfolio)

    return (
        <a className="mx-2 w-full min-w-[100px]" href={`/portfolio/${portfolio.id}/photos`}>
            <div className="flex flex-row flex-nowrap overflow-hidden items-center py-1 px-1 bg-gray-200 hover:bg-gray-100 transition-all rounded-xl">
                <div className="h-10 w-10 aspect-square rounded-full">
                    <img src={portfolio?.picture?.minPath ?? DefaultPictureProfil} alt={portfolio.name} className="object-cover w-full h-full min-w-[40px] rounded-full" />
                </div>
                <div className="flex flex-col justify-center ml-4 w-full">
                    <p className="text-base font-medium text-gray-900 whitespace-nowrap">{portfolio.name}</p>
                </div>
            </div>
        </a>
    );
}
