import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";

import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, FreeMode, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import PortfolioAPI from "../../services/API/Clients/PortfolioAPI";
import { SearchAutocomplete } from "../Interfaces/PortfolioType";
import Avatar from "../NavBar/composants/Avatar";
import { handleError } from "../../services/Errors/handleErrors";

export default function ModalSearchMobile({ open, setOpen, isPhoto }: Readonly<{ open: boolean; setOpen: (arg: boolean) => void; isPhoto: boolean }>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const [research, setResearch] = useState("");
    const [inputRendered, setInputRendered] = useState(false);

    const [autocomplete, setAutocomplete] = React.useState<{
        data: SearchAutocomplete;
    }>({
        data: {
            portfolios: [],
            suggestions: [],
        },
    });

    useLayoutEffect(() => {
        if (open && inputRef.current && !inputRendered) {
            setTimeout(() => {
                if (!inputRef.current) return;
                inputRef.current.focus();
                setInputRendered(true);
            }, 0);
        }
    }, [open, inputRendered]);

    useEffect(() => {
        const fetchAutocomplete = async () => {
            if (isPhoto) {
                if (research.length === 0) {
                    setAutocomplete({ data: { portfolios: [], suggestions: [] } });
                    return;
                }
                const response = await PicturesAPI.autocomplete(research);
                if (response.status === 200) {
                    setAutocomplete({ data: response.body });
                } else {
                    handleError(response);
                }
            }

            if (!isPhoto) {
                if (research.length === 0) {
                    setAutocomplete({ data: { portfolios: [], suggestions: [] } });
                    return;
                }
                const response = await PortfolioAPI.autocomplete(research);
                if (response.status === 200) {
                    setAutocomplete({ data: response.body });
                } else {
                    handleError(response);
                }
            }
        };
        fetchAutocomplete();
    }, [research]);

    const setSearch = (text: string) => {
        setResearch(text);
    };

    const doSearch = (text: string) => {
        if (isPhoto) {
            if (text.length === 0) {
                navigate("/search");
            } else {
                navigate(`/search?query=${text}`);
            }
        } else {
            if (text.length === 0) {
                navigate("/photographers");
            } else {
                navigate(`/photographers?query=${text}`);
            }
        }
    };

    const onSearch = (text: string) => {
        setResearch(text);
        doSearch(text);
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen} padding={false}>
            <Dialog.Panel className="md:hidden transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all w-screen h-screen">
                <div className="flex gap-2 items-center p-2 ">
                    <FontAwesomeIcon
                        icon="arrow-left"
                        onClick={() => setOpen(false)}
                        className="cursor-pointer text-black hover:text-gray-600 transition-all"
                    />
                    <button
                        className={`relative transition-all ease-in-out left-0 bg-white h-10 border-gray-200 border ${
                            isPhoto ? "border-orange-500" : "border-purple-500"
                        } inline-block w-full rounded-[20px]`}
                    >
                        <div className="h-10 px-2 pr-8 vertical-middle flex items-center" style={{ width: "calc(100% - 17px)" }}>
                            <FontAwesomeIcon icon="search" className={`-mt-1 ${isPhoto ? "text-orange-500" : "text-purple-500"}`} />
                            <input
                                ref={inputRef}
                                onChange={(e) => setSearch(e.target.value)}
                                value={research}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        doSearch(research);
                                    }
                                }}
                                className="mx-auto -mt-1 text-sm text-grey-400 focus:border-0 focus:ring-0 focus:outline-none"
                                placeholder={isPhoto ? t("searchExPhotos") : t("searchExPhotograph")}
                                style={{ width: "calc(100% - 30px)" }}
                            />
                            {research.length > 0 && (
                                <div>
                                    <button
                                        className={`flex absolute top-1/2 -translate-y-1/2 right-8 rounded-full items-center justify-center w-7 h-7`}
                                        onClick={() => {
                                            setResearch("");
                                        }}
                                    >
                                        <FontAwesomeIcon icon="x" className="text-gray-500 text-xs" />
                                    </button>
                                    <button
                                        className={`flex absolute top-1/2 -translate-y-1/2 right-2 rounded-full items-center justify-center w-7 h-7 ${
                                            isPhoto ? "bg-orange-500" : "bg-purple-500"
                                        }`}
                                        onClick={() => {
                                            if (isPhoto) navigate(`/search?query=${research}`);
                                            else navigate(`/photographers?query=${research}`);
                                        }}
                                    >
                                        <FontAwesomeIcon icon="magnifying-glass" className="text-white text-sm" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </button>
                </div>
                {autocomplete.data.suggestions.length > 0 && (
                    <>
                        <div className="block text-base w-full bg-gray-400 text-white font-comfortaa px-2">{t("suggestions")}</div>
                        <div className="block text-base w-full">
                            {autocomplete.data.suggestions.map((search) => (
                                <button className="block py-2 hover:bg-gray-100 w-full text-left px-4" onClick={(e) => onSearch(search)}>
                                    {search}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                {autocomplete.data.portfolios.length > 0 && (
                    <>
                        <div className="block text-base w-full bg-gray-400 text-white font-comfortaa px-2 mb-2">{t("portfolios")}</div>
                        <div className="flex flex-row gap-4 w-full px-4 overflow-hidden">
                            <Swiper
                                modules={[FreeMode, Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={10}
                                freeMode={true}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    884: {
                                        slidesPerView: 2,
                                    },
                                    1250: {
                                        slidesPerView: 3,
                                    },
                                    1732: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                centeredSlides={autocomplete.data.portfolios.length === 1}
                                className="w-full"
                            >
                                {autocomplete.data.portfolios.map((portfolio) => (
                                    <SwiperSlide key={portfolio.id} className="pb-2">
                                        <Avatar key={portfolio.id} portfolio={portfolio} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                )}
            </Dialog.Panel>
        </ModalWrapper>
    );
}
