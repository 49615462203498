import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Button } from "@material-tailwind/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface Item {
    key: string
    value: string
}

interface BooleanFilterProps {
    title: string
    value: true | undefined
    onChange: (value: true | undefined) => void
}

export default function BooleanFilter({ title, value, onChange }: Readonly<BooleanFilterProps>) {
    const { t } = useTranslation();
    return (
        <Menu>
            <div>
                <Menu.Button
                    className={`${value ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""} border flex px-4 py-1 rounded-full flex-row items-center gap-2 inline-flex bg-white hover:bg-gray-50 transition-all`}
                    onClick={() => onChange(value ? undefined : true)}
                >
                    {t(title)}
                </Menu.Button>
            </div>
        </Menu>
    );
}
