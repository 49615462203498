import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem, Popover, PopoverContent, PopoverHandler, Textarea, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateDescription, updatePhotoStyle } from "../../../services/redux/actions/formAction";
import PhotoStyleAPI from "../../../services/API/Clients/PhotoStyleAPI";
import Regions from "./AboutEdit/Regions";
import Langs from "./AboutEdit/Langs";
import Material from "./AboutEdit/Material";
import { PhotoStyle } from "../../../components/Interfaces/PortfolioType";
import SocialNetwork from "./AboutEdit/SocialNetwork";
import moment from "moment";
import { handleError } from "../../../services/Errors/handleErrors";

export default function AboutEdit() {
    const { t } = useTranslation();
    const portfolio = useSelector((state: any) => state.form);
    const myLang = localStorage.getItem("lang") ?? "fr";
    const dispatch = useDispatch();

    const handleDescriptionChange = (event: any) => {
        dispatch(updateDescription(event.target.value));
    };

    return (
        <div className="mx-auto px-0 md:px-4 py-2 md:py-6 flex flex-col md:flex-row max-w-6xl">
            <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-4 mt-4 mb-4">
                <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100">
                    <Typography className={`text-lg font-bold mb-2`}>{t("caption")}</Typography>
                    <Textarea
                        value={portfolio.description}
                        onChange={handleDescriptionChange}
                        className="bg-white text-base text-gray-500"
                        color="orange"
                        label={t("caption")}
                    />
                </div>
                <PhotoStyleSelector />
                <SocialNetwork />
            </div>
            <div className="flex flex-col justify-left items-center w-full md:w-1/2 px-4 mt-4 mb-4">
                <Regions myLang={myLang} />
                <Langs />
                <Material />
                <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
                    <Typography className="text-base text-gray-500 italic ">{t("signupdate", { date: moment(portfolio.createdAt).format("DD.MM.YYYY") })}</Typography>
                </div>
            </div>
        </div>
    );
}

function PhotoStyleSelector() {
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const [photoStyles, setPhotoStyles] = React.useState<PhotoStyle[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        const getData = async () => {
            const resp = await PhotoStyleAPI.styles();
            if (resp.status === 200) {
                setPhotoStyles(resp.body);
            } else {
                handleError(resp);
            }
        };
        getData();
    }, []);

    const handleStyleClick = (style: PhotoStyle) => {
        const newStyles = [...portfolio.photoStyles];
        newStyles.push(style);
        dispatch(updatePhotoStyle(newStyles));
    };

    const handleStyleRemove = (styleToRemove: PhotoStyle) => {
        const newStyles = [...portfolio.photoStyles];
        const index = newStyles.indexOf(styleToRemove);
        if (index > -1) {
            newStyles.splice(index, 1);
            dispatch(updatePhotoStyle(newStyles));
        }
    };

    const filteredPhotoStyles = photoStyles.filter((style) => !portfolio.photoStyles.includes(style));

    return (
        <div className="flex flex-col w-full p-4 rounded-lg bg-gray-100 mt-4">
            <Typography className={`text-lg font-bold`}>{t("mystyles")}</Typography>
            <div className="flex flex-row flex-wrap items-center mt-2 gap-2">
                <Popover placement="bottom-start">
                    <PopoverHandler>
                        <FontAwesomeIcon icon="circle-plus" className="text-gray-500 cursor-pointer" />
                    </PopoverHandler>
                    <PopoverContent className="p-1 overflow-y-auto z-50 h-44">
                        <List className="p-1">
                            {filteredPhotoStyles.map((style) => (
                                <ListItem key={style.enumValue} onClick={() => handleStyleClick(style)}>
                                    {style.frLabel}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverContent>
                </Popover>
                {portfolio.photoStyles.map((style: { enumValue: string; frLabel: string; enLabel: string }) => (
                    <div
                        key={style.enumValue}
                        className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1  bg-orange-200/30 text-orange-700 rounded-full"
                    >
                        {style.frLabel}
                        <FontAwesomeIcon icon="circle-xmark" className="ml-2 text-xs cursor-pointer" onClick={() => handleStyleRemove(style)} />
                    </div>
                ))}
            </div>
        </div>
    );
}
