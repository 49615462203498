// import { toast } from 'react-toastify';
// import i18next from 'i18next';

// interface ResponseBody {
//     detail?: string;
// }

// interface Response {
//     status?: number;
//     body: ResponseBody;
// }

// export const handleError = (response: Response) => {
//     console.log(response)
//     if (response?.status) {
//         switch (response.status) {
//             case 0: 
//                 toast.error("Problème de connexion internet");
//                 break;
//             case 400:
//             case 429:
//                 toast.warning(i18next.t(response.body.detail ?? ""));
//                 break;
//             case 401:
//             case 403:
//                 toast.error("Vous n'avez pas la permission de faire ceci");
//                 break;
//             case 404:
//                 toast.error("Nous n'arrivons pas à accéder à certaines informations");
//                 break;
//             case 500:
//             default:
//                 toast.error("Une erreur est survenue, veuillez réessayer ultérieurement");
//                 break;
//         }
//     } else {
//         toast.error("Une erreur est survenue, veuillez réessayer ultérieurement");
//     }
// };

import { toast } from "react-toastify";
import i18next from "i18next";

interface Response {
    status?: number;
    body?: {
        detail?: string;
    };
}

export const handleError = (response: Response) => {
    if (response?.status) {
        switch (response.status) {
            case 1: 
                toast.error("Problème de connexion internet");
                break;
            case 400:
            case 429:
            case 409:
                toast.warning(i18next.t(response?.body?.detail ?? ""));
                break;
            case 401:
            case 403:
                toast.error("Vous n'avez pas la permission de faire ceci");
                break;
            case 404:
                toast.error("Nous n'arrivons pas à accéder à certaines informations");
                break;
            case 500:
            default:
                toast.error("Une erreur est survenue, veuillez réessayer ultérieurement");
                break;
        }
    } else {
        toast.error("Une erreur est survenue, veuillez réessayer ultérieurement");
    }
};
