import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, IconButton, Input } from "@material-tailwind/react";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import Auth from "../../services/API/Clients/AuthAPI";

import { appleAuthHelpers } from "react-apple-signin-auth";
import { Link, useNavigate } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import { PictureType } from "../../components/Interfaces/PictureType";
import Loader from "../../components/Loader";
import Modal2fa from "../../components/Modals/Modal2fa";
import ModalOauth from "../../components/Modals/ModalOauth";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import { Storage } from "../../services/storage";
import Footer from "../../components/Footer/Footer";
import { handleError } from "../../services/Errors/handleErrors";
import NewsletterAPI from "../../services/API/Clients/NewsletterAPI";

interface UserData {
    email: string;
    firstname: string;
    username: string;
    lastname: string;
    plainPassword: string;
    birthdate: string;
    company?: string;
    enableNewsletter: boolean;
}

function ColorPhoto(pathname: string) {
    if (pathname === "particulier") return "text-white bg-orange-400 border-transparent";
    return pathname === "entreprise"
        ? "bg-white text-gray-500 border-gray-400 border-r-transparent hover:text-orange-500 hover:border-orange-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500 hover:z-10";
}

function ColorGrapher(pathname: string) {
    if (pathname === "entreprise") return "text-white bg-orange-400 border-transparent";
    return pathname === "particulier"
        ? "bg-white text-gray-500 border-gray-400 border-l-transparent hover:text-orange-500 hover:border-orange-500"
        : "bg-white text-gray-500 border-gray-400 hover:text-orange-500 hover:border-orange-500";
}

export default function Signup() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    document.title = `ezoom | ${t("signup")}`;
    const [searchBar, setSearchBar] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [onglet, setOnglet] = React.useState("particulier");
    const [user, setUser] = useState<{
        society: string;
        firstname: string;
        lastname: string;
        birthdate: string;
        mail: string;
        password: string;
        repassword: string;
    }>({
        society: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        mail: "",
        password: "",
        repassword: "",
    });
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [acceptCom, setAcceptCom] = useState(false);
    const [loadingBg, setLoadingBg] = useState(true);
    const [token, setToken] = useState("");
    const [open, setOpen] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);
    const [need2fa, setNeed2fa] = useState(false);
    const [dataTokens, setDataTokens] = useState<{ token: string; refresh_token: string; id: string }>({
        token: "",
        refresh_token: "",
        id: "",
    });
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);
    
    const query = window.location.search;

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body.picture);
                setLoading(false);
            } else {
                handleError(response);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckBox = () => {
        setAcceptConditions(!acceptConditions);
    };

    const handleCheckBoxCom = () => {
        setAcceptCom(!acceptCom);
    };

    const checkSignup = () => {
        if (onglet === "entreprise" && user.society === "") {
            toast.warning(t("signupSociety"));
            return false;
        }
        if (user.firstname === "") {
            toast.warning(t("signupFirstname"));
            return false;
        }
        if (/^[a-zA-ZÀ-ÿ\s-]+$/u.test(user.firstname) === false) {
            toast.warning(t("signupFirstnameLetters"));
            return false;
        }
        if (user.lastname === "") {
            toast.warning(t("signupLastname"));
            return false;
        }
        if (/^[a-zA-ZÀ-ÿ\s-]+$/u.test(user.lastname) === false) {
            toast.warning(t("signupLastnameLetters"));
            return false;
        }
        if (user.birthdate === "") {
            toast.warning(t("signupBirthdate"));
            return false;
        }
        if (/^\d{4}-\d{2}-\d{2}$/.test(user.birthdate) === false) {
            toast.warning(t("signupBirthdateInvalid"));
            return false;
        }
        if (user.mail === "") {
            toast.warning(t("signupMail"));
            return false;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(user.mail)) {
            toast.warning(t("signupEmailInvalid"));
            return false;
        }
        if (user.password === "") {
            toast.warning(t("signupPassword"));
            return false;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?~\\/-])[A-Za-z\d\p{L}\p{N}!@#$%^&*()_+{}\[\]:;"'<>,.?~\\/-]{8,}$/u.test(user.password)) {            toast.warning(t("signupPasswordLength"));
            return false;
        }
        if (user.repassword === "") {
            toast.warning(t("signupPasswordConfirm"));
            return false;
        }
        if (user.password !== user.repassword) {
            toast.warning(t("signupPasswordMatch"));
            return false;
        }
        return true;
    };

    const handleSignup = async (e: any) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);

        if (!checkSignup()) {
            setLoading(false);
            return;
        }

        const datas: UserData = {
            email: user.mail,
            firstname: user.firstname,
            username: user.firstname,
            lastname: user.lastname,
            birthdate: user.birthdate,
            plainPassword: user.password,
            enableNewsletter: acceptCom,
        };

        if (onglet === "entreprise") datas.company = user.society;

        const response = await Auth.signup(datas);

        if (response.status === 201) {
            toast.success(t("register_success"));
            if (acceptCom) {
                const dataCom = {
                    email: user.mail,
                    lastname: user.lastname,
                    firstname: user.firstname,
                    ...(onglet !== "particulier" && { company: user.society }),
                };

                await NewsletterAPI.subscribe(dataCom);
            } else {
                navigate("/signin" + query);
                setLoading(false);
            }
        } else {
            handleError(response);
            setLoading(false);
        }
        setLoading(false);
    };

    const isDisabled = !acceptConditions;

    useEffect(() => {
        setLoadingBg(false);
    }, []);

    const handleChange2fa = () => {
        setOpen(!open);
        if (open) {
            setLoading(false);
        }
    };

    const handleOpenComplete = () => {
        setOpenComplete(!openComplete);
    };

    const signinGoogle = async (tokenResponse: any) => {
        const datas = {
            oAuthContext: {
                code: tokenResponse.code,
            }
        };

        const response = await Auth.signinGoogle(datas);
        if (response.status === 200 || response.status === 201) {
            if (response.body.needCompletion) {
                if (response.body?.totpToken) {
                    setToken(response.body.totpToken);
                    setOpenComplete(true);
                    setNeed2fa(true);
                } else {
                    setDataTokens({
                        token: response.body.token,
                        refresh_token: response.body.refresh_token,
                        id: response.body.id,
                    });
                    setNeed2fa(false);
                    setOpenComplete(true);
                }
            } else if (response.body?.totpToken) {
                setToken(response.body.totpToken);
                setOpen(true);
            } else {
                Storage.setToken(response.body.token);
                Storage.setRefreshToken(response.body.refresh_token);
                Storage.setId(response.body.id);
                setLoading(false);
                toast.success(t("signin_success"));
                if (query === "?redirect=premium") {
                    window.location.href = "/subscription/premium";
                } else if (query === "?redirect=pro") {
                    window.location.href = "/subscription/pro";
                } else {
                    window.location.href = "/";
                }
            }
        } else {
            handleError(response);
        }
    };

    const loginGoogle = useGoogleLogin({
        onSuccess: (tokenResponse) => signinGoogle(tokenResponse),
        onError: (error) => toast.error(t("signin_google_error")),
        flow: "auth-code",
        redirect_uri: "postmessage",
    });

    const responseFacebook = async (response: any) => {
        const datas = {
            oAuthContext: {
                code: response.signedRequest,
            }
        };

        const resp = await Auth.signinFacebook(datas)
        if (resp.status === 200 || resp.status === 201) {
            if (resp.body.needCompletion) {
                if (resp.body?.totpToken) {
                    setToken(resp.body.totpToken);
                    setOpenComplete(true);
                    setNeed2fa(true);
                } else {
                    setDataTokens({
                        token: resp.body.token,
                        refresh_token: resp.body.refresh_token,
                        id: resp.body.id,
                    });
                    setNeed2fa(false);
                    setOpenComplete(true);
                }
            } else if (resp.body?.totpToken) {
                setToken(resp.body.totpToken);
                setOpen(true);
            } else {
                Storage.setToken(resp.body.token);
                Storage.setRefreshToken(resp.body.refresh_token);
                Storage.setId(resp.body.id);
                setLoading(false);
                toast.success(t("signin_success"));
                if (query === "?redirect=premium") {
                    window.location.href = "/subscription/premium";
                } else if (query === "?redirect=pro") {
                    window.location.href = "/subscription/pro";
                } else {
                    window.location.href = "/";
                }
            }
        } else {
            handleError(resp);
        }
    };

    const signinApple = async (code: string, env: string) => {
        const datas = {
            oAuthContext: {
                code,
                env
            }
        };

        const response = await Auth.signinApple(datas);
        if (response.status === 200 || response.status === 201) {
            if (response.body.needCompletion) {
                if (response.body?.totpToken) {
                    setToken(response.body.totpToken);
                    setOpenComplete(true);
                    setNeed2fa(true);
                } else {
                    setDataTokens({
                        token: response.body.token,
                        refresh_token: response.body.refresh_token,
                        id: response.body.id,
                    });
                    setNeed2fa(false);
                    setOpenComplete(true);
                }
            } else if (response.body?.totpToken) {
                setToken(response.body.totpToken);
                setOpen(true);
            } else {
                Storage.setToken(response.body.token);
                Storage.setRefreshToken(response.body.refresh_token);
                Storage.setId(response.body.id);
                setLoading(false);
                toast.success(t("signin_success"));
                if (query === "?redirect=premium") {
                    window.location.href = "/subscription/premium";
                } else if (query === "?redirect=pro") {
                    window.location.href = "/subscription/pro";
                } else {
                    window.location.href = "/";
                }
            }
        } else {
            handleError(response);
        }
    };

    const onSignin = async () => {
        await appleAuthHelpers.signIn({
          authOptions: {
            clientId: 'ch.ezoom.auth.client',
            scope: 'email name',
            redirectURI: `https://${window.location.hostname}/auth/callback`,
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          },
          onSuccess: (response: any) => signinApple(response.authorization.code, window.location.hostname),
          onError: (error: any) => toast.error(t("signin_apple_error")),
        });
    }

    return (
        <div className="min-h-full">
            <Loader loading={loadingBg} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <Modal2fa open={open} setOpen={handleChange2fa} token={token} />
            <ModalOauth
                open={openComplete}
                setOpen={handleOpenComplete}
                token={token}
                dataTokens={dataTokens}
                need2fa={need2fa}
                handleChange2fa={handleChange2fa}
            />
            <div className="h-full min-h-screen">
                <div
                    className="hidden md:block h-full"
                    style={{
                        backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`,
                        backgroundAttachment: "fixed",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width: "100vmax",
                        zIndex: -1,
                        position: "fixed",
                        top: 0,
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        pointerEvents: "none",
                        backgroundPositionX: "center",
                    }}
                ></div>
                <div className="relative min-h-screen flex justify-start items-center">
                    <div className="relative w-screen min-h-screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 xl:w-1/3 bg-white rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex flex justify-center flex-col max-w-3xl">
                        <Link to="/" className="absolute top-4 right-4 flex md:hidden">
                            <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                                <FontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                            </IconButton>
                        </Link>
                        <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                        <h1 className="text-3xl mb-6 text-center">{t("signup")}</h1>
                        <form onSubmit={handleSignup}>
                            <div className="flex flex-col w-full gap-4">
                                <div className="rounded-lg mx-auto">
                                    <div className="flex">
                                        <div className="group flex relative">
                                            <button
                                                className={`${ColorPhoto(
                                                    onglet
                                                )} transition-all py-1.5 px-3 cursor-pointer border rounded-l-full text-sm flex items-center justify-end`}
                                                onClick={() => setOnglet("particulier")}
                                            >
                                                Particulier
                                            </button>
                                        </div>
                                        <div className="group flex relative">
                                            <button
                                                className={`${ColorGrapher(
                                                    onglet
                                                )} transition-all py-1.5 px-3 cursor-pointer border rounded-r-full text-sm flex items-center justify-start`}
                                                onClick={() => setOnglet("entreprise")}
                                                style={{ marginLeft: "-1px" }}
                                            >
                                                Entreprise
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {onglet === "entreprise" && (
                                    <Input
                                        name="society"
                                        type="text"
                                        color="orange"
                                        label={t("society")}
                                        className="rounded-md"
                                        onChange={handleChange}
                                        value={user.society}
                                        required
                                    />
                                )}
                                <Input
                                    required
                                    name="firstname"
                                    type="text"
                                    color="orange"
                                    label={t("firstname")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.firstname}
                                />
                                <Input
                                    required
                                    name="lastname"
                                    type="text"
                                    color="orange"
                                    label={t("name")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.lastname}
                                />
                                <Input
                                    required
                                    name="birthdate"
                                    type="date"
                                    color="orange"
                                    label={t("birth")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.birthdate}
                                />
                                <Input
                                    required
                                    name="mail"
                                    type="email"
                                    color="orange"
                                    label={t("mail")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.mail}
                                />
                                <Input
                                    required
                                    name="password"
                                    type="password"
                                    color="orange"
                                    label={t("pwd")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.password}
                                />
                                <Input
                                    required
                                    name="repassword"
                                    type="password"
                                    color="orange"
                                    label={t("rpwd")}
                                    className="rounded-md"
                                    onChange={handleChange}
                                    value={user.repassword}
                                />
                            </div>
                            <div className="flex flex-row justify-start items-center mt-4">
                                <Checkbox id="checkboxCGU" color="orange" onChange={handleCheckBox} required />
                                <label htmlFor="checkboxCGU" className="text-left text-base text-blue-gray-700" dangerouslySetInnerHTML={{ __html: t("agreeCGU")}}></label>
                            </div>
                            <div className="flex flex-row justify-start items-center my-4">
                                <Checkbox id="checkboxNewsletter" color="orange" onChange={handleCheckBoxCom} />
                                <label htmlFor="checkboxNewsletter" className="text-left text-base text-blue-gray-700" dangerouslySetInnerHTML={{ __html: t("agreeCom")}}></label>
                            </div>
                            <div className="w-full mx-auto">
                                <GradientButton text="signup" loading={loading} disabled={isDisabled} />
                            </div>
                        </form>
                        <div className="text-center uppercase my-4 flex mx-auto w-3/4 items-center">
                            <div className="flex-1 border-b border-gray-400 mr-1/4"></div>
                            <span className="text-gray-400 mx-2">{t("or")}</span>
                            <div className="flex-1 border-b border-gray-400 ml-1/4"></div>
                        </div>

                        <button
                            onClick={onSignin}
                            className="border-2 border-black inline w-auto transition-all hover:bg-black hover:text-white py-2 px-4 rounded-full text-black mt-2"
                        >
                            {t("signupap")}
                        </button>
                        <FacebookLogin
                            appId="1217121976359157"
                            fields="name,email,picture"
                            responseType="code"
                            callback={responseFacebook}
                            render={(renderProps) => (
                                <button
                                    onClick={renderProps.onClick}
                                    className="border-2 border-blue-900 inline w-auto transition-all hover:bg-blue-900 hover:text-white py-2 px-4 rounded-full text-blue-900 mt-2"
                                >
                                    {t("signupfb")}
                                </button>
                            )}
                            version="19.0"
                        />
                        <button
                            onClick={() => loginGoogle()}
                            className="border-2 border-gray-900 inline w-auto transition-all hover:bg-gray-900 hover:text-white py-2 px-4 rounded-full text-gray-900 mt-2"
                        >
                            {t("signupgl")}
                        </button>
                        <hr className="border-t border-t-gray-500 shadow-none rounded-lg mt-8 w-3/4 mx-auto" />
                        <p className="text-center text-gray-500 mt-4 flex flex-col gap-2">
                            {t("alreadyhaveaccount")}{" "}
                            <Link to={"/signin" + query} className="text-orange-500 hover:text-orange-200">
                                {t("signin")}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
