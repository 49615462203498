import { Typography } from "@material-tailwind/react";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function PurchaseOrder({
    historyPaymentOrder,
}: Readonly<{
    historyPaymentOrder: {
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {}[];
    };
}>) {
    const { t } = useTranslation();

    return (
        <div className="w-full overflow-x-auto">
            {historyPaymentOrder.data.length > 0 ? (
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 rounded-tl">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("date")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("time")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("payement_method")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("amount")}
                                </Typography>
                            </th>
                            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                <Typography variant="small" color="blue-gray" className="leading-none opacity-90 font-extrabold rounded-tl">
                                    {t("product_period")}
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyPaymentOrder.data.map((item: any, index: number) => (
                            <tr className={`${index % 2 ? "bg-blue-gray-50/75" : "bg-white"}  hover:bg-gray-200 transition-all`} key={item.id}>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(item.createdAt).format("DD.MM.YYYY")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {moment(item.createdAt).format("HH:mm")}
                                    </Typography>
                                </td>
                                <td className="p-4 flex flex-row items-center">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {item.displayText}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        {item.amount} {t("chf")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        Achat d'une image
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <Typography className="text-base text-gray-700 my-4">{t("noPurchase")}</Typography>
                </div>
            )}
        </div>
    );
}
