import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import Loader from "../../../components/Loader";
import Advantages from "./components/Advantages";
import Community from "./components/Community";
import KeepControl from "./components/KeepControl";
import Prices from "./components/Prices";

import FooterTop from "../../../assets/images/home/waves/footer_top.svg";
import HeaderForAnimation from "../../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../../components/NavBar/HeaderMobile";
import Register from "../../Teaser/components/Register";

export default function IamPhotographer() {
        const { t } = useTranslation();
        document.title = `ezoom | ${t("home")}`;
        const [loading, setLoading] = useState(true)
        const [searchBar, setSearchBar] = useState(false);

        useEffect(() => {
            setLoading(false);
        }, [])

        return (
            <div className="relative pb-[360px] md:pb-36">
                <Loader loading={loading} />
                <HeaderForAnimation />
                <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
                <div className="overflow-hidden">
                    <Community />
                    <Advantages />
                    <KeepControl />
                    <Prices />
                    <Register />
                    <div className="mb-0 bg-white">
                        <FooterTop />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }