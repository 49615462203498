import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class ServiceAPI {
    public static async create(data: {}) {
        return APIClient.ezoom.post(`/services`, data)
    }

    public static async publish(id: string) {
        return APIClient.ezoom.put(`/services/${id}/publish`, {})
    }

    public static async getOne(id: string) {
        return APIClient.ezoom.get(`/public/services/${id}`)
    }

    public static async edit(id: string, data: {}) {
        return APIClient.ezoom.put(`/services/${id}`, data)
    }

    public static async services(portfolio: string) {
        if (token)
            return APIClient.ezoom.get(`/public/services?portfolio=${portfolio}`)
        else
            return APIClient.noInterceptor.get(`/public/services?portfolio=${portfolio}`)
    }

    public static async servicesPagination(portfolio: string, number: number) {
        if (token)
            return APIClient.ezoom.get(`/public/services?portfolio=${portfolio}&page=${number}`)
        else
            return APIClient.noInterceptor.get(`/public/services?portfolio=${portfolio}&page=${number}`)
    }

    public static async delete(id: string) {
        return APIClient.ezoom.delete(`/services/${id}`)
    }
}
