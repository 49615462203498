import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Photographer from "./Photographer";

export default function SwiperPhotograph({ galeries } : Readonly<{ galeries: any[] }>) {
    return (
        <div className="w-full h-fit">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {galeries.map((item) => (
                    <SwiperSlide key={`gallery-${item.name}`} className="pb-8 w-full">
                        <Photographer key={`gallery-${item.name}`} data={item} isForSwiper={true} withSlider={false} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}