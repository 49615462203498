import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

export default function LinkGeneric ({ link, title, internLink = false } : { link: string, title: string, internLink?: boolean }) {
    const { t } = useTranslation();
    if (internLink) {
        return (
            <HashLink to={link} className="flex flex-row gap-1 transition-all items-center mt-0.5 hover:text-black">
                <FontAwesomeIcon icon="chevron-right" className="text-orange-500 h-3 w-3" />
                <p className="text-sm text-gray-500 hover:text-black transition-all text-justify">{t(title)}</p>
            </HashLink>
        )
    }

    return (
        <a href={link} className="flex flex-row gap-1 transition-all items-center mt-0.5 hover:text-black">
            <FontAwesomeIcon icon="chevron-right" className="text-orange-500 h-3 w-3" />
            <p className="text-sm text-gray-500 hover:text-black transition-all text-justify">{t(title)}</p>
        </a>
    )
}