import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    XIcon,
} from "react-share";

export default function SharingBox({ loading = false }: Readonly<{ loading?: boolean }>) {
    const shareUrl = window.location.href;

    return (
        <>
            {loading ? (
                <IconButton color="white" className={`ring-1 bg-gray-400/50 animate-pulse ring-gray-500/40 rounded-full transition-all`}>
                    <></>
                </IconButton>
            ) : (
                <>
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton
                                color="white"
                                className="hidden md:block ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-400 rounded-full hover:bg-gray-50"
                            >
                                <FontAwesomeIcon icon="share" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0 z-40">
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <FacebookIcon size={16} round /> Facebook
                                </FacebookShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <TwitterShareButton url={shareUrl} title={"X"} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <XIcon size={16} round /> X
                                </TwitterShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <PinterestShareButton
                                    url={String(window.location)}
                                    media={`${String(window.location)}`}
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <PinterestIcon size={16} round /> Pinterest
                                </PinterestShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <LinkedinIcon size={16} round /> LinkedIn
                                </LinkedinShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <EmailShareButton
                                    url={shareUrl}
                                    subject={"Ezoom"}
                                    body="body"
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <EmailIcon size={16} round /> Mail
                                </EmailShareButton>
                            </div>
                        </PopoverContent>
                    </Popover>
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton
                                color="white"
                                size="sm"
                                className="md:hidden ring-1 ring-gray-400/50 hover:rounded-full transition-all duration-400 rounded-full hover:bg-gray-50"
                            >
                                <FontAwesomeIcon icon="share" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0 z-30">
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <FacebookIcon size={16} round /> Facebook
                                </FacebookShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <TwitterShareButton url={shareUrl} title={"X"} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <XIcon size={16} round /> X
                                </TwitterShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <PinterestShareButton
                                    url={String(window.location)}
                                    media={`${String(window.location)}`}
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <PinterestIcon size={16} round /> Pinterest
                                </PinterestShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button flex gap-2 items-center">
                                    <LinkedinIcon size={16} round /> LinkedIn
                                </LinkedinShareButton>
                            </div>
                            <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">
                                <EmailShareButton
                                    url={shareUrl}
                                    subject={"Ezoom"}
                                    body="body"
                                    className="Demo__some-network__share-button flex gap-2 items-center"
                                >
                                    <EmailIcon size={16} round /> Mail
                                </EmailShareButton>
                            </div>
                        </PopoverContent>
                    </Popover>
                </>
            )}
        </>
    );
}
