import React from "react";
import ModalOrganize from "../../../components/Modals/Organize/ModalOrganize";
import { useSelector } from "react-redux";
import { PictureResult } from "../../../components/SearchResult/impl/PictureResult";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../services/redux/store";

export default function PhotosEdit() {
    const portfolio = useSelector((state: RootState) => state.form);
    const [openModal, setOpenModal] = React.useState(false);

    const { t } = useTranslation();

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <ModalOrganize open={openModal} onClose={handleCloseModal} />
            {portfolio.pictures.pagination.totalItems > 1 && (
                <button onClick={handleOpenModal} className="hidden md:flex border w-fit hover:text-black py-2 mt-2 mr-2 px-4 rounded-full ml-auto bg-white hover:bg-gray-50 transition-all">{t("organizepictures")}</button>
            )}  
            <div className="mx-auto px-2 md:px-8 py-2 md:py-0">
                <PictureResult
                    overrides={{
                        pagetype: "portfolio",
                        portfolio: portfolio.id,
                    }}
                />
            </div>
        </>
    );
}
