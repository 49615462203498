import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken()

export default class EmailVerficiationAPI {
    public static async getMail(data: {}) {
        return APIClient.ezoom.post(`/email-verifications`, data)
    }

    public static async verifMail(uuid: string) {
        if (token)
            return APIClient.ezoom.post(`/public/verify-email?token=${uuid}`, {})
        else
            return APIClient.noInterceptor.post(`/public/verify-email?token=${uuid}`, {})
    }
}
