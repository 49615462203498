import AutosizeInput from 'react-input-autosize';

const InputWithSpan = ({ inputValue, setInputValue, className, placeholder, handleEnterPress }: { inputValue: string, setInputValue: Function, className: string, placeholder: string, handleEnterPress?: any }) => {
    return (
        <p>
            <AutosizeInput
                name="form-field-name"
                placeholder={placeholder}
                value={inputValue}
                className={className}
                style={{ backgroundColor: "transparent", fontSize: "15px", color: "rgb(233, 133, 18)" }}
                inputStyle={{ backgroundColor: "transparent", fontSize: "15px", color: "rgb(233, 133, 18)" }}
                onChange={(e) => setInputValue(e)}
                onKeyDown={(e) => handleEnterPress && handleEnterPress(e)}
            />
        </p>
    );
};

export default InputWithSpan;