import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import UserAPI from "../../../services/API/Clients/UserAPI";
import { Card, CardHeader, Tab, Tabs, TabsBody, TabsHeader, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootState } from "../../../services/redux/store";
import { handleError } from "../../../services/Errors/handleErrors";

const defaultOptions = {
    colors: ["#ef9335"],
    chart: {
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        bar: {
            columnWidth: "16%",
            borderRadius: 5,
        },
    },
    xaxis: {
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        labels: {
            style: {
                colors: "#000000",
                fontSize: "13px",
                fontFamily: "inherit",
                fontWeight: 300,
            },
        },
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    yaxis: {
        labels: {
            style: {
                colors: "#000000",
                fontSize: "13px",
                fontFamily: "inherit",
                fontWeight: 300,
            },
        },
    },
    grid: {
        show: true,
        borderColor: "#00000033",
        strokeDashArray: 5,
        xaxis: {
            lines: {
                show: true,
            },
        },
        padding: {
            top: 5,
            right: 20,
        },
    },
    fill: {
        opacity: 0.8,
    },
    tooltip: {
        theme: "dark",
    },
    markers: {
        size: 5,
    },
};

export default function Charts({ color, query, title }: Readonly<{ color: string; query: string, title: string }>) {
    const { t } = useTranslation();
    const general = useSelector((state: RootState) => state.general);
    const [options, setOptions] = React.useState(defaultOptions);
    const [datas, setDatas] = React.useState<any[]>([]);
    const [date, setDate] = React.useState<string>("day");
    const [queryEnd, setQueryEnd] = React.useState<string>("&period=day&group=hour");

    useEffect(() => {
        if (date === "day") {
            setQueryEnd("&period=day&group=hour");
        } else if (date === "week") {
            setQueryEnd("&period=week&group=day");
        } else if (date === "month") {
            setQueryEnd("&period=month&group=day");
        } else {
            console.log("error")
        }
    }, [date]);

    useEffect(() => {
        const fetchData = async () => {
            if (!general?.me?.id) return;
            const response = await UserAPI.statistics(general?.me?.id, query + queryEnd);
            if (response.status === 200) {
                setDatas(response.body);
            } else {
                handleError(response);
            }
        };
        fetchData();
    }, [general?.me?.id, query, queryEnd]);

    useEffect(() => {
        // const categories = ["abc", "def"]
        const updatedOptions = {
            ...defaultOptions,
            ...{
                xaxis: {
                    ...defaultOptions.xaxis,
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            ...{ colors: [color === "orange" ? "#ef9335" : "#625199"] },
        };
        setOptions(updatedOptions);
    }, [datas, color]);

    const series = {
        name: "series-1",
        data: datas.map((item) => {
            return {
                x: moment(item.date).format(date === "day" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"),
                y: item.count
            }
            })
    }

    return (
        <Card className="w-full xl:w-1/2 p-4 bg-transparent shadow-none">
            <Tabs value="day">
                <TabsHeader className="mb-4 w-2/3 mx-auto">
                    <Tab value="day" onClick={() => setDate("day")}>
                        {t("day")}
                    </Tab>
                    <Tab value="week" onClick={() => setDate("week")}>
                        {t("week")}
                    </Tab>
                    <Tab value="month" onClick={() => setDate("month")}>
                        {t("month")}
                    </Tab>
                </TabsHeader>
                <TabsBody className="pt-8">
                    <CardHeader className="h-full grow" style={{ backgroundImage: "linear-gradient(to right top, #FEFEFE, #DEDEDE)" }}>
                        {series.data.length > 0
                            ? series.data.length > 1 
                                ? <Chart type={"area"} series={[series]} options={options} width={"100%"} />
                                : <Chart type={"bar"} series={[series]} options={options} width={"100%"} />
                        : (
                            <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 h-full aspect-video">
                                <FontAwesomeIcon icon="chart-simple" className="mt-12 text-4xl text-gray-500/50 mb-4" />
                                <Typography className="text-base text-gray-700 mb-12">{t("noStats")}</Typography>
                            </div>
                        )}
                    </CardHeader>
                    <Typography variant="h6" color="blue-gray" className="text-center my-6">
                        {t(title)}
                    </Typography>
                </TabsBody>
            </Tabs>
        </Card>
    );
}
