import { useState } from "react";
import { useTranslation } from "react-i18next";
import GradientButton from "../../../components/Form/Button/GradientButton";
import { GalleryInterface } from "../../../components/Interfaces/GalleryInterface";
import { CompletionDetailsGallery } from "../../Home/LogIn/components/RightSide";

interface PublishGalleryButtonProps {
    onPublish: () => void
    data: GalleryInterface
    banner?: {
        publicPath?: string
    },
    tmpBanner: {
        id?: string
        minPath?: string
    }
    pictures: number
}

enum MandatoryField {
    BANNER = "banner",
    TITLE = "title",
    PICTURES = "pictures"
}

const PublishGalleryButton = ({ onPublish, data, banner, tmpBanner, pictures }: PublishGalleryButtonProps) => {
    const { t } = useTranslation();

    const getMissingFields = (): MandatoryField[] => {
        const fields: MandatoryField[] = [];

        if (!(tmpBanner && tmpBanner.minPath !== "" && tmpBanner.minPath) && !(data && data.banner && data.banner.publicPath)) {
            fields.push(MandatoryField.BANNER);
        }
        if (!data.title || data.title === undefined || data.title === "") {
            fields.push(MandatoryField.TITLE);
        }
        if (pictures === 0) {
            fields.push(MandatoryField.PICTURES);
        }

        return fields;
    }

    const [showDetails, setShowDetails] = useState(false);

    const missingFields = getMissingFields();

    return (<div onMouseEnter={() => setShowDetails(true)} onMouseLeave={() => setShowDetails(false)}>
        <button onClick={() => onPublish()} className={`pt-0.5 text-base whitespace-nowrap ${missingFields.length > 0 ? "opacity-50 pointer-events-none" : ""}`}>
            <GradientButton text={t(data.private ? "saveandpublishprivate" : "saveandpublish")} />
        </button>
        {missingFields.length > 0 && <div className={`absolute bg-white border-gray-300 border p-3 m-2 rounded-lg min-w-[200px] z-10 ${showDetails ? "" : "hidden"}`}>
            <b className="text-lg">{t("beforepublish")}</b>
            <CompletionDetailsGallery
                hasBanner={missingFields.indexOf(MandatoryField.BANNER) === -1}
                hasTitle={missingFields.indexOf(MandatoryField.TITLE) === -1}
                hasPictures={missingFields.indexOf(MandatoryField.PICTURES) === -1}
            />
        </div>}
    </div>);
}

export default PublishGalleryButton;