import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Chip, IconButton, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import React from "react";

export default function ModalChangePlanPro({ open, setOpen }: Readonly<{ open: boolean; setOpen: any }>) {
    const { t } = useTranslation();
    const [statePrices, setStatePrices] = React.useState({
        prenium: false,
        pro: false,
    });

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-orange-500 text-center">
                    {t("boostyouraccount")}
                    <FontAwesomeIcon icon="crown" className="ml-2" />
                </Dialog.Title>
                <IconButton
                    color="white"
                    onClick={() => setOpen(false)}
                    className="absolute top-4 right-4 ring-1 ring-gray-500 ring-opacity-20 rounded-full hover:bg-gray-100"
                >
                    <FontAwesomeIcon icon="times" />
                </IconButton>
                <div className="flex justify-between items-start gap-4 mt-8">
                    <Card color="purple" variant="gradient" className="w-full xl:w-2/3 px-8 py-4 mx-auto shadow-lg h-fit">
                        <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="m-0 rounded-none border-b border-white/10 pb-4 text-center"
                        >
                            <Typography variant="small" color="white" className="font-normal uppercase">
                                {t("pro")}
                            </Typography>
                            <div className="mt-4 flex flex-row items-center justify-around">
                                <span className={`text-sm font-medium ${statePrices.pro ? "text-gray-300" : "text-white"}`}>{t("monthly")}</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        onChange={() => setStatePrices({ ...statePrices, pro: !statePrices.pro })}
                                        value=""
                                        className="sr-only peer"
                                    />
                                    <div className="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-100"></div>
                                </label>
                                <span className={`text-sm font-medium ${!statePrices.pro ? "text-gray-300" : "text-white"}`}>{t("yearly")}</span>
                            </div>
                            <p className={`${statePrices.pro ? "opacity-100" : "opacity-0"} text-left md:text-center text-gray-300 font-sans font-base mt-8 flex justify-center gap-2 text-xs line-through`}>
                                {t("chf")}
                                <span>22.00 {t("ttc")}</span>
                                <span>{t("monthly_label")}</span>
                            </p>
                            <p className="text-left md:text-center text-orange-500 font-sans font-base mb-3 flex justify-center gap-2">
                                {!statePrices.pro ? (
                                    <div className="flex flex-col justify-center items-center">
                                        <Typography color="white" className="justify-center gap-1 text-2xl xl:text-3xl 2xl:text-4xl font-normal">
                                            <span className="mt-2 text-base 2xl:text-2xl">{t("chf")}</span>
                                            <span> 22.00 {t("ttc")}</span>
                                            <span className="self-end text-base 2xl:text-2xl">{t("monthly_label")}</span>
                                        </Typography>
                                        <span className="text-xs font-bold text-gray-200 invisible">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 198.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex flex-col justify-center items-center">
                                        <Typography color="white" className="justify-center gap-1 text-2xl xl:text-3xl 2xl:text-4xl font-normal">
                                            <span className="mt-2 text-base 2xl:text-2xl">{t("chf")}</span>
                                            <span> 16.50 {t("ttc")}</span>
                                            <span className="self-end text-base 2xl:text-2xl">{t("monthly_label")}</span>
                                        </Typography>
                                        <span className="text-xs font-bold text-gray-300">
                                            {t("orYearly")} {" "}
                                            {t("chf")}
                                            <span> 198.00 {t("ttc")}</span>
                                            <span>{t("yearly_label")}</span>
                                        </span>
                                    </div>
                                )}
                            </p>
                        </CardHeader>
                        <CardBody className="p-0 py-4 text-sm border-b border-white/10">
                            <p dangerouslySetInnerHTML={{ __html: t("pro_summary") }}></p>
                        </CardBody>
                        <Link to="/tarifs" className="text-sm text-center text-white hover:text-gray-200 mt-2">En savoir plus</Link>
                        <Link to="/subscription/pro" className="mt-2 w-full bg-white text-purple-500 hover:bg-purple-50 hover:text-purple-600 py-3 rounded-lg font-medium text-center">
                            {t("obtainPro")}
                        </Link>
                    </Card>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}

