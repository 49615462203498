import React, { useEffect } from "react";
import PortfolioAPI from "../../../services/API/Clients/PortfolioAPI";
import Loader from "../../../components/Loader";
import PortfolioView from "../../Portfolio/Portfolio";
import Page404 from "./Page404";
import { Outlet } from "react-router-dom";
import { handleError } from "../../../services/Errors/handleErrors";

export default function PortfolioOr404() {
    const [loading, setLoading] = React.useState(true)
    const [doIAccess, setDoIAccess] = React.useState(false)
    const currentUrl = window.location.pathname;

    useEffect(() => {
        const fetchData = async () => {
            const response = await PortfolioAPI.path(currentUrl.split("/")[1])
            if (response.status === 200 || response.status === 201) {
                setDoIAccess(true)
                setLoading(false)
            } else {
                handleError(response);
                setDoIAccess(false)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <Loader loading={loading} />
            {doIAccess ? <PortfolioView isPathPersonnal={true} /> : <Page404 />}
        </>
    )
}