import React from "react";
import { useTranslation } from "react-i18next";

export default function GradientButtonPurple({ text, disabled = false }: Readonly<{ text: string, disabled?: boolean }>) {
    const { t } = useTranslation();

    return (
        <button
            className="border-2 bg-purple-500 hover:bg-purple-200 border-transparent inline w-auto text-white hover:text-black py-2 px-4 rounded-full disabled:bg-gray-200 disabled:hover:text-white"
            style={{
                transition: "all .25s ease-in-out",
            }}
            disabled={disabled}
        >
            <span className="flex">{t(text)}</span>
        </button>
    );
}
