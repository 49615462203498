import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const staticRoutes = [
    "/signin", "/signup", "/confirm-signup", "/verify-email", "/forgotpassword", 
    "/forgot-password", "/phone_confirmation", "/2fa", "/favorites", "/gallery", 
    "/portfolio", "/my-portfolio", "/recent", "/popular", "/galeries", "/search", 
    "/photographers", "/popular-photographers", "/recent-photographers", "/account", 
    "/sales", "/purchases", "/statistics", "/subscription", "/checkout", "/upload", 
    "/contact", "/contracts", "/download", "/about", "/privacy", "/CGU", 
    "/CGU-newsletter", "/CGV", "/avis", "/tarifs", "/help", "/unsubscribe-newsletter"
];

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const { id } = useParams();

    

    useEffect(() => {
        const isPortfolioRoute = pathname.startsWith("/portfolio");
        const isStaticRoute = staticRoutes.some(route => pathname.startsWith(route));

        console.log("pathname", pathname);
        console.log("isPortfolioRoute", isPortfolioRoute);
        console.log("isStaticRoute", isStaticRoute);

        if (!isPortfolioRoute && isStaticRoute) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
}