import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import BrandAPI from "../../../../services/API/Clients/BrandAPI";
import { DualInput } from "./DualInput";
import { useSelector, useDispatch } from "react-redux";
import { Setup } from "../../../../components/Interfaces/PortfolioType";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Boitier({ brands }: Readonly<{ brands: { value: string; label: string }[] }>) {
    const portfolio = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    const t = useTranslation().t;

    const handleAddBoitier = () => {
        const newBoitiers = [...portfolio.body];
        newBoitiers.push({ brandLabel: "", modelLabel: "", type: "body" });

        dispatch({ type: "UPDATE_BODY", payload: newBoitiers });
    };

    const modelsGetter = async (brand: string) => {
        const respModels = await BrandAPI.model(brand);
        if (respModels.status === 200) {
            const formatModels = respModels.body.map((model: { id: string; label: string }) => ({ value: model.label, label: model.label, type: "body" }));
            return formatModels;
        } else {
            handleError(respModels);
        }
        return [];
    };

    return (
        <>
            <Typography className="ml-2 text-base mt-2">{t("setup_b")}</Typography>
            {portfolio.body.map((boitier: Setup, index: number) => (
                <DualInput
                    leftChoices={brands}
                    onChange={(value: [string, string]) => {
                        const newBoitiers = [...portfolio.body];
                        newBoitiers[index] = { brandLabel: value[0], modelLabel: value[1], type: "body" };
                        dispatch({ type: "UPDATE_BODY", payload: newBoitiers });
                    }}
                    rightChoicesGetter={modelsGetter}
                    value={[boitier.brandLabel, boitier.modelLabel]}
                    key={index}
                />
            ))}
            <button onClick={() => handleAddBoitier()} className="flex">
                <Typography className="ml-6 text-base text-gray-500 mt-2 cursor-pointer">
                    <FontAwesomeIcon icon="plus" /> {t("addsetup")}
                </Typography>
            </button>
        </>
    );
}
