import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { Storage } from "../../../../services/storage";
import SharingBox from "../../../../components/Box/SharingBox";
import { toast } from "react-toastify";
import FavoriteAPI from "../../../../services/API/Clients/FavoriteAPI";
import CardUserWithData from "../../../../components/Box/CardUserWithData";
import { useSelector } from "react-redux";
import { OpenGalleryReport } from "../../../../components/Modals/ModalGallerieReport";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import { handleError } from "../../../../services/Errors/handleErrors";
import moment from "moment";

const token = Storage.getId();

export default function GalleryHeader({
    searchBar,
    picturesLength,
    data,
    setOpenModal,
    fetchData,
}: Readonly<{ searchBar: boolean; picturesLength: number; data: GalleryInterface; setOpenModal: any; fetchData: any }>) {
    const { t } = useTranslation();
    const [sortBy, setSortBy] = React.useState("");
    const general = useSelector((state: any) => state.general);
    const [hidden, setHidden] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);

    const handleChangeFav = async () => {
        if (general && general.me && general.me.portfolio && general.me.portfolio.id) {
            const datas = {
                picture: `/api/public/pictures/${data.id}`,
                owner: `/api/users/${general.me.portfolio.id}`,
            };
            if (!data.isFavorited) {
                const response = await FavoriteAPI.favGallerie(datas);
                if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
                else handleError(response);
            } else {
                const response = await FavoriteAPI.unFavGallerie(datas);
                if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
                else handleError(response);
            }
            fetchData();
        }
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    return (
        <> 
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <PictureDrawerForFilters />
            <div
                className="md:hidden relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${(data?.banner?.publicPath) ?? DefaultBannerEzoom})`,
                }}
            ></div>
            <div className="flex md:hidden flex-row justify-between mt-2 px-4">
                <div className="flex flex-row justify-start gap-4 w-1/2">
                    <CardUserWithData data={data.portfolio} isVerified={data.owner.verified} />
                </div>
                <div className="flex flex-row justify-end gap-2 w-1/2">
                    <IconButton
                        onClick={() => !Storage.getToken() ? setOpenInvitation(true) :  handleChangeFav()}
                        color={data.isFavorited ? "red" : "white"}
                        size="sm"
                        className={`ring-1 ${
                            data.isFavorited ? "ring-red-500/50 rounded-full hover:bg-red-400" : "ring-gray-400/50 rounded-full hover:bg-gray-50"
                        } transition-all`}
                    >
                        <FontAwesomeIcon icon="heart" />
                    </IconButton>
                    <SharingBox />
                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <IconButton size="sm" color="white" className="ring-1 ring-gray-400/50 transition-all duration-400 rounded-full hover:bg-gray-50">
                                <FontAwesomeIcon icon="ellipsis-vertical" />
                            </IconButton>
                        </PopoverHandler>
                        <PopoverContent className="p-0">
                            <button
                                className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                onClick={() => !Storage.getToken() ? setOpenInvitation(true) :  data?.id && OpenGalleryReport(data.id, t)}
                            >
                                {t("report")}
                            </button>
                            {token && token === data.owner.id && (
                                <a href={`/gallery/${data.id}/edit`}>
                                    <div className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full">{t("edit")}</div>
                                </a>
                            )}
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <Typography className="flex md:hidden justify-center text-4xl font-bold text-center font-sans px-4 mt-4" style={{ fontFamily: "Great Vibes" }}>
                {data.title}
            </Typography>
            <div className="flex md:hidden flex-col md:flex-row justify-between px-4 mt-4 max-w-7xl mx-auto w-full">
                <div className="flex flex-row flex-wrap justify-center gap-4 px-4 mx-auto w-full md:w-1/3">
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        size="sm"
                        variant="ghost"
                        value={`${picturesLength}  ${t("photos")}`}
                        icon={<FontAwesomeIcon icon="images" className="h-3 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        size="sm"
                        value={`${data.viewCount} ${t("views")}`}
                        icon={<FontAwesomeIcon icon="eye" className="h-3 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        size="sm"
                        value={`${data.favoriteCount} ${t("favs")}`}
                        icon={<FontAwesomeIcon icon="heart" className="h-3 mt-0.5" />}
                    />
                </div>
                <div className="flex flex-rox md:flex-col justify-center md:justify-end md:items-end  mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3 text-gray-500 text-right gap-1">
                    {data.date && moment(data.date).format("DD.MM.YYYY")}<br />
                    {data?.place?.label}
                    {data.private && (
                        <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {t("privateGallery")}
                        </div>
                    )}
                    {!data.published && (
                        <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {t("unpublishedGallery")}
                        </div>
                    )}
                </div>
            </div>

            <motion.div
                variants={{
                    visible: { top: "48px", paddingTop: "12px" },
                    hidden: { top: "0px", paddingTop: "4px" },
                    barsInactive: { top: "48px", paddingTop: "12px" },
                    barsActive: { top: "102px", paddingTop: "8px" },
                }}
                animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                transition={{
                    duration: 0.35,
                    ease: "easeInOut",
                }}
                className="flex md:hidden justify-end gap-2 pr-2 mt-4 md:-mb-4 sticky top-0 md:pt-0 pb-1 md:pb-0 bg-white z-30 border-b border-gray-200"
            >
                <MobileFilterButton />

                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button
                            className={`${
                                sortBy !== "" ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""
                            } border flex px-4 py-2.5 rounded-full flex-row items-center gap-2 hover:bg-white text-xs`}
                        >
                            {t("sortby")}
                            <FontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className={`absolute right-0 z-50  flex-col px-2 py-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                        >
                            <div className="py-1">
                                {["default", "note"].map((item: string) => (
                                    <Menu.Item key={item}>
                                        <button
                                            onClick={() => setSortBy(item)}
                                            className={`${
                                                sortBy === item && "bg-orange-500 text-white"
                                            } my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg`}
                                        >
                                            {t(item)}
                                            {sortBy === item && <FontAwesomeIcon icon="check" className="ml-2" />}
                                        </button>
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </motion.div>
        </>
    );
}
