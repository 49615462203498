import { Checkbox } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import TitleHelper from "./components/TitleHelper";
import LinkGeneric from "./components/LinkGeneric";

export default function Adult({ filterAdult, handleFilterAdult }: Readonly<{ filterAdult: any; handleFilterAdult: () => void }>) {
    const { t } = useTranslation();
    return (
        <div className=" pb-4 border-b border-gray-300">
            <div className="flex flex-row items-center w-full mt-4">
                <div className="flex flex-col">
                    <TitleHelper title="nsfw" infobulle="nsfw_i" />
                    <div className="flex flex-row items-center gap-2">
                        <p className={`text-sm ${filterAdult ? "text-black" : "text-gray-500"} transition-all mb-2 text-justify pr-2`}>{t("nsfw_d")}</p>
                        <Checkbox className="bg-white" color={filterAdult ? "orange" : "gray"} checked={filterAdult} onChange={handleFilterAdult} />
                    </div>
                    <LinkGeneric link="/CGU#conduiteDesUtilisateurs" title="learnmorepolicy" internLink={true} />
                </div>
            </div>
        </div>
    );
}
