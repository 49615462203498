import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PropIntellectuel() {
    const { t } = useTranslation();
    const [openDescription, setOpenDescription] = React.useState(false);
    return (
        <div className="">
            <button
                type="button"
                onClick={() => setOpenDescription(!openDescription)} 
                className="flex flex-row justify-between items-center w-full mt-4 pb-3 border-b border-gray-300"
            >
                <div className="flex flex-col text-left w-full pr-2">
                    <p className="text-lg font-sans">7. {t("property_rights")}</p>
                </div>
                <div className="flex w-fit">
                    <FontAwesomeIcon icon={openDescription ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                </div>
            </button>
            <Collapse open={openDescription} className="flex flex-col gap-4 pt-2 px-4">
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("content_possession")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("content_possession_d")}
                </Typography>
                <Typography variant="paragraph" className="font-bold text-justify">
                    {t("attribution")}
                </Typography>
                <Typography variant="small" className="text-justify">
                    {t("attribution_d", { user: "John Doe", url: "jonh-doe" })}
                </Typography>
            </Collapse>
        </div>
    )
}