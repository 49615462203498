import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

export default function GradientButton({ text, icon }: { text: string, icon: any }) {
    const { t } = useTranslation();

    return (
        <button
            className="border-2 bg-orange-500 hover:bg-orange-200 border-transparent inline w-auto text-white hover:text-black py-1 px-4 rounded-full"
            style={{
                // backgroundImage: "linear-gradient(to right, #e98512, #F5BA56, #F5BA56, #fff)",
                // backgroundSize: "300% 100%",
                transition: "all .25s ease-in-out",
                // backgroundPosition: isHover ? "calc(100% + 6px)" : "-6px",
            }}
        >
            <span className="hidden md:flex">{t(text)}</span>
            <FontAwesomeIcon icon={icon} className="md:hidden text-white hover:text-black" />
        </button>
    );
}
