import Footer from "../../components/Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import MiniLogo from "../../assets/images/logos/SVG/logo_e.svg";
import GradientButton from "../../components/Form/Button/GradientButtonBig";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackgroundDefault from "../../assets/images/elements/default-banner.png";
import Loader from "../../components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Picture2faStep1 from "../../assets/images/elements/2fa/step1.svg";
import Picture2faStep3 from "../../assets/images/elements/2fa/step3.svg";
import Picture2faStep4 from "../../assets/images/elements/2fa/step4.svg";
import { PictureType } from "../../components/Interfaces/PictureType";
import HeaderForAnimation from "../../components/NavBar/HeaderForAnimation";
import HeaderMobile from "../../components/NavBar/HeaderMobile";
import PicturesAPI from "../../services/API/Clients/PicturesAPI";
import UserAPI from "../../services/API/Clients/UserAPI";
import { updateMe } from "../../services/redux/actions/generalActions";
import { RootState } from "../../services/redux/store";
import { handleError } from "../../services/Errors/handleErrors";

export default function DoubleAuth() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("inscription_confirmation")}`;
    const [searchBar, setSearchBar] = React.useState(false);
    const general = useSelector((state: RootState) => state.general);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);
    const [step, setStep] = React.useState(0);
    const [qrCodePicture, setQrCodePicture] = React.useState<string>("");
    const [codeFor2fa, setCodeFor2fa] = React.useState("");
    const [backgroundPicture, setBackgroundPicture] = useState<PictureType | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await PicturesAPI.backgroundPicture();
            if (response.status === 200) {
                setBackgroundPicture(response.body.picture);
                setLoading(false);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        setLoading(false);
    }, []);

    const chooseText = () => {
        switch (step) {
            case 0:
                return "twofactor_step1";
            case 1:
                return "twofactor_step2";
            case 2:
                return "twofactor_step3";
            case 3:
                return "twofactor_step4";
            default:
                return "2fa_step1";
        }
    }

    const updateStep2 = async (e: React.FormEvent) => {
        e.preventDefault();
        if (general?.me?.id === undefined) return
        const stepper = step + 1
        if (stepper === 3) {
            const datas = {
                totpChallenge: codeFor2fa,
            }
            const response = await UserAPI.verifyTotp(general?.me?.id, datas)
            if (response.status === 200) {
                const respUser = await UserAPI.me()
                if (respUser.status === 200) {
                    dispatch(updateMe(respUser.body))
                } else {
                    handleError(respUser);
                }
                setStep(stepper)
            } else {
                handleError(response);
            }
        }

    }

    const updateStepping = async () => {
        if (general?.me?.id === undefined) return
        const stepper = step + 1
        if (stepper === 1) {
            const response = await UserAPI.initTotp(general?.me?.id)
            if (response.status === 200) {
                const blob = response.body;
                const imageUrl = URL.createObjectURL(blob);
                setQrCodePicture(imageUrl)
                setStep(stepper)
            } else {
                handleError(response);
            }
        } else if (stepper === 2) {
            setStep(stepper)
        } else if (stepper === 3) {
            const datas = {
                totpChallenge: codeFor2fa,
            }
            const response = await UserAPI.verifyTotp(general?.me?.id, datas)
            if (response.status === 200) {
                const respUser = await UserAPI.me()
                if (respUser.status === 200) {
                    dispatch(updateMe(respUser.body))
                } else {
                    handleError(respUser);
                }
                setStep(stepper)
            } else {
                handleError(response);
            }
        } else {
            return;
        }
    }

    return (
        <div className="min-h-full">
            <Loader loading={loading} />
            <HeaderForAnimation />
            <HeaderMobile searchBar={searchBar} openSearchBar={setSearchBar} />
            <div
                className="hidden md:block h-full min-h-screen"
                style={{
                    backgroundImage: `url('${backgroundPicture?.publicPath ?? BackgroundDefault}')`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "100vmax",
                    zIndex: -1,
                    position: "fixed",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    pointerEvents: "none",
                    backgroundPositionX: "center",
                }}
            ></div>
             <div className="relative min-h-screen flex justify-start items-start">
                <div className="relative w-screen min-h-screen md:min-h-fit mt-0 mb-0 md:h-fit md:w-2/3 xl:w-1/3 bg-white rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <Link to="/account/security" className="absolute top-4 right-4 flex md:hidden">
                        <IconButton color="orange" className="w-10 h-10 rounded-0 md:rounded-lg flex items-center justify-center">
                            <FontAwesomeIcon icon="x" className="text-white h-4 w-4 mx-auto my-3" aria-hidden="true" />
                        </IconButton>
                    </Link>
                    <MiniLogo className="w-14 h-14 my-2.5 mx-auto" />
                    <h1 className="text-3xl mb-2 text-center">{t("twofactor")}</h1>
                    <div className="my-4 mx-auto">
                        {step === 0 && <Picture2faStep1 width="200" />}
                        {step === 1 && <img src={qrCodePicture} style={{ width: "200px" }} alt="qrcode" />}
                        {step === 2 && <Picture2faStep3 width="200" />}
                        {step === 3 && <Picture2faStep4 width="200" />}
                    </div>
                    <form onSubmit={updateStep2}>
                        <p className="text-center text-gray-500 text-sm gap-2 mb-2" dangerouslySetInnerHTML={{ __html: t(chooseText()) }}></p>
                        {step === 2 && (
                            <Input color="orange" onChange={(e) => setCodeFor2fa(e.target.value)} label={t("code")} />
                        )}
                        <div className="flex flex-row justify-center items-center gap-2 mt-2">
                            {step !== 0 && step !== 3 && (
                                <button type="button" className="w-full border-2 bg-white hover:bg-gray-200 transition-all inline py-2 px-4 rounded-full text-center items-center justify-center" onClick={() => setStep(step - 1)}>
                                    {t("previoustep")}
                                </button>
                            )}
                            {step !== 3 && (
                                <button type="button" className="w-full " onClick={() => updateStepping()}>
                                    <GradientButton text="nextstep" />
                                </button>
                            )}
                            {step === 3 && (
                                <Link to="/account/security" className="w-full">
                                    <GradientButton text="returnParameters" />
                                </Link>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            <Footer isAbsolute={false} />
        </div>
    );
}
