import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { useTranslation } from "react-i18next";
import SwiperPhotograph from "../../../../components/Box/Photograph/SwiperPhotograph";
import React from "react";
import { Link } from "react-router-dom";
import { SearchPortfolioType } from "../../../../components/Interfaces/PortfolioType";
import { useSelector } from "react-redux";
import { Typography } from "@material-tailwind/react";
import { HashLink } from "react-router-hash-link";

const id = 1;

export default function RightSideUnder({
    complete,
    photographers,
}: Readonly<{
    complete: {
        completionPercentage: number;
        hasProfilePicture: boolean;
        hasBanner: boolean;
        hasPublishedPhotos: boolean;
        hasAbout: boolean;
        hasPublishedPortfolio: boolean;
    };
    photographers: SearchPortfolioType;
}>) {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);

    return (
        <div className="flex flex-col w-full gap-10 rounded-lg h-fit">
            {general && general?.me && complete.completionPercentage < 100 && (
                <div className={`flex flex-col w-full bg-gray-50 p-4 rounded-lg h-fit`}>
                    <p className="text-xl text-gray-900 font-sans">{t("complete_publish_portfolio")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4">{t("complete_portfolio_p1")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4">{t("complete_portfolio_p2")}</p>
                    <div className="hidden md:flex w-[40%] mx-auto my-8">
                        <CircularProgressbar
                            value={complete.completionPercentage ?? 0}
                            text={`${complete.completionPercentage ?? 0}%`}
                            styles={buildStyles({
                                pathColor: "#F5BA56",
                                textColor: "#625199",
                                trailColor: "#e5e5e5",
                                textSize: "18px",
                            })}
                        />
                    </div>
                    <hr className="hidden md:flex" />
                    <div className="hidden md:flex justify-start items-start flex-col gap-1 mt-4 text-left mb-4">
                        <p className="text-base text-gray-900 font-sans ">
                            <FontAwesomeIcon icon={"check-circle"} className={`mr-2 text-orange-800`} />
                            {t("task_account")}
                        </p>
                        <p className="text-base text-gray-900 font-sans mt-4">
                            <FontAwesomeIcon
                                icon={complete.hasProfilePicture ? "check-circle" : "circle"}
                                className={`mr-2 ${complete.hasProfilePicture ? "text-orange-800" : "text-gray-300"}`}
                            />
                            {t("task_pp")}
                        </p>
                        <p className="text-base text-gray-900 font-sans mt-4">
                            <FontAwesomeIcon
                                icon={complete.hasBanner ? "check-circle" : "circle"}
                                className={`mr-2 ${complete.hasBanner ? "text-orange-800" : "text-gray-300"}`}
                            />
                            {t("task_banner")}
                        </p>
                        <p className="text-base text-gray-900 font-sans mt-4">
                            <FontAwesomeIcon
                                icon={complete.hasPublishedPhotos ? "check-circle" : "circle"}
                                className={`mr-2 ${complete.hasPublishedPhotos ? "text-orange-800" : "text-gray-300"}`}
                            />
                            {t("task_upload")}
                        </p>
                        <p className="text-base text-gray-900 font-sans mt-4">
                            <FontAwesomeIcon
                                icon={complete.hasAbout ? "check-circle" : "circle"}
                                className={`mr-2 ${complete.hasAbout ? "text-orange-800" : "text-gray-300"}`}
                            />
                            {t("task_desc")}
                        </p>
                        <p className="text-base text-gray-900 font-sans mt-4">
                            <FontAwesomeIcon
                                icon={complete.hasPublishedPortfolio ? "check-circle" : "circle"}
                                className={`mr-2 ${complete.hasPublishedPortfolio ? "text-orange-800" : "text-gray-300"}`}
                            />
                            {t("task_published")}
                        </p>
                    </div>
                    <hr className="hidden md:flex" />
                    <div className="hidden md:flex justify-center items-center mt-4">
                        <Link to={`/portfolio/edit/about`}>
                            <GradientButton text={t("complete_portfolio")} />
                        </Link>
                    </div>
                </div>
            )}
            {general && general.me && !general.me.verified && (
                <div className={`flex flex-col w-full bg-gray-50 p-4 rounded-lg h-fit`}>
                    <p className="text-xl text-gray-900 font-sans">{t("verify_account")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4">
                        {t("verify_account_p1")} <FontAwesomeIcon icon="circle-check" className="text-orange-500" /> {t("verify_account_p2")}
                    </p>
                    <Link to="/account/data" className="w-fit mx-auto mt-4">
                        <GradientButton text={t("verify_account_btn")} />
                    </Link>
                </div>
            )}
            {(general?.me?.plan !== "PRO") && (
                <div className={`flex flex-col w-full bg-gray-50 p-4 rounded-lg h-fit`}>
                    <p className="text-xl text-gray-900 font-sans">{t("next_level")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4 mb-4" dangerouslySetInnerHTML={{ __html: t(general?.me?.plan !== "PREMIUM" ? "next_level_d" : "next_level_d_pro")}}></p>
                    <div className="flex flex-col gap-2 justify-around items-center">
                        {general?.me?.plan !== "PREMIUM" && (
                            <Link to="/subscription/premium" className="w-fit mx-auto">
                                <button className="rounded-full border bg-orange-500 text-white py-2 px-4">{t("next_level_btn_p1")}</button>
                            </Link>
                        )}
                        <Link to="/subscription/pro" className="w-fit mx-auto ">
                            <button className="rounded-full bg-purple-500 py-2 px-4 text-white">{t("next_level_btn_p2")}</button>
                        </Link>
                    </div>
                    <HashLink to="/tarifs#detailTarifs">
                        <p className="text-sm text-gray-900 font-sans mt-4 text-center">
                            {t("next_level_a")} <FontAwesomeIcon icon="chevron-right" className="h-3" />
                        </p>
                    </HashLink>
                </div>
            )}
            <div className={`flex flex-col w-full bg-gray-50 px-5 py-4 rounded-lg h-fit`}>
                <p className="text-xl text-gray-900 font-sans mb-4">{t("photographers_around")}</p>
                {photographers.data.length > 0 ? (
                    <SwiperPhotograph galeries={photographers.data} />
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                        <FontAwesomeIcon icon="user" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                        <Typography className="text-base text-gray-700 mb-4">{t("noPhotographers")}</Typography>
                    </div>
                )}
            </div>
        </div>
    );
}
