import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientButton from "../../../../components/Form/Button/GradientButton";
import { useTranslation } from "react-i18next";
import SwiperPhotograph from "../../../../components/Box/Photograph/SwiperPhotograph";
import { Carousel, IconButton, Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import { SearchPortfolioType } from "../../../../components/Interfaces/PortfolioType";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

const id = 1;

export default function RightSide({
    complete,
    photographers,
}: Readonly<{
    complete: {
        completionPercentage: number;
        hasProfilePicture: boolean;
        hasBanner: boolean;
        hasPublishedPhotos: boolean;
        hasAbout: boolean;
        hasPublishedPortfolio: boolean;
    };
    photographers: SearchPortfolioType,
}>) {
    const { t } = useTranslation();
    const general = useSelector((state: any) => state.general);
    const [myActiveIndex, setMyActiveIndex] = React.useState(0);
    const [openCollapse, setOpenCollapse] = React.useState(false);
    const plan: string = general?.me?.plan ?? "USER";

    return (
        <Carousel
            prevArrow={({ handlePrev }) => (
                <IconButton
                    variant="text"
                    color="orange"
                    size="sm"
                    onClick={() => {
                        handlePrev();
                        setMyActiveIndex(myActiveIndex - 1);
                    }}
                    className="!absolute top-0.5 left-1"
                    disabled={myActiveIndex === 0}
                >
                    <FontAwesomeIcon icon="chevron-left" className="h-4 w-4" />
                </IconButton>
            )}
            nextArrow={({ handleNext }) => (
                <IconButton
                    variant="text"
                    color="orange"
                    size="sm"
                    onClick={() => {
                        handleNext();
                        setMyActiveIndex(myActiveIndex + 1);
                    }}
                    className="!absolute top-0.5 !right-1"
                    disabled={(plan === "PRO") ? myActiveIndex === 2 : myActiveIndex === 3}
                >
                    <FontAwesomeIcon icon="chevron-right" className="h-4 w-4" />
                </IconButton>
            )}
            navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute top-3.5 left-2/4 z-30 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                        <span
                            key={i}
                            className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-orange-500" : "w-4 bg-orange-200/50"
                                }`}
                            onClick={() => {
                                setActiveIndex(i);
                                setMyActiveIndex(i);
                            }}
                        />
                    ))}
                </div>
            )}
            className="h-fit"
        >
            <div className={`${myActiveIndex === 0 ? "flex" : "hidden"} flex flex-col w-full bg-gray-50 p-5 pt-10 rounded-lg h-fit`}>
                <p className="text-xl text-gray-900 font-sans">{t("complete_publish_portfolio")}</p>
                <p className="text-base text-gray-900 font-sans mt-4">{t("complete_portfolio_p1")}</p>
                <p className="text-base text-gray-900 font-sans mt-4">{t("complete_portfolio_p2")}</p>
                <button className="flex md:hidden mt-4 justify-between gap-2 text-orange-500" onClick={() => setOpenCollapse(!openCollapse)}>
                    {t("showmoreHome")}
                    <FontAwesomeIcon icon={openCollapse ? "chevron-up" : "chevron-down"} className="h-3 mr-2" />
                </button>
                <div className={`${openCollapse ? "flex flex-col" : "hidden"}`}>
                    <div className="flex md:hidden w-[30%] mx-auto my-8">
                        <CircularProgressbar
                            value={complete.completionPercentage ?? 0}
                            text={`${complete.completionPercentage ?? 0}%`}
                            styles={buildStyles({
                                pathColor: "#F5BA56",
                                textColor: "#625199",
                                trailColor: "#e5e5e5",
                                textSize: "18px",
                            })}
                        />
                    </div>
                    <hr className="flex md:hidden" />
                    <div className="flex md:hidden justify-start items-start flex-col gap-1 mt-4 text-left mb-4">
                        <CompletionDetails {...complete} />
                    </div>
                    <hr className="flex md:hidden" />
                    <div className="flex md:hidden justify-center items-center mt-4">
                        <Link to={`/portfolio/edit/about`}>
                            <GradientButton text={t("complete_portfolio")} />
                        </Link>
                    </div>
                </div>
            </div>
            {general && general.me && !general.me.verified && (
                <div className={`${myActiveIndex === 1 ? "flex" : "hidden"} flex flex-col w-full bg-gray-50 p-5 pt-10 rounded-lg h-fit`}>
                    <p className="text-xl text-gray-900 font-sans">{t("verify_account")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4">
                        {t("verify_account_p1")} <FontAwesomeIcon icon="circle-check" className="text-orange-500" /> {t("verify_account_p2")}
                    </p>
                    <Link to="/account/data" className="w-fit mx-auto mt-4">
                        <GradientButton text={t("verify_account_btn")} />
                    </Link>
                </div>
            )}
            {(plan !== "PRO") && (
                <div className={`${myActiveIndex === 2 ? "flex" : "hidden"} flex flex-col w-full bg-gray-50 p-5 pt-10 rounded-lg h-fit`}>
                    <p className="text-xl text-gray-900 font-sans">{t("next_level")}</p>
                    <p className="text-base text-gray-900 font-sans mt-4" dangerouslySetInnerHTML={{ __html: t(plan !== "PREMIUM" ? "next_level_d" : "next_level_d_pro")}}></p>
                    <div className="flex flex-col gap-2 justify-around items-center">
                        {plan !== "PREMIUM" && (
                            <Link to="/subscription/premium" className="w-fit mx-auto mt-4">
                                <button className="rounded-full border border-purple-500 text-purple-500 py-2 px-4">{t("next_level_btn_p1")}</button>
                            </Link>
                        )}
                        <Link to="/subscription/pro" className="w-fit mx-auto ">
                            <button className="rounded-full bg-purple-500 py-2 px-4 text-white">{t("next_level_btn_p2")}</button>
                        </Link>
                    </div>
                    <HashLink to="/tarifs#detailTarifs">
                        <p className="text-sm text-gray-900 font-sans mt-4 text-center">
                            {t("next_level_a")} <FontAwesomeIcon icon="chevron-right" className="h-3" />
                        </p>
                    </HashLink>
                </div>
            )}
            <div className={`${(plan === "PRO") ? myActiveIndex === 2 ? "flex" : "hidden" : myActiveIndex === 3 ? "flex" : "hidden"} flex flex-col w-full bg-gray-50 pt-10 px-5 rounded-lg h-fit`}>
                <p className="text-xl text-gray-900 font-sans mb-4">{t("photographers_around")}</p>
                {photographers && photographers.data && photographers.data.length > 0 ? (
                    <SwiperPhotograph
                        galeries={photographers.data}
                    />
                ) : (
                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                        <FontAwesomeIcon icon="user" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                        <Typography className="text-base text-gray-700 mb-4">{t("noPhotographers")}</Typography>
                    </div>
                )}
            </div>
        </Carousel>
    );
}

export function CompletionDetails(props: Readonly<{ hasProfilePicture: boolean; hasBanner: boolean; hasPublishedPhotos: boolean; hasAbout: boolean; hasPublishedPortfolio: boolean; hideFomalities?: boolean }>) {

    const { t } = useTranslation();

    return <>
        {!props.hideFomalities &&
            <p className="text-base text-gray-900 font-sans ">
                <FontAwesomeIcon icon={"check-circle"} className={`mr-2 text-orange-800`} />
                {t("task_account")}
            </p>
        }
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasProfilePicture ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasProfilePicture ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_pp")}
        </p>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasBanner ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasBanner ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_banner")}
        </p>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasPublishedPhotos ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasPublishedPhotos ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_upload")}
        </p>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasAbout ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasAbout ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_desc")}
        </p>
        {(!props.hideFomalities) &&
            <p className="text-base text-gray-900 font-sans mt-4">
                <FontAwesomeIcon
                    icon={props.hasPublishedPortfolio ? "check-circle" : "circle"}
                    className={`mr-2 ${props.hasPublishedPortfolio ? "text-orange-800" : "text-gray-300"}`} />
                {t("task_published")}
            </p>
        }
    </>;
}


export function CompletionDetailsGallery(props: Readonly<{ hasBanner: boolean; hasTitle: boolean; hasPictures: boolean; }>) {
    const { t } = useTranslation();

    return <>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasTitle ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasTitle ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_title_gallery")}
        </p>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasBanner ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasBanner ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_banner_gallery")}
        </p>
        <p className="text-base text-gray-900 font-sans mt-4">
            <FontAwesomeIcon
                icon={props.hasPictures ? "check-circle" : "circle"}
                className={`mr-2 ${props.hasPictures ? "text-orange-800" : "text-gray-300"}`} />
            {t("task_pictures_gallery")}
        </p>
    </>;
}
