import { Collapse } from "@material-tailwind/react";
import TitleDescribe from "./components/TitleDescribe";
import CheckBoxDescribe from "./components/CheckBoxDescribe";
import IconOpen from "./components/IconOpen";
import { useRef } from "react";
import { t } from "i18next";

export default function Identification({
    toggleOpenIdentifiable,
    openIdentifiable,
    identification,
    setIdentification,
    writtenAccords,
    existants = 0,
    setWrittenAccords,
    withOpeners = true,
    nbrAlreadyImported
}: Readonly<{
    toggleOpenIdentifiable: () => void;
    openIdentifiable: boolean;
    identification: {
        people: boolean;
        properties: boolean;
        writtenContract: boolean;
    };
    setIdentification: Function;
    writtenAccords: File[];
    existants?: number;
    setWrittenAccords: Function;
    withOpeners?: boolean;
    nbrAlreadyImported?: number;
}>) {
    const inputFile = useRef<HTMLInputElement>(null);

    const sendNewAccords = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    const newUpload = () => {
        if (inputFile.current) {
            if (writtenAccords.length > 0) {
                const olds = writtenAccords;
                const files = inputFile.current.files;
                if (files) {
                    const news = Array.from(files);
                    setWrittenAccords(olds.concat(news));
                } else {
                    setWrittenAccords(olds);
                }
            } else {
                const files = inputFile.current.files;
                if (files) {
                    setWrittenAccords(Array.from(files));
                }
            }
        }
    };

    return (
        <div className=" pb-4 border-b border-gray-300">
            <button onClick={toggleOpenIdentifiable} className="flex flex-row justify-between items-center w-full mt-4 text-left">
                <TitleDescribe title="people_ident" describe="people_prop_ident" />
                {withOpeners && <IconOpen open={openIdentifiable} />}
            </button>
            <Collapse open={openIdentifiable} className="flex flex-col gap-4 pt-2">
                <div className="flex flex-col pl-1">
                    <CheckBoxDescribe
                        label="people_ident"
                        name="people_ident"
                        describe="people_ident_d"
                        checked={identification.people}
                        index={0}
                        setIdentification={() => setIdentification("people", !identification.people )}
                    />
                    <CheckBoxDescribe
                        label="prop_ident"
                        name="prop_ident"
                        describe="prop_ident_d"
                        checked={identification.properties}
                        index={1}
                        setIdentification={() => setIdentification("properties", !identification.properties )}
                    />
                    <CheckBoxDescribe
                        label="written_auth"
                        name="written_auth"
                        describe="written_auth_d"
                        checked={identification.writtenContract}
                        index={2}
                        setIdentification={() => setIdentification("writtenContract", !identification.writtenContract )}
                    />
                    <button className={`${identification.writtenContract ? nbrAlreadyImported && nbrAlreadyImported > 0 ? "h-20" :  "h-16" : "h-0 overflow-hidden"} transition-all cursor-pointer`} onClick={() => sendNewAccords()}>
                        <input ref={inputFile} type="file" className="hidden" multiple onChange={(e) => newUpload()} />
                        <div className="flex flex-col items-center text-sm py-2 px-3 border rounded mt-2">
                            <p className="text-gray-900">{t("upload_files")}</p>
                            <p className="text-xs text-gray-500">{t("selected_files", { count: writtenAccords.length })}</p>
                            {(nbrAlreadyImported && nbrAlreadyImported > 0) ? <p className="text-xs text-gray-500">{t("existing_files", { count: nbrAlreadyImported })}</p> : <></>}
                        </div>
                    </button>
                </div>
            </Collapse>
        </div>
    );
}
