import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, Popover, PopoverContent, PopoverHandler, Button, List } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import ContractsAPI from "../../../../../services/API/Clients/ContractAPI";
import { Storage } from "../../../../../services/storage";
import { useEffect, useState } from "react";
import { handleError } from "../../../../../services/Errors/handleErrors";

export default function SelectLicence({ handleSelectLicense }: Readonly<{ handleSelectLicense: (license: any) => void }>) {
    const [liste, setListe] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [popup, setPopup] = useState<Window | null>(null);
    const [isPopupClosed, setIsPopupClosed] = useState(false);

    const openPopup = () => {
        const newPopup = window.open(`/contracts`, 'popup', 'width=600,height=400');
        setPopup(newPopup);
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        if (popup) {
            interval = setInterval(() => {
                if (popup.closed) {
                    setIsPopupClosed(true);
                    if (interval) clearInterval(interval);
                }
            }, 1000);
        }
    
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [popup]);
    
    useEffect(() => {
        if (isPopupClosed) {
            getMyLicenses(true);
        }
    }, [isPopupClosed]);

    useEffect(() => {
        getMyLicenses();
    }, [open]);

    const getMyLicenses = async (isSelecter = false) => {
        const myId = Storage.getId();
        const response = await ContractsAPI.contracts(myId ?? "");

        if (response.status === 200) {
            const transformedData = response.body.data.map((item: { id: number; name: string }) => ({
                id: item.id,
                name: item.name,
            }));
            setListe(transformedData);
            if (isSelecter) {
                handleSelectLicense(transformedData[0]);
            }
        } else {
            handleError(response);
        }
    }

    const { t } = useTranslation();
    return (
        <Popover placement="top">
            <PopoverHandler onClick={() => setOpen(!open)}>
                <Button onClick={() => setOpen(!open)} color="white" className="flex flex-row items-center gap-2 font-sans w-full justify-center hover:bg-gray-200 transition-all">
                    <FontAwesomeIcon icon="file-invoice" />
                    <p className="mt-0.5 font-sans">{t("addlicense")}</p>
                </Button>
            </PopoverHandler>
            <PopoverContent className="p-0 z-40">
                <List>
                    <ListItem className="flex flex-row items-center gap-2" onClick={() => openPopup()}>
                        <FontAwesomeIcon icon="plus" className="h-4 w-4 " /> {t("createlicense")}
                    </ListItem>
                    {liste && liste.map((license) => (
                        <ListItem
                            onClick={() => {
                                handleSelectLicense(license);
                            }}
                            key={license.id}
                        >
                            {license.name}
                        </ListItem>
                    ))}
                </List>
            </PopoverContent>
        </Popover>
    );
}
