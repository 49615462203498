import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ModalChangePlan from "../../../../components/Modals/ModalChangePlan";
import ContractsAPI from "../../../../services/API/Clients/ContractAPI";
import { Storage } from "../../../../services/storage";
import CardSell from "./components/CardSell";
import LinkGeneric from "./components/LinkGeneric";
import SelectLicence from "./components/SelectLicence";
import TitleDescribe from "./components/TitleDescribe";
import TitleHelper from "./components/TitleHelper";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function Sell({
    openSell,
    toggleOpenSell,
    selectedLicense,
    setSelectedLicense,
    general,
    withOpeners = true,
    showAlert = false,
    fileUpload,
    setFileUpload,
}: Readonly<{ 
    openSell: boolean; 
    toggleOpenSell: () => void; 
    selectedLicense: any; 
    setSelectedLicense: Function; 
    general: any; 
    withOpeners?: boolean,
    showAlert?: boolean,
    fileUpload?: File | null;
    setFileUpload?: Function
}>) {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const inputFile = React.useRef<HTMLInputElement>(null);

    const sendNewAccords = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    }

    const newUpload = () => {
        if (inputFile.current) {
            const files = inputFile.current.files;
            if (files && setFileUpload) {
                setFileUpload(files[0]);
            }
        }
    }

    const handleSelectLicense = async (license: any) => {
        const myId = Storage.getId();
        const response = await ContractsAPI.contracts(myId ?? "");

        if (response.status === 200) {
            const selected = response.body.data.find((item: any) => item.id === license.id);
            setSelectedLicense(selected);
        } else {
            handleError(response);
        }
    };

    const handleRemoveLicense = () => {
        setSelectedLicense({ id: 0, name: "", limit: "", exclusivity: "", commercial: "", price: "" });
    };

    return (
        <div className=" pb-4 border-b border-gray-300">
            <ModalChangePlan open={openModal} setOpen={setOpenModal} />
            <button onClick={toggleOpenSell} className="flex flex-row justify-between items-center w-full mt-4 text-left">
                <TitleHelper title="selling" infobulle="selling_i" description="selling_d" />
                {withOpeners && (
                    <div className="flex w-fit">
                        <FontAwesomeIcon icon={openSell ? "chevron-up" : "chevron-down"} className="text-gray-500" />
                    </div>
                )}
            </button>
            <LinkGeneric link="/CGU#venteOctroiLicence" title="learnmoresl" internLink={true} />
            <Collapse open={openSell} className="flex flex-col gap-4 pt-2">
                <div className="flex flex-col gap-2 items-center">
                    {showAlert && (
                        <div className="flex flex-col">
                            <div className={`flex bg-orange-50/20 text-sm text-black px-4 py-3 font-sans rounded-lg`} role="alert">
                                <p className="flex flex-col">
                                    <span dangerouslySetInnerHTML={{ __html: t("import_back_d") }} />
                                </p>
                            </div>
                            <button className={`h-16 transition-all cursor-pointer`} onClick={() => sendNewAccords()}>
                                <input ref={inputFile} type="file" className="hidden" multiple onChange={(e) => newUpload()} />
                                <div className={` flex flex-col items-center text-sm py-2 px-3 border rounded mt-2`}>
                                    <p className="text-gray-900">{t("upload_files_picture")}</p>
                                    <p className="text-xs text-gray-500">{t("selected_files", { count: fileUpload ? 1 : 0 })}</p>
                                </div>
                            </button>
                        </div>
                    )}
                    {!selectedLicense || selectedLicense === undefined || selectedLicense.id === undefined || selectedLicense.id === 0 ? (
                        <div className="w-full h-full relative">
                            <SelectLicence handleSelectLicense={handleSelectLicense} />
                        </div>
                    ) : (
                        <CardSell selectedLicense={selectedLicense} handleRemoveLicense={handleRemoveLicense} setSelectedLicense={setSelectedLicense} />
                    )}
                </div>
            </Collapse>
        </div>
    );
}
