export const ContactCustomStyle = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(98, 81, 153)" : "",
        borderColor: state.isFocused ? "rgb(98, 81, 153)" : state.isDisabled ? "rgb(236, 239, 241)" : "rgb(176, 190, 197)",
        backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "white",
        color: state.isDisabled ? "rgb(69, 90, 100)" : "rgb(128, 128, 128)",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(98, 81, 153)" : "rgb(176, 190, 197)",
        },
        fontSize: "14px"
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        backgroundColor: state.isSelected ? "rgb(98, 81, 153)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            backgroundColor: state.isSelected ? "rgb(98, 81, 153)" : "lightgray",
            color: "black",
        },
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
};