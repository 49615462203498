import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faPen, faTrash, faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverContent, PopoverHandler, Tooltip, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultBannerEzoom from "../../../assets/images/elements/default-banner.png";
import { usePictureModal } from "../../../providers/PictureModalProvider";
import FavoriteAPI from "../../../services/API/Clients/FavoriteAPI";
import PicturesAPI from "../../../services/API/Clients/PicturesAPI";
import { Storage } from "../../../services/storage";
import { PictureSearch } from "../../Interfaces/PictureType";
import ModalNsfw from "../../Modals/ModalNsfw";
import { usePictureEdit } from "../../../providers/PictureEditProvider";
import { useCart } from "../../../providers/CartProvider";
import ModalSignInUp from "../../Modals/ModalSignInUp";
import { handleBuyIt } from "./components/PictureModalBuyIt";
import { handleDelete } from "./components/PictureModalDelete";
import { handleError } from "../../../services/Errors/handleErrors";
import { useNavigate } from "react-router-dom";

interface PictureAloneProps {
    width: number;
    height: number;
    margin?: string;
    data: {
        id: string;
        nsfw: boolean;
        watermarkUptoDate: boolean;
        canBeBought: boolean;
        validationStatus: string;
        portfolio: {
            id: string;
            name: string;
            plan: string;
            isSubscribed: boolean;
        };
        minPath: string;
        isFavorited: boolean;
        isOwned: boolean;
    };
    index: number;
    heart: boolean;
    printNsfw: boolean;
    handleChangeNsfw: (arg: boolean) => void;
    pictures: PictureSearch[];
    loadMore: () => void;
}

export default function PictureAlone({
    width,
    height,
    margin,
    data,
    heart,
    printNsfw,
    handleChangeNsfw,
    pictures,
    loadMore,
}: Readonly<PictureAloneProps>) {
    const [heartColor, setHeartColor] = React.useState(data.isFavorited);
    const [hoverActive, setHoverActive] = React.useState(false);
    const [userId, setUserId] = React.useState<string | null>(null);
    const [openNsfw, setOpenNsfw] = React.useState(false);
    const [openInvitation, setOpenInvitation] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setUserId(Storage.getId());
        if (Storage.getCookie() === "true") handleChangeNsfw(true);
        else handleChangeNsfw(false);
    }, []);

    useEffect(() => {
        setUserId(Storage.getId());
        if (Storage.getCookie() === "true") handleChangeNsfw(true);
        else handleChangeNsfw(false);
    }, [openNsfw]);

    const handleLoveOrUnlove = async (id: string, isHeart: boolean) => {
        if (!userId) {
            setOpenInvitation(true)
            return;
        }

        setHeartColor(!heartColor);
        const data = {
            picture: `/api/public/pictures/${id}`,
            owner: `/api/users/${userId}`,
        };
        if (isHeart) {
            const response = await FavoriteAPI.fav(data);
            if (response.status === 200 || response.status === 201) toast.success(t("addToFav"));
            else handleError(response);
            setHeartColor(!heartColor);
        } else {
            const response = await FavoriteAPI.unFav(data);
            if (response.status === 200 || response.status === 201 || response.status === 204) toast.success(t("removeFromFav"));
            else handleError(response);
            setHeartColor(!heartColor);
        }
    };

    const { addToCart } = useCart();

    const handleAddToCart = async () => {
        if (!userId) {
            setOpenInvitation(true)
            return;
        }
        const response = await PicturesAPI.getPicture(data.id);
        if (response.status === 200) {
            const price = response.body.contract.basePrice;
            addToCart({ minPath: data.minPath, id: data.id, price: price });
        } else {
            handleError(response);
        }
    };

    const handleOpenNsfw = (arg: boolean) => {
        setOpenNsfw(arg);
        if (Storage.getCookie() === "true") handleChangeNsfw(true);
        else handleChangeNsfw(false);
    };

    const { open, setOpen } = usePictureModal();

    const { setEditedPicture } = usePictureEdit();

    const onEditPicture = () => {
        setEditedPicture({ id: data.id });
    }

    const handleToBuy = async () => {
        const response = await PicturesAPI.getPicture(data.id);
        if (response.status === 200) {
            handleBuyIt(handleAddToCart, data.id, response.body, t)
        } else {
            handleError(response);
        }
    }

    useEffect(() => {
        const deleteSuccess = Storage.getDelete();
        if (deleteSuccess) {
            toast.success(t("deleteSuccess"));
            Storage.clearDelete();
        }
    }, [t]);

    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <ModalNsfw open={openNsfw} setOpen={handleOpenNsfw} />
            <button
                className={`block relative`}
                onMouseEnter={() => setHoverActive(true)}
                onMouseLeave={() => setHoverActive(false)}
                style={{ margin, padding: 4, width: `${width}px`, height: `${height}px` }}
                onClick={(e) => {
                    if (data.nsfw && !printNsfw) {
                        return
                    }
                    const target = e.target as HTMLElement;
                    if (target.closest(".picture-interactions")) return;

                    setOpen({
                        id: data.id,
                        context: pictures,
                        loadMore: loadMore
                    })
                }}
            >
                <img
                    alt={`picNumber-${data.id}`}
                    className="block w-full rounded-lg object-cover object-center transition-all"
                    src={data.minPath === "https://ezoomstorage.s3.eu-central-2.amazonaws.com/path/to/min/picture" ? DefaultBannerEzoom : data.minPath}

                />
                {data.nsfw && !printNsfw && (
                    <button className={`absolute top-0 left-0 w-full h-full opacity-100 transition-all`} onClick={() => handleOpenNsfw(true)}>
                        <div
                            className={`${hoverActive ? "opacity-100" : "opacity-0"
                                } absolute bottom-0 z-10 h-full w-full flex flex-col gap-2 justify-center items-center transition-all`}
                        >
                            <FontAwesomeIcon icon="eye-slash" className="text-white text-4xl" />
                            <Typography className="text-white text-2xl">{t("nsfw")}</Typography>
                        </div>
                        <div className="absolute bottom-0 w-full h-full rounded-lg bg-gradient-to-b backdrop-blur-lg"></div>
                    </button>
                )}
                {heart && (
                    <>
                        <div className={`absolute top-0 left-0 w-full h-full ${hoverActive ? "opacity-100" : "opacity-0"} transition-all`}>
                            <div className="absolute bottom-0 w-full h-1/2 bg-gradient-to-b from-white/0 to-black/20 rounded-b-lg"></div>
                        </div>
                        <div className={`${hoverActive ? "flex" : "hidden"} absolute bottom-2 left-3 w-full`}>
                            <div className="text-white font-sans font-bold text-ellipsis overflow-hidden line-clamp-1 w-[65%] text-left">
                                {data?.portfolio?.name}
                            </div>
                        </div>
                        <div className={`${hoverActive ? "flex" : "hidden"} absolute bottom-0 right-1 flex flex-row gap-3 p-1 md:p-2 picture-interactions`}>
                            {data.canBeBought && !data.isOwned && (
                                <button onClick={() => !Storage.getToken() ? setOpenInvitation(!openInvitation) : handleToBuy() }>
                                    <FontAwesomeIcon icon="shopping-cart" className="text-orange-500 hover:text-orange-100 transition-all text-xl" />
                                </button>
                            )}
                            {(!data.watermarkUptoDate || data.validationStatus === "pending") && (
                                <Tooltip 
                                    placement="bottom" 
                                    className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                                    content={
                                        <div className="w-80">
                                            <Typography
                                                variant="small"
                                                className="font-normal opacity-80 text-black"
                                            >
                                                {!data.watermarkUptoDate ? t("creating_watermark") : t("waiting_validation_d")}
                                            </Typography>
                                        </div>
                                    }
                                >
                                        <FontAwesomeIcon icon="clock" className="text-white text-xl hover:opacity-70 transition-all" />
                                </Tooltip>
                            )}
                            {data.validationStatus === "rejected" && (
                                <Tooltip 
                                    placement="bottom" 
                                    className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                                    content={
                                        <div className="w-80">
                                            <Typography
                                                variant="small"
                                                className="font-normal opacity-80 text-black"
                                            >
                                                {t("photo_refused_small")}
                                            </Typography>
                                        </div>
                                }>
                                    <FontAwesomeIcon icon="triangle-exclamation" className="text-white text-xl hover:opacity-70 transition-all" />
                                </Tooltip>
                            )}
                            {!data.isOwned && (
                                <button
                                    onClick={() => {
                                        handleLoveOrUnlove(data.id, !heartColor);
                                    }}
                                >
                                    <FontAwesomeIcon icon={heartColor ? solidHeart : regularHeart} className="text-white text-xl hover:opacity-70 transition-all" />
                                </button>
                            )}
                            {data.isOwned && (
                                <>
                                    <button
                                        onClick={onEditPicture}
                                    >
                                        <FontAwesomeIcon icon={faPen} className="text-white text-xl hover:opacity-70 transition-all" />
                                    </button>
                                    <button
                                        onClick={() => handleDelete(data.id, t, navigate)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="text-white text-xl hover:opacity-70 transition-all" />
                                    </button>
                                </>
                            )}
                        </div>
                    </>
                )}
            </button >
        </>
    );
}
