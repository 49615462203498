import APIClient from "../APIClients";

export default class SubscriptionAPI {
    public static async subscriptions(data: {}) {
        return APIClient.ezoom.post(`/subscriptions`, data);
    }

    public static async subscribeTransaction(data: {}) {
        return APIClient.ezoom.post("/subscription-transactions", data);
    }

    public static async subscribeTransactionAssert(id: string, data: {}) {
        return APIClient.ezoom.put(`/subscription-transactions/${id}/assert`, data);
    }

    public static async getPdfSubscription(id: string) {
        return APIClient.ezoom.getPdf(`/subscription-transactions/${id}/document`);
    }

    public static async getVoucherCode() {
        return APIClient.ezoom.get(`/vouchers`);
    }
}
