import { Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterStoreInterface } from "../../services/redux/reducers/filtersReducer";
import SwipeGalery from "../Box/Gallery/SwipeGalery";
import { Galleries, SearchGalleriesType } from "../Interfaces/PortfolioType";
import { FilterConfiguration } from "./filters/FilterConfiguration";
import Filters from "./filters/Filters";
import { CompatibleFilterList, FetchFiltersFunction, FetchResultFunction, FilterList, FilterRenderConfiguration, FilterSchema, SortOption } from "./types/global";

interface SearchResultProps<FiltersType extends FilterList, Entity, CompatibleFiltersType extends CompatibleFilterList> {
    filtersSchema: FilterSchema<FiltersType>
    filters: boolean
    filtersRender: FilterRenderConfiguration<CompatibleFiltersType>
    fetchResults: FetchResultFunction<FiltersType, Entity>
    fetchGalleries?: FetchResultFunction<FiltersType, Galleries>
    fetchFilters: FetchFiltersFunction<FiltersType, CompatibleFiltersType>
    renderEntity?: (entity: Entity, index: number, context: Entity[], loadMore: () => void) => JSX.Element
    renderParent?: (entities: Entity[], loadMore: () => void) => JSX.Element
    onInit?: (loadMoreMethod: () => void) => void
    onNewResults?: (results: Entity[]) => void
    NoResults: JSX.Element
    filterOverrides?: FiltersType
    sortOptions: SortOption[]
    defaultSort?: string
}

interface PageSettings<Entity> {
    result: Entity[]
    galleries?: SearchGalleriesType
    page: number
    lastPage?: number

    loading: boolean
}

const defaultPageSettings: PageSettings<any> = {
    result: [],
    page: 1,

    loading: false,
}

function SearchResult<FiltersType extends FilterList, Entity, CompatibleFiltersType extends CompatibleFilterList>(props: Readonly<SearchResultProps<FiltersType, Entity, CompatibleFiltersType>>) {

    const navigate = useNavigate();

    const filters: FilterStoreInterface = useSelector((state: any) => state.filters);
    const dispatch = useDispatch();

    const filterConfiguration = new FilterConfiguration<FiltersType>(navigate, filters, dispatch, props.filterOverrides, props.defaultSort);

    const [page, setPage] = useState<PageSettings<Entity>>(defaultPageSettings);
    const [isInit, setIsInit] = useState(false);

    const updatePage = (newPage: Partial<PageSettings<Entity>>) => {
        setPage({ ...page, ...newPage });
    }

    const getResults = async (pageNo: number, loadFilters: boolean = false) => {
        const entities = await props.fetchResults({ filters: filterConfiguration, page: pageNo });
        if (!entities) return;

        const galleries = {};
        if (pageNo === 1) {
            page.result = [];

            if (props.fetchGalleries) {
                const galleriesRes = await props.fetchGalleries({ filters: filterConfiguration, page: pageNo });

                if (galleriesRes)
                    (galleries as any).galleries = galleriesRes;
            }
        }

        updatePage({
            result: [...page.result, ...entities.data],
            lastPage: entities.pagination.last,
            page: pageNo,
            ...galleries,
            loading: false
        });
    }

    const query = new URLSearchParams(useLocation().search);
    const pageQuery = query.get('query');

    useEffect(() => {
        filterConfiguration.initStore(props.filtersSchema);
        if (props.onInit) props.onInit(() => getResults(page.page + 1));
        setIsInit(true);
    }, [pageQuery]);

    useEffect(() => {
        if (!isInit) return;
        if (!filters.currentFilters) return;
        filterConfiguration.updateUrl();
        getResults(1, true);
        updatePage({
            loading: true,
            result: []
        })
    }, [filters.currentFilters, filters.sortBy, isInit]);

    const nextPage = () => {
        getResults(page.page + 1);
    }

    useEffect(() => {
        if (props.onNewResults) props.onNewResults(page.result);
    }, [page.result]);

    return (
        <div>
            {props.filters && <Filters
                filters={filterConfiguration}
                renderConfiguration={props.filtersRender}
                fetchFilters={props.fetchFilters}
                sortOptions={props.sortOptions}
                defaultSort={props.defaultSort}
            />}
            {page.galleries && <SwipeGalery
                galeries={page.galleries}
            />
            }
            {page.result.length !== 0 ? (
                <InfiniteScroll
                    loadMore={nextPage}
                    hasMore={page.lastPage !== undefined && page.page < page.lastPage}
                    loader={
                        <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                            <Spinner
                                color="orange"
                                className="mt-12 text-4xl text-gray-500/50 mb-4"
                            />
                        </div>
                    }
                    className={props.renderParent
                        ? "w-full pt-4 md:pt-2" :
                        "flex flex-wrap justify-center gap-4 md:gap-8 w-full pt-4 md:pt-2"
                    }
                >
                    {props.renderEntity && page.result?.map((v, i) => props.renderEntity && props.renderEntity(v, i, page.result, nextPage))}
                    {props.renderParent && page.result && props.renderParent(page.result, nextPage)}
                </InfiniteScroll>
            ) : (<div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                {page.loading ?
                    <Spinner
                        color="orange"
                        className="mt-12 text-4xl text-gray-500/50 mb-4"
                    />
                    : props.NoResults
                }
            </div>
            )}
        </div>);
}

export default SearchResult;