interface GalleriesProps {
    gallery: string;
    index: number;
    banner?: {
        minPath?: string;
    },
    bannerOffset?: number;
    id: string;
    title: string;
}

interface PicturesProps {
    minPath: string;
    id: string;
}

interface BannerProps {
    id: string;
    minPath: string;
}

export interface PerformanceProps {
    title: string;
    description: string;
    priceType: string;
    priceValue: number;
    priceCeil: number;
    type: string;
    pictures: PicturesProps[];
    galleries: GalleriesProps[];
    bannerOffset: number;
    banner: BannerProps;
    selectorPrices: string
    keywords: string[]
}

export const updateTitle = (name: string): any => ({
    type: "UPDATE_PERF_TITLE",
    payload: name,
});

export const updateDescription = (description: string): any => ({
    type: "UPDATE_PERF_DESCRIPTION",
    payload: description,
});

export const updatePriceType = (priceType: string): any => ({
    type: "UPDATE_PERF_PRICETYPE",
    payload: priceType,
});

export const updatePriceValue = (priceValue: number): any => ({
    type: "UPDATE_PERF_PRICEVALUE",
    payload: priceValue,
});

export const updatePriceCeil = (priceCeil: number): any => ({
    type: "UPDATE_PERF_PRICECEIL",
    payload: priceCeil,
});

export const updateType = (type: string): any => ({
    type: "UPDATE_PERF_TYPE",
    payload: type,
});

export const updatePictures = (pictures: PicturesProps[]): any => ({
    type: "UPDATE_PERF_PICTURES",
    payload: pictures,
});

export const updateGalleries = (galleries: GalleriesProps[]): any => ({
    type: "UPDATE_PERF_GALLERIES",
    payload: galleries,
});

export const updateBanner = (banner: BannerProps): any => ({
    type: "UPDATE_PERF_BANNER",
    payload: banner,
});

export const updateSelectorPrices = (selectorPrices: string): any => ({
    type: "UPDATE_PERF_SELECTOR_PRICES",
    payload: selectorPrices,
});

export const updateKeywords = (keywords: { value: string; label: string; type: string }[]): any => ({
    type: "UPDATE_PERF_KEYWORDS",
    payload: keywords,
});

export const updateBannerOffset = (bannerOffset: number): any => ({
    type: "UPDATE_PERF_BANNER_OFFSET",
    payload: bannerOffset,
});

export const updatePerformances = (performance: PerformanceProps): any => ({
    type: "UPDATE_PERF_PERFORMANCE",
    payload: performance,
});

export const updateResetPerformance = (): any => ({
    type: "RESET_PERFORMANCE",
});