import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Menu, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DefaultPictureEzoom from "../../../../assets/images/elements/default-profile.png";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import SharingBox from "../../../../components/Box/SharingBox";
import { Storage } from "../../../../services/storage";
import { NavigationCustomStyles } from "../../../../components/CustomStyles/NavigationCustomStyles";
import PortfolioAPI from "../../../../services/API/Clients/PortfolioAPI";
import { toast } from "react-toastify";
import { updatePortfolio, updateRepartition, updateSubscribed } from "../../../../services/redux/actions/formAction";
import { OpenReportPortfolio } from "../../../../components/Modals/ModalPortfolioReport";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { PictureDrawerForFilters } from "../../../../components/SearchResult/impl/PictureResult";
import MobileFilterButton from "../../../../components/SearchResult/mobile/MobileFilterButton";
import { RootState } from "../../../../services/redux/store";
import UserAPI from "../../../../services/API/Clients/UserAPI";
import { handleError } from "../../../../services/Errors/handleErrors";

export default function HeaderMobile({
    searchBar,
    actualUrl,
    isPersonnal,
    handleLinkClick,
}: Readonly<{ searchBar: boolean; actualUrl: string; isPersonnal: boolean; handleLinkClick: (url: string) => void }>) {
    const [sortBy, setSortBy] = React.useState("");
    const [hidden, setHidden] = React.useState(false);
    const { t } = useTranslation();
    const { id, page } = useParams();
    const portfolio = useSelector((state: RootState) => state.form);
    const myId = Storage.getId();
    const dispatch = useDispatch();

    const chooseUrl = () => {
        if (isPersonnal) {
            switch (window.location.pathname.split("/")[2]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[3]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        } else {
            switch (window.location.pathname.split("/")[3]) {
                case "photos":
                    return `/portfolio/edit/photos`;
                case "about":
                    return `/portfolio/edit/about`;
                case "performances":
                    return `/portfolio/edit/performances`;
                case "performance":
                    return `/portfolio/edit/performance/${window.location.pathname.split("/")[4]}`;
                case "opinion":
                    return `/portfolio/edit/photos`;
                case "contact":
                    return `/portfolio/edit/photos`;
                default:
                    return `/portfolio/edit/photos`;
            }
        }
    };

    const featurePages = portfolio?.features?.pages;

    const MenuList = Object.entries(featurePages ?? {})
        .filter(([key, value]) => value) // Filtrer uniquement les clés avec des valeurs true
        .map(([key, _]) => {
            let item = {};

            if (key === "photos") {
                item = {
                    value: key,
                    url: isPersonnal ? `/${portfolio.path}/about` : `/portfolio/${id}/${key}`,
                    label: `${t("photos")} (${portfolio.pictures.pagination.totalItems ?? 0})`,
                };
            } else if (key === "services") {
                item = {
                    value: "performances",
                    url: isPersonnal ? `/${portfolio.path}/performances` : `/portfolio/${id}/performances`,
                    label: `${t("performances")} (${portfolio?.services.pagination.totalItems ?? 0})`,
                };
            } else if (key === "testimonials") {
                item = {
                    value: "opinion",
                    url: isPersonnal ? `/${portfolio.path}/opinion` : `/portfolio/${id}/opinion`,
                    label: `${t("opinion")} (${portfolio.opinions.data.length ?? 0})`,
                };
            } else {
                item = {
                    value: key,
                    url: isPersonnal ? `/${portfolio.path}/${key}` : `/portfolio/${id}/${key}`,
                    label: t(key),
                };
            }

            return item;
        });

    const followPortfolio = async () => {
        if (myId) {
            const datas = {
                owner: `/api/users/${myId}`,
                portfolio: `/api/public/portfolios/${id}`,
            };
            if (portfolio.isSubscribed) {
                const responseUnfollow = await PortfolioAPI.unfollow(datas);
                if (responseUnfollow.status === 200 || responseUnfollow.status === 201 || responseUnfollow.status === 204) {
                    toast.success(t("portfolio_unfollow_success"));
                    dispatch(updateSubscribed(false));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseUnfollow);
                }
            } else {
                const responseFollow = await PortfolioAPI.follow(datas);
                if (responseFollow.status === 200 || responseFollow.status === 201) {
                    toast.success(t("portfolio_follow_success"));
                    dispatch(updateSubscribed(true));
                    const regetPortfolio = await PortfolioAPI.portfolio(portfolio.id);
                    if (regetPortfolio.status === 200) {
                        dispatch(updatePortfolio(regetPortfolio.body));
                    } else {
                        handleError(regetPortfolio);
                    }
                    const regetRepas = await PortfolioAPI.getRepartition(portfolio.id);
                    if (regetRepas.status === 200) {
                        dispatch(updateRepartition(regetRepas.body));
                    } else {
                        handleError(regetRepas);
                    }
                } else {
                    handleError(responseFollow);
                }
            }
        }
    };

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (latest: number) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 150) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    return (
        <>
            <PictureDrawerForFilters />
            <div
                className="flex md:hidden relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (portfolio.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${portfolio.banner ?? DefaultBannerEzoom})`,
                }}
            ></div>

            <div className="md:hidden sticky md:static h-14 md:h-0 bg-white z-30 px-1 pb-2 md:pb-0 md:overflow-hidden flex flex-row justify-between items-end">
                <div className="relative h-12 pt-1">
                    <div className="flex flex-row gap-4 items-center mt-1">
                        <div className="w-12 h-12 bg-white rounded-full">
                            <img
                                alt="vector"
                                className="w-full h-full object-cover object-center rounded-full border-4 border-gray-50"
                                src={portfolio.loading ? DefaultPictureEzoom : portfolio.picture ?? DefaultPictureEzoom}
                            />
                        </div>
                        <div className="flex flex-col justify-center">
                            <Typography className="text-xl font-bold flex items-center gap-4">
                                {portfolio.name}
                                {portfolio.owner.verified && (<FontAwesomeIcon icon="check-circle" className="w-3 h-3 text-gray-500" />)}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="flex relative flex-row justify-end gap-2 px-2">
                    {!portfolio.loading && myId && myId === portfolio.owner.id && (
                        <a href={chooseUrl()}>
                            <IconButton size="sm" color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 transition-all duration-400">
                                <FontAwesomeIcon icon="pen" />
                            </IconButton>
                        </a>
                    )}
                    {!portfolio.loading && myId && myId !== portfolio.owner.id && (
                        <IconButton
                            size="sm"
                            color="white"
                            className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50 transition-all duration-400"
                            onClick={() => followPortfolio()}
                        >
                            {portfolio.isSubscribed ? <FontAwesomeIcon icon="user-minus" /> : <FontAwesomeIcon icon="user-plus" />}
                        </IconButton>
                    )}
                    <SharingBox />
                    {!portfolio.loading && myId && myId !== portfolio.owner.id && (
                        <Popover placement="bottom-end">
                            <PopoverHandler>
                                <IconButton
                                    size="sm"
                                    color="white"
                                    className="ring-1 ring-gray-400/50 hover:bg-gray-100 rounded-full transition-all duration-400"
                                >
                                    <FontAwesomeIcon icon="ellipsis-vertical" />
                                </IconButton>
                            </PopoverHandler>
                            <PopoverContent className="p-0">
                                <button
                                    className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                    onClick={() => portfolio?.id && OpenReportPortfolio(portfolio.id, t)}
                                >
                                    {t("report")}
                                </button>
                            </PopoverContent>
                        </Popover>
                    )}
                </div>
            </div>

            <div className="flex md:hidden flex-row gap-2 mt-4 items-center w-full justify-center flex-wrap">
                {portfolio.plan === "PRO" && (
                    <Chip
                        className="w-fit h-fit bg-black text-white"
                        color="purple"
                        variant="ghost"
                        value={t("pro")}
                        icon={<FontAwesomeIcon icon="crown" className="h-3 ml-0.5" />}
                        size="sm"
                    />
                )}
                {portfolio.plan === "PREMIUM" && (
                    <Chip
                        className="w-fit h-fit bg-white text-black border border-black"
                        color="orange"
                        variant="ghost"
                        value={t("premium")}
                        icon={<FontAwesomeIcon icon="star" className="h-3 ml-0.5" />}
                        size="sm"  
                    />
                )}

                <Chip
                    className="w-fit h-fit"
                    color="purple"
                    variant="ghost"
                    value={`${portfolio.subscriberCount ?? 0} ${t("subs")}`}
                    icon={<FontAwesomeIcon icon="users" className="mt-1" />}
                    size="sm"
                />
                {(portfolio.avgNote !== -1 && portfolio.features.actions.testimonial) && (
                    <Chip
                        className="w-fit h-fit"
                        color="purple"
                        variant="ghost"
                        value={`${portfolio.avgNote === -1 ? t("nonote") : Number(portfolio.avgNote).toFixed(2)}`}
                        icon={<FontAwesomeIcon icon="star" className="mt-1" />}
                        size="sm"
                    />
                )}
            </div>

            <motion.div
                variants={{
                    visible: { top: "48px", paddingTop: "12px" },
                    hidden: { top: "0px", paddingTop: "4px" },
                    barsInactive: { top: "48px", paddingTop: "12px" },
                    barsActive: { top: "102px", paddingTop: "8px" },
                }}
                animate={hidden ? (searchBar ? "barsInactive" : "hidden") : searchBar ? "barsActive" : "visible"}
                transition={{
                    duration: 0.35,
                    ease: "easeInOut",
                }}
                className="flex md:hidden border-b border-gray-200 w-full mx-auto bg-white mt-4 sticky top-[52px] z-30 pt-2"
            >
                <div className={`${actualUrl === "photos" ? "w-1/2" : "w-full"} px-2 pb-2`}>
                    <Select
                        placeholder="Menu"
                        name="Menu"
                        className="w-full relative top-0 bg-white text-xs"
                        options={MenuList}
                        styles={NavigationCustomStyles}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        value={MenuList.find((obj: any) => obj.value === actualUrl)}
                        onChange={(e: any) => e !== null && handleLinkClick(e.url)}
                        isSearchable={false}
                    />
                </div>
                <div className={`${actualUrl === "photos" ? "flex" : "hidden"} w-1/2 px-2  gap-2 justify-end`}>
                    <MobileFilterButton />

                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button
                                className={`${
                                    sortBy !== "" ? "text-white border-transparent bg-gradient-to-r from-orange-500 to-orange-100" : ""
                                } border flex px-4 py-2.5 rounded-full flex-row items-center gap-2  hover:bg-white text-xs`}
                            >
                                {t("sortby")}
                                <FontAwesomeIcon icon="chevron-down" className="-mr-1 h-3 w-3" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className={`absolute right-0 z-50  flex-col px-2 py-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                            >
                                <div className="py-1">
                                    {["default", "note"].map((item: string) => (
                                        <Menu.Item key={item}>
                                            <button
                                                onClick={() => setSortBy(item)}
                                                className={`${
                                                    sortBy === item && "bg-orange-500 text-white"
                                                } my-1 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg`}
                                            >
                                                {t(item)}
                                                {sortBy === item && <FontAwesomeIcon icon="check" className="ml-2" />}
                                            </button>
                                        </Menu.Item>
                                    ))}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </motion.div>
        </>
    );
}
