import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class TestimonialAPI {
    public static async testimonials() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/testimonials`);
        else
            return APIClient.ezoom.get(`/public/testimonials`);
    }

    public static async newTestimonial(data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/testimonials`, data);
        else
            return APIClient.ezoom.post(`/public/testimonials`, data);
    }

    public static async answer(id: string, data: {}) {
        return APIClient.ezoom.put(`/testimonials/${id}/answer`, data);
    }
}
