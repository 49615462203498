import { Chip, IconButton, Popover, PopoverContent, PopoverHandler, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { GalleryInterface } from "../../../../components/Interfaces/GalleryInterface";
import DefaultBannerEzoom from "../../../../assets/images/elements/default-banner.png";
import { useEffect, useState } from "react";
import { Storage } from "../../../../services/storage";
import SharingBox from "../../../../components/Box/SharingBox";
import moment from "moment";
import FavoriteAPI from "../../../../services/API/Clients/FavoriteAPI";
import { toast } from "react-toastify";
import CardUserWithData from "../../../../components/Box/CardUserWithData";
import { PieChart } from "react-minimal-pie-chart";
import GalleryAPI from "../../../../services/API/Clients/GalleryAPI";
import { OpenGalleryReport } from "../../../../components/Modals/ModalGallerieReport";
import ModalSignInUp from "../../../../components/Modals/ModalSignInUp";
import { handleError } from "../../../../services/Errors/handleErrors";

const token = Storage.getToken();
const myId = Storage.getId();

export default function GalleryHeader({ picturesLength, data, setOpenModal, fetchData }: Readonly<{ picturesLength: number, data: GalleryInterface, setOpenModal: any, fetchData: any }>) {
    const { t } = useTranslation();
    const [repartition, setRepartition] = useState<{
        users: number;
        photographers: number;
    }>({
        users: 0,
        photographers: 0,
    });
    const [openInvitation, setOpenInvitation] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!data.id || data.id === "") return
            const response = await GalleryAPI.getRepartition(data.id)
            if (response.status === 200) {
                setRepartition(response.body);
            } else {
                handleError(response);
            }
        }
        fetchData();
    }, [data]);

    const handleChangeFav = async () => {      
        if (myId) {  
            const datas = {
                gallery: `/api/public/galleries/${data.id}`,
                owner: `/api/users/${myId}`
            }
            if (!data.isFavorited) {
                const response = await FavoriteAPI.favGallerie(datas);
                if (response.status === 200 || response.status === 201)
                    toast.success(t("addToFavGallerie"))
                else
                    handleError(response);
            } else {
                const response = await FavoriteAPI.unFavGallerie(datas);
                if (response.status === 200 || response.status === 201 || response.status === 204)
                    toast.success(t("removeFromFavGallerie"))
                else
                    handleError(response);
            }
            fetchData();
        }
    }
    
    return (
        <>
            <ModalSignInUp open={openInvitation} setOpen={setOpenInvitation} />
            <div
                className="hidden md:flex relative bg-cover aspect-[16/6]"
                style={{
                    backgroundPositionY: `${100 - (data.bannerOffset ?? 0)}%`,
                    backgroundImage: `url(${(data.banner && data.banner.publicPath) ?? DefaultBannerEzoom})`,
                }}
            ></div>
            <div className="hidden md:flex flex-row justify-end gap-4 mt-2 px-4">
                {myId && myId === data.owner.id && (
                    <a href={`/gallery/${data.id}/edit`}>
                        <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50">
                            <FontAwesomeIcon icon="pen" />
                        </IconButton>
                    </a>
                )}
                {myId && myId === data.owner.id && (
                    <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50" onClick={() => setOpenModal(true)}>
                        <FontAwesomeIcon icon="trash" />
                    </IconButton>
                )}
                {!data.private && data.published && (
                    <IconButton onClick={() => !Storage.getToken() ? setOpenInvitation(true) : handleChangeFav()} color={data.isFavorited ? "red" : "white"} className={`ring-1 ${data.isFavorited ? "ring-red-500/50 rounded-full hover:bg-red-400" : "ring-gray-400/50 rounded-full hover:bg-gray-50"} rounded-full transition-all`} >
                        <FontAwesomeIcon icon="heart" />
                    </IconButton>
                )}
                {data.published && (
                    <>
                        <SharingBox />
                        <Popover placement="bottom-end">
                            <PopoverHandler>
                                <IconButton color="white" className="ring-1 ring-gray-400/50 rounded-full hover:bg-gray-50">
                                    <FontAwesomeIcon icon="ellipsis-vertical" />
                                </IconButton>
                            </PopoverHandler>
                            <PopoverContent className="p-0">
                                <button
                                    className="py-2 px-4 rounded-lg hover:bg-orange-500 hover:text-white w-full"
                                    onClick={() => !Storage.getToken() ? setOpenInvitation(true) : data?.id && OpenGalleryReport(data.id, t)}
                                >
                                    {t("report")}
                                </button>
                            </PopoverContent>
                        </Popover>
                    </>
                )}
            </div>
            <Typography className="hidden md:flex justify-center text-4xl font-bold text-center font-sans px-4 mt-4" style={{ fontFamily: "Great Vibes" }}>{data.title}</Typography>
            <div className="hidden md:flex flex-col md:flex-row justify-between items-center px-4 mt-4 max-w-7xl mx-auto w-full">
                <div className="flex flex-row justify-center md:justify-start gap-4 mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3">
                    <CardUserWithData data={data.portfolio} isVerified={data.owner.verified} />
                </div>
                <div className="flex flex-row flex-wrap justify-center gap-4 mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3">
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        value={`${picturesLength} ${t("photos")}`}
                        icon={<FontAwesomeIcon icon="images" className="h-4 mt-0.5" />}
                    />
                    <Chip
                        className="w-fit h-fit"
                        color="gray"
                        variant="ghost"
                        value={`${data.viewCount} ${t("views")}`}
                        icon={<FontAwesomeIcon icon="eye" className="h-4 mt-0.5" />}
                    />
                    <Popover placement="bottom">
                        <PopoverHandler>
                            <Chip
                                className="w-fit h-fit cursor-pointer"
                                color="gray"
                                variant="ghost"
                                value={`${data.favoriteCount} ${t("favs")}`}
                                icon={<FontAwesomeIcon icon="heart" className="h-4 mt-0.5" />}
                            />
                        </PopoverHandler>
                        <PopoverContent className="z-40">
                            <div className="flex flex-row gap-2 items-center">
                                {repartition.users !== 0 || repartition.photographers !== 0 ? (
                                    <PieChart
                                        data={[
                                            { title: t("users"), value: repartition.users ?? 0, color: "rgb(239, 147, 53)" },
                                            { title: t("photographs"), value: repartition.photographers ?? 0, color: "rgb(98, 81, 153)" },
                                        ]}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                ) : (
                                    <PieChart
                                        data={[{ title: t("users"), value: 100, color: "#d4d4d4" }]}
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                )}
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="w-4 h-4 bg-orange-400 rounded-full"></div>
                                        <span>{t("users")}</span>
                                        <span>{repartition.users ?? 0}%</span>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <div className="w-4 h-4 bg-purple-400 rounded-full"></div>
                                        <span>{t("photographs")}</span>
                                        <span>{repartition.photographers ?? 0}%</span>
                                    </div>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
                <div className="flex flex-rox md:flex-col justify-center md:justify-end md:items-end  mt-4 md:mt-0 px-4 mx-auto w-full md:w-1/3 text-gray-500 text-right gap-1">
                    {data.date && moment(data.date).format("DD.MM.YYYY")}<br />
                    {data?.place?.label}
                    {data.private && (
                        <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {t("privateGallery")}
                        </div>
                    )}
                    {!data.published && (
                        <div className="bg-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {t("unpublishedGallery")}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
